<template>
  <div class="ad-put">
    <div class="ad-put-operation flex-row-between-start">
      <div style="line-height: 34px">
        <i
          class="el-icon-back"
          @click="goBack"
          title="返回计划列表"
          style="
            font-size: 18px;
            color: #ccc;
            font-weight: bold;
            cursor: pointer;
          "
        ></i>
        <span style="font-weight: bold; margin-left: 10px">新建广告计划</span>
      </div>
      <div class="operation">
        <c-button
          @click="
            (cb) => {
              clearTemplateData(cb);
            }
          "
          type="danger"
          :disabled="
            accountSelection.length === 0 && Object.keys(params).length === 0
          "
        >清除当前模板数据</c-button>
        <c-button
          @click="
            (cb) => {
              chooseTemplate(cb);
            }
          "
        >选择计划模板</c-button>
        <c-button
          @click="
            (cb) => {
              saveTemplate(cb);
            }
          "
        >保存计划模板</c-button>
        <c-button
          type="primary"
          @click="
            (cb) => {
              submitTemplate(cb);
            }
          "
        >提交计划</c-button>
      </div>
    </div>
    <div class="ad-put-container">
      <div class="another-info part">
        <h3>基础</h3>
        <div class="another-info-container part-container">
          <div class="flex-row-start-start-wrap">
            <template-part
              :title="`选择账号${accountSelection.length ? `(${accountSelection.length}个)` : ''
              }`"
              slotName="account"
              @setConfig="handleSetConfig('AddAccount')"
            >
              <template #account>
                <p v-for="(item, index) in accountSelection" :key="item.id" class="account">
                  <span class="line-overflow">{{ item.accountName }}({{ item.accountId }})</span>
                  <i class="el-icon-error" @click="handleDelAccount(index)"></i>
                </p>
              </template>
            </template-part>
            <template-part title="人群包" slotName="crowd" @setConfig="handleSetConfig('AddCrowd')">
              <template #crowd>
                <p
                  v-for="(item, index) in accountSelection"
                  :key="index"
                  class="crowd flex-row-between-center"
                >
                  <span class="line-overflow">{{ item.accountName }}({{ item.accountId }})</span>
                  <el-tag
                    :type="`${Array.isArray(item.crowd) && item.crowd.length > 0
                    ? 'success'
                    : 'info'
                    }`"
                    class="crowd-allocation"
                  >
                    {{
                      `${Array.isArray(item.crowd) && item.crowd.length > 0
                        ? "已配置"
                        : "不限"
                        }`
                    }}
                  </el-tag>
                </p>
              </template>
            </template-part>
          </div>
        </div>
      </div>
      <div class="base-info part">
        <h3>基础</h3>
        <div class="base-info-container part-container">
          <div class="flex-row-start-start-wrap">
            <template-part
              :title="`计划${params.projectConfig
              ? `（分配:${params.projectConfig.planRules.ChineseText}  创建:${params.projectConfig.createRules.ChineseText}）`
              : ''
              }`"
              @setConfig="handleSetConfig('ConfigBaseInfo')"
              slotName="project"
            >
              <template #project v-if="params.projectConfig">
                <p
                  class="property-item flex-row-between-center"
                  v-for="(item, index) in projectProperty"
                  :key="index"
                >
                  <span class="property-item-key">
                    {{
                      params.projectConfig[item].propertyName
                    }}
                  </span>
                  <span class="property-item-value">
                    {{
                      params.projectConfig[item].ChineseText
                    }}
                  </span>
                </p>
              </template>
            </template-part>
            <template-part title="广告" slotName="ConfigAd" @setConfig="handleSetConfig('ConfigAd')">
              <template #ConfigAd>
                <p
                  class="property-item flex-row-between-center"
                  v-for="(item, index) in adProperty"
                  :key="index"
                >
                  <span class="property-item-key">{{ params.adConfig[item].propertyName }}</span>
                  <span class="property-item-value">{{ params.adConfig[item].ChineseText }}</span>
                </p>
              </template>
            </template-part>
            <template-part
              title="创意"
              slotName="ConfigCreativity"
              @setConfig="handleSetConfig('ConfigCreativity')"
            >
              <template #ConfigCreativity>
                <p
                  class="property-item flex-row-between-center"
                  v-for="(item, index) in creativityProperty"
                  :key="index"
                >
                  <span class="property-item-key">{{ params.creativityConfig[item].propertyName }}</span>
                  <span class="property-item-value">{{ params.creativityConfig[item].ChineseText }}</span>
                </p>
              </template>
            </template-part>
          </div>
        </div>
      </div>
      <div class="batch-content part">
        <h3 style="display:inline-block">批量添加内容，交叉生成广告</h3>
        <el-tooltip content="文案-素材是否一一对应" placement="top">
          <el-switch class="switch" v-model="isOneToOne" :disabled="getSwitchStatus()"></el-switch>
        </el-tooltip>
        <!-- <span style='font-size:14px;color:#ccc;'>（选择文案-素材一对一之后，将自动按照“定向”进行分配）</span> -->
        <div class="batch-content-container part-container">
          <div class="flex-row-start-start-wrap">
            <template-style
              slotName="ConfigOrientation"
              :title="`定向${params.ConfigOrientation
              ? `(${params.ConfigOrientation.select.length}个)`
              : ''
              }`"
              @setConfig="handleSetConfig('ConfigOrientation')"
              @addContent="addContent('ConfigOrientation')"
            >
              <template #ConfigOrientation>
                <div v-if="params.ConfigOrientation">
                  <p
                    class="property-item line-part"
                    v-for="(item, index) in params.ConfigOrientation.select"
                    :key="item.id"
                  >
                    <span class="line-overflow">{{ item.targetingName }}</span>
                    <i
                      class="el-icon-error del-icon"
                      @click="params.ConfigOrientation.select.splice(index, 1)"
                    ></i>
                  </p>
                </div>
              </template>
            </template-style>
            <!-- 只激励视频不展示 !params.xxx 防止清空数据的时候不展示该栏位的bug-->
            <template-style
              v-if="(!params.creativityConfig || params.creativityConfig.adcreativeTemplateInfo.value !== 910) && !isOneToOne"
              slotName="ConfigResource"
              :title="`素材${params.creativityConfig
              ? `(${params.creativityConfig.adcreativeTemplateInfo.ChineseText})`
              : ''
              }`"
              @setConfig="handleSetConfig('ConfigResource')"
              @addContent="addContent('ConfigResource')"
            >
              <template #ConfigResource>
                <div v-if="params.ConfigResource">
                  <template
                    v-if="
                      [711, 712, 714, 722].includes(params.creativityConfig.adcreativeTemplateInfo.value)
                    "
                  >
                    <p
                      class="property-item line-part flex-row-start-center"
                      v-for="(item, index) in params.ConfigResource.select"
                      :key="item.id"
                    >
                      <img
                        :src="[711, 712, 714].includes(params.creativityConfig.adcreativeTemplateInfo.value) ? item.mediaInfo.src : `${item.mediaInfo.src}?vframe/jpg/offset/0`"
                        style="width: 20px; height: 11px; margin-right: 6px"
                        alt
                      />
                      <span>{{ item.mediaInfo.name }}</span>
                      <i
                        class="el-icon-error del-icon"
                        @click="params.ConfigResource.select.splice(index, 1)"
                      ></i>
                    </p>
                  </template>
                  <template
                    v-else-if="
                      [721, 720, 1529].includes(
                        params.creativityConfig.adcreativeTemplateInfo.value
                      )
                    "
                  >
                    <div
                      class="property-item line-part media-type"
                      v-for="(item, index) in params.ConfigResource.select"
                      :key="item.id"
                    >
                      <p>
                        <img :src="item.image.src" alt />
                        <span
                          style="margin-left: 5px;width:70%"
                          class="line-overflow"
                        >{{ item.image.name }}</span>
                      </p>
                      <p>
                        <img :src="`${item.video.src}?vframe/jpg/offset/0`" />
                        <span style="margin-left: 5px;width:70%" class="line-overflow">
                          {{
                            item.video.name
                          }}
                        </span>
                      </p>
                      <i
                        class="el-icon-error del-icon"
                        @click="params.ConfigResource.select.splice(index, 1)"
                      ></i>
                    </div>
                  </template>
                  <template
                    v-else-if="
                      params.creativityConfig.adcreativeTemplateInfo.value ===
                      718
                    "
                  >
                    <div
                      class="property-item line-part three-pic"
                      v-for="(item, index) in params.ConfigResource.select"
                      :key="item.id"
                    >
                      <p v-for="(img, index2) in item.image" :key="index2">
                        <img :src="img.src" alt />
                        <span
                          style="margin-left: 5px;width:70%"
                          class="line-overflow"
                        >{{ img.name }}</span>
                      </p>
                      <i
                        class="el-icon-error del-icon"
                        style="top: -3%"
                        @click="params.ConfigResource.select.splice(index, 1)"
                      ></i>
                    </div>
                  </template>
                </div>
              </template>
            </template-style>
            <template-style
              v-if="(!params.creativityConfig || !DONT_SHOW_IDS.includes(params.creativityConfig.adcreativeTemplateInfo.value)) && !isOneToOne"
              :title="`文案${params.ConfigDoc && params.ConfigDoc.select.length > 0
              ? `(${params.ConfigDoc.select.length}个)`
              : ''
              }`"
              slotName="configDoc"
              @setConfig="handleSetConfig('ConfigDoc')"
              @addContent="addContent('ConfigDoc')"
            >
              <template #configDoc v-if="params.ConfigDoc">
                <div
                  class="property-item line-part three-pic"
                  v-for="(item, index) in params.ConfigDoc.select"
                  :key="item.id"
                >
                  <div class="doc-list">
                    <p v-if="item.bottom_text">辅助文案：{{ item.bottom_text }}</p>
                    <p v-if="item.title">文案(1-14)：{{ item.title }}</p>
                    <p
                      v-if="item.description"
                    >文案({{ getMinLength() }}-{{ getMaxLength() }})：{{ item.description }}</p>
                  </div>
                  <i
                    class="el-icon-error del-icon"
                    style="top: -3%"
                    @click="params.ConfigDoc.select.splice(index, 1)"
                  ></i>
                </div>
              </template>
            </template-style>
            <!-- 素材和文案一对一显示内容 -->
            <template-style
              v-if="isOneToOne"
              slotName="MixContent"
              :title="`素材和文案（${Math.min(params.ConfigResource ? params.ConfigResource.select.length : 0, params.ConfigDoc ? params.ConfigDoc.select.length : 0)}个）`"
              tips="添加素材和文案"
              @setConfig="handleSetConfig('MixContent')"
              @addContent="addContent('MixContent')"
            >
              <template #MixContent>
                <div class="mix-content">
                  <div
                    class="mix-content-item"
                    v-for="(item, index) in mixContentList"
                    :key="item.id"
                  >
                    <template
                      v-if="params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo"
                    >
                      <div class="resource-content">
                        <template v-if="getType(item.resource)">
                          <template
                            v-if="[711, 712, 714, 722].includes(params.creativityConfig.adcreativeTemplateInfo.value)"
                          >
                            <p class="mix-item flex-row-start-center">
                              <img
                                :src="[711, 712, 714].includes(params.creativityConfig.adcreativeTemplateInfo.value) ? item.resource.mediaInfo.src : `${item.resource.mediaInfo.src}?vframe/jpg/offset/0`"
                                alt
                              />
                              <span
                                style="margin-left:5px;width:85%"
                              >{{ item.resource.mediaInfo.name }}</span>
                              <i
                                class="el-icon-error del-icon"
                                @click="spliceItem(item, index, 'resource')"
                              ></i>
                            </p>
                          </template>
                          <template
                            v-else-if="
                              [721, 720, 1529].includes(
                                params.creativityConfig.adcreativeTemplateInfo.value
                              )
                            "
                          >
                            <div class="mix-item-style-02 media-type">
                              <p>
                                <img :src="item.resource.image.src" />
                                <span style="margin-left:5px">{{ item.resource.image.name }}</span>
                              </p>
                              <p>
                                <img :src="`${item.resource.video.src}?vframe/jpg/offset/0`" />
                                <span
                                  style="margin-left:5px;width:85%"
                                  class="line-overflow"
                                >{{ item.resource.video.name }}</span>
                              </p>
                              <i
                                class="el-icon-error del-icon"
                                @click="spliceItem(item, index, 'resource')"
                              ></i>
                            </div>
                          </template>
                          <template
                            v-else-if="
                              params.creativityConfig.adcreativeTemplateInfo.value ===
                              718
                            "
                          >
                            <div class="mix-item-style-03">
                              <p v-for="(img, index2) in item.resource.image" :key="index2">
                                <img :src="img.src" />
                                <span style="margin-left:5px;width:85%">{{ img.name }}</span>
                              </p>
                              <i
                                class="el-icon-error del-icon"
                                style="top:-3%"
                                @click="spliceItem(item, index, 'resource')"
                              ></i>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="upload-resource" @click="chooseSingle(index)">
                            <i class="el-icon-plus"></i>
                            <span>点击选择素材</span>
                          </div>
                        </template>
                      </div>
                    </template>
                    <div class="doc-content">
                      <template v-if="isValidObject(item.doc)">
                        <div class="doc-list">
                          <p v-if="item.doc && item.doc.bottom_text">辅助文案：{{ item.doc.bottom_text }}</p>
                          <p v-if="item.doc && item.doc.title">文案(1-14)：{{ item.doc.title }}</p>
                          <p
                            v-if="item.doc && item.doc.description"
                          >文案({{ getMinLength() }}-{{ getMaxLength() }})：{{ item.doc.description }}</p>
                        </div>
                        <i
                          class="el-icon-error del-icon"
                          style="top:-3%"
                          @click="spliceItem(item, index, 'doc')"
                        ></i>
                      </template>
                      <template v-else>
                        <div class="upload-doc" @click="chooseSingle(index)">
                          <i class="el-icon-plus"></i>
                          <span>点击添加文案</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template-style>
          </div>
        </div>
      </div>
      <div class="preview-ad-list part">
        <div>
          <h3 style="display: inline-block">预览广告</h3>
          <span
            style="color: #fd5d5a; font-size: 13px; margin-left: 5px"
          >除了广告的状态外，您在腾讯广告系统修改了其它广告信息，将不会同步到本系统！</span>
        </div>
        <div class="preview-ad-list-container" v-if="previewDataList.length > 0">
          <div
            class="preview-table"
            v-for="(account, index) in accountSelection"
            :key="account.accountId"
          >
            <div
              class="account-name flex-row-between-start"
              @click="account.showTable = !account.showTable"
            >
              <div>
                <p>账户：{{ account.accountName }}</p>
                <p>ID：{{ account.accountId }}</p>
              </div>
              <div style="flex-grow: 1; text-align: right">
                共{{
                  previewDataList[index] ? previewDataList[index].length : 0
                }}个广告
                <i
                  style="cursor: pointer; margin-left: 5px"
                  v-if="
                    previewDataList[index] &&
                    previewDataList[index].length !== 0
                  "
                  :class="{
                    'el-icon-arrow-down': account.showTable,
                    'el-icon-arrow-up': !account.showTable,
                  }"
                ></i>
              </div>
            </div>
            <template
              v-if="
                previewDataList[index] && previewDataList[index].length !== 0
              "
            >
              <el-table
                v-show="account.showTable"
                :data="previewDataList[index]"
                :span-method="
                  ({ row, column, rowIndex, columnIndex }) => {
                    objectSpanMethod(index, {
                      row,
                      column,
                      rowIndex,
                      columnIndex,
                    });
                  }
                "
                max-height="700"
                style="width: 100%"
              >
                <el-table-column label="计划名称">
                  <template slot-scope="scope">
                    <span v-if="scope.row.projectConfig">
                      {{
                        scope.row.projectConfig.campaign_name.value
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="广告名称">
                  <template slot-scope="scope">
                    <span v-if="scope.row.adConfig">{{ scope.row.adConfig.ad_name.value }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="定向">
                  <template slot-scope="scope">
                    <div
                      class="per-part"
                      v-if="scope.row.orientation"
                    >{{ scope.row.orientation.targetingName }}</div>
                  </template>
                </el-table-column>
                <!-- 激励视频不展示 素材 文案 -->
                <template
                  v-if="params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo.value !== 910"
                >
                  <el-table-column label="素材">
                    <template slot-scope="scope">
                      <div class="per-part">
                        <div
                          class="flex-row-start-start"
                          v-if="Array.isArray(scope.row.resource.image)"
                        >
                          <div
                            class="three-pic-item flex-center-center"
                            style="margin-right: 15px"
                            v-for="(img, index) in scope.row.resource.image"
                            :key="index"
                          >
                            <img :src="img.url" alt />
                          </div>
                        </div>
                        <template v-else-if="'mediaInfo' in scope.row.resource">
                          <div class="flex-row-start-center">
                            <img
                              :src="scope.row.resource.mediaInfo.format.includes('video') ? `${scope.row.resource.mediaInfo.src}?vframe/jpg/offset/0` : `${scope.row.resource.mediaInfo.src}`"
                              alt
                            />
                            <span
                              style="margin-left:4px;width:70%"
                              class="line-overflow"
                            >{{ scope.row.resource.mediaInfo.name }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex-row-start-center" v-if="scope.row.resource.image">
                            <img :src="scope.row.resource.image.src" alt />
                            <span style="margin-left: 4px;width:70%" class="line-overflow">
                              {{
                                scope.row.resource.image.name
                              }}
                            </span>
                          </div>
                          <div
                            class="flex-row-start-center"
                            v-if="scope.row.resource.video"
                            style="margin-top: 5px"
                          >
                            <img :src="`${scope.row.resource.video.src}?vframe/jpg/offset/0`" />
                            <span style="margin-left: 4px;width:70%" class="line-overflow">
                              {{
                                scope.row.resource.video.name
                              }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="文案"
                    v-if="params.creativityConfig.adcreativeTemplateInfo.value !== 722"
                  >
                    <template slot-scope="scope">
                      <div class="per-part">
                        <p v-if="scope.row.doc && scope.row.doc.bottom_text">
                          <span>辅助文案:{{ scope.row.doc.bottom_text }}</span>
                        </p>
                        <p v-if="scope.row.doc && scope.row.doc.description">
                          <span>文案({{ getMinLength() }}-{{ getMaxLength() }}):{{ scope.row.doc.description }}</span>
                        </p>
                        <p v-if="scope.row.doc && scope.row.doc.title">
                          <span>文案(1-14):{{ scope.row.doc.title }}</span>
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-backtop target=".small-plane" style="right:100px;bottom:100px"></el-backtop>
    <choose-template
      :show="showTemplate"
      @close="showTemplate = false"
      @chooseSuccess="chooseSuccess"
      @deleteItem="deleteItem"
    ></choose-template>
    <component
      :is="curComponentInfo.name"
      :showInfo="curComponentInfo"
      :account="accountSelection"
      :isSingle="isSingle"
      :params="params"
      :mixContentList="subSituationMixContentList()"
      ref="customComponent"
      @close="handleComponentClose"
      @confirm="
        (select) => {
          handleSelectSuccess(select);
        }
      "
      @changePromotedType="changePromotedType"
    ></component>
  </div>
</template>

<script>
import TemplatePart from './component/template-part'
import TemplateStyle from './component/template-style'
import AddAccount from './component/add-account'
import AddCrowd from './component/add-crowd'
import ConfigBaseInfo from './component/config-base-info'
import ConfigAd from './component/config-ad'
import ConfigCreativity from './component/config-creativity'
import ConfigOrientation from './component/config-orientation'
import ConfigResource from './component/config-resource'
import ConfigDoc from './component/config-doc'
import ChooseTemplate from './component/choose-template'
import MixContent from './component/children/mix-content'
import { nanoid } from 'nanoid'
import {
  isEqual,
  formatTime,
  deepFlatten,
  getElementType,
  isValidArray,
  isValidObject
} from '@/assets/js/utils'
import {
  uploadAdqTemplate,
  editAdqTemplate,
  saveAdqTemplate,
  getAdqAudience
  , getADQList
} from '@/api/tencent'
import _ from 'lodash'

const DONT_SHOW_IDS = [910, 722]

export default {
  data () {
    return {
      isEdit: false,
      crowdSelection: [], // * 人群包选择合集
      accountSelection: [], // *站好选择合集
      showCrowdDialog: false,
      showAddAccountDialog: false,
      showProjectDialog: false,
      projectProperty: [], // 计划模块需要展示的属性值
      adProperty: [], // 广告模块需要展示的属性值
      creativityProperty: [], // 创意需要展示的属性值合集
      showConfigAdDialog: false,
      tableLoading: false,
      previewDataList: [],
      needTips: true, // 是否需要跳转提示
      showTemplate: false,
      templateName: '', // 保存的模板名称
      templateId: null, // 模板Id
      isOneToOne: false, // 文案素材是否采用一一对应的模式,
      isValidArray,
      isValidObject,
      DONT_SHOW_IDS,
      curComponentInfo: {
        name: '',
        dialogName: '',
        value: false
      }, // 当前组件信息
      params: {},
      spanArr: [],
      pos: 0,
      isLoopUpdate: true,
      mixContentList: [],
      curMixContentIndex: null,
      isSingle: false
    }
  },
  watch: {
    'params.creativityConfig.adcreativeTemplateInfo': {
      handler (newV, oldV) {
        // ! 判断oldV 防止第一次从无到有进入了判断
        if (newV && oldV) {
          if (!isEqual(newV, oldV)) {
            this.params.ConfigResource = null
            this.params.ConfigDoc = null
            this.previewDataList = []
            this.isOneToOne = false
            this.isSingle = false
            this.mixContentList = []
          }
        }
      }
    },
    params: {
      handler (newV) {
        if (this.isLoopUpdate) {
          if (this.checkAllReady()) {
            this.createPreviewData()
          }
        }
      },
      deep: true
    },
    accountSelection: {
      handler (newV, oldV) {
        // if (oldV && newV) {
        //   if (oldV.length !== 0 && oldV.length !== newV.length) {
        //     this.resetParams('notAll')
        //     this.resetData(false, ['projectProperty'])
        //   } else if (oldV.length === newV.length) {
        //     // ! 及时个数相同 ，选择的账号也相同，但是顺序不一样 也会导致重新配置信息!
        //     for (let i = 0; i < newV.length; i++) {
        //       if (newV[i].accountId !== oldV[i].accountId) {
        //         this.resetParams('notAll')
        //         this.resetData(false, ['projectProperty'])
        //         break
        //       }
        //     }
        //   }
        // }
        if (this.checkAllReady()) {
          this.createPreviewData()
        }
      },
      immediate: true
    },
    isOneToOne: {
      handler (newV) {
        if (newV === true) {
          this.mixContentList = this.getMixContent()
        } else {
          this.mixContentList = []
        }
        if (this.isLoopUpdate) {
          if (this.checkAllReady()) {
            this.createPreviewData()
          }
        }
      }
    }
  },
  mounted () {
    this.createBeforeunloadHandler()
    this.$once('hook:beforeDestroy', () => {
      this.destroyedBeforeunloadHandler()
    })
  },
  methods: {
    createBeforeunloadHandler () {
      window.addEventListener('beforeunload', this.beforeunloadHandler, false)
    },
    destroyedBeforeunloadHandler () {
      window.removeEventListener(
        'beforeunload',
        this.beforeunloadHandler,
        false
      )
    },
    beforeunloadHandler (e) {
      e = e || window.event
      if (e) {
        e.returnValue = '确定要继续吗？未保存的数据将会丢失!'
      }
      return '关闭提示'
    },
    checkAllReady () {
      const {
        projectConfig,
        adConfig,
        creativityConfig,
        ConfigOrientation,
        ConfigResource,
        ConfigDoc
      } = this.params
      if (
        projectConfig &&
        Object.keys(projectConfig).length > 0 &&
        adConfig &&
        Object.keys(adConfig).length > 0 &&
        creativityConfig &&
        Object.keys(creativityConfig).length > 0 &&
        ConfigOrientation &&
        Object.keys(ConfigOrientation).length > 0 &&
        Array.isArray(ConfigOrientation.select) &&
        ConfigOrientation.select.length > 0 &&
        this.accountSelection.length > 0
      ) {
        const { creativityConfig: { adcreativeTemplateInfo: { value } } } = this.params
        if (this.DONT_SHOW_IDS.includes(value)) {
          if (value === 910) return true
          //  贴片视频没有文案 但是要有素材
          if (value === 722 && ConfigResource &&
            Object.keys(ConfigResource).length > 0 &&
            Array.isArray(ConfigResource.select) &&
            ConfigResource.select.length > 0) return true
        } else {
          if (
            ConfigResource &&
            Object.keys(ConfigResource).length > 0 &&
            Array.isArray(ConfigResource.select) &&
            ConfigResource.select.length > 0 &&
            ConfigDoc &&
            Object.keys(ConfigDoc).length > 0 &&
            Array.isArray(ConfigDoc.select) &&
            ConfigDoc.select.length > 0
          ) {
            return true
          }
        }
      }

      return false
    },
    handleSetConfig (type, immediate = false) {
      if (!this.accountSelection.length && type !== 'AddAccount') {
        return this.$message.warning('请选择账号!')
      }
      const config = {
        AddAccount: 'showAddAccountDialog',
        AddCrowd: 'showCrowdDialog',
        ConfigBaseInfo: 'showProjectDialog',
        ConfigAd: 'showConfigAdDialog',
        ConfigCreativity: 'showConCreativityDialog',
        ConfigOrientation: 'showConOrientationDialog',
        ConfigResource: 'showConResourceDialog',
        ConfigDoc: 'showConDocDialog',
        MixContent: 'showMixContent'
      }
      if (type === 'ConfigCreativity') {
        if (!this.params.projectConfig) {
          return this.$message.warning('请设置基础信息!')
        }
        if (!this.params.adConfig) {
          return this.$message.warning('请设置广告信息!')
        }
      }
      if (['ConfigResource', 'MixContent'].includes(type)) {
        if (!this.params.creativityConfig) {
          return this.$message.warning('请设置创意信息!')
        }
      }
      this.curComponentInfo = {
        name: type,
        dialogName: config[type],
        value: true,
        immediate
      }
    },
    handleComponentClose () {
      this.curComponentInfo = {
        name: '',
        dialogName: '',
        value: false
      }
      this.isSingle = false
    },
    handleSelectSuccess (select) {
      if (['AddAccount', 'AddCrowd'].includes(this.curComponentInfo.name)) {
        this.accountSelection = select
        this.accountSelection = this.accountSelection.map((item) => ({
          ...item,
          showTable: false
        }))
        return
      }
      if (this.curComponentInfo.name === 'ConfigBaseInfo') {
        this.projectProperty = [
          'planRules',
          'createRules',
          'promoted_object_type',
          'speed_mode',
          'daily_budget',
          'total_budget',
          'campaign_name'
        ]
        const {
          planRules,
          createRules,
          promoted_object_type,
          speed_mode,
          daily_budget,
          total_budget,
          campaign_name
        } = select
        this.$set(this.params, 'projectConfig', {
          planRules,
          createRules,
          promoted_object_type,
          speed_mode,
          daily_budget,
          total_budget,
          campaign_name
        })
      }
      if (this.curComponentInfo.name === 'ConfigAd') {
        this.adProperty = [
          'netWorkValue',
          'dynamicAdSpec',
          'bid_adjustment',
          'trafficPackageType',
          'putSchedule',
          'putTime',
          'bidMethod',
          'optimizationType',
          'bidType',
          'bid_strategy',
          'bid_amount',
          // 'bid_adjustmentType',
          'bidAdjustmentSwitch',
          'daily_budget',
          'ad_name'
        ]

        const {
          netWorkValue,
          dynamic_ad_spec,
          dynamicAdSpec,
          bid_adjustment,
          trafficPackageType,
          putSchedule,
          putTime,
          bidMethod,
          optimizationType,
          bidType,
          bid_strategy,
          bid_amount,
          bid_adjustmentType,
          bidAdjustmentSwitch,
          daily_budget,
          ad_name,
          positionList
        } = select

        this.$set(this.params, 'adConfig', {
          dynamic_ad_spec,
          netWorkValue,
          dynamicAdSpec,
          bid_adjustment,
          trafficPackageType,
          putSchedule,
          putTime,
          bidMethod,
          optimizationType,
          bidType,
          bid_strategy,
          bid_amount,
          // bid_adjustmentType,
          bidAdjustmentSwitch,
          daily_budget,
          ad_name,
          positionList
        })
        if (bidAdjustmentSwitch.value) {
          this.$set(this.params, 'adConfig', {
            ...this.params.adConfig,
            bid_adjustmentType
          })
          this.adProperty = [...this.adProperty, 'bid_adjustmentType']
        }
      }
      if (this.curComponentInfo.name === 'ConfigCreativity') {
        this.creativityProperty = [
          'adcreativeTemplateInfo',
          // 'buttonText',
          'page_spec',
          'adcreative_name'
        ]
        const {
          adcreativeTemplateInfo,
          buttonText,
          page_spec,
          brandInfo,
          adcreative_name
        } = select
        const obj = {
          adcreativeTemplateInfo,
          page_spec,
          adcreative_name
        }
        if (adcreativeTemplateInfo.value === 722 && this.params?.projectConfig.createRules.value === 'doc') {
          this.$message.warning("贴片视频不需要文案，无法按照'文案'分配，请选择其它分配模式!")
        }
        if (buttonText !== undefined && buttonText !== null) {
          this.creativityProperty = [...this.creativityProperty, 'buttonText']
          obj.buttonText = buttonText
        }
        if (brandInfo !== undefined && brandInfo !== null) {
          this.creativityProperty = [...this.creativityProperty, 'brandInfo']
          obj.brandInfo = brandInfo
        }
        this.$set(this.params, 'creativityConfig', obj)
      }
      if (
        ['ConfigOrientation', 'ConfigResource', 'ConfigDoc'].includes(
          this.curComponentInfo.name
        )
      ) {
        this.$set(this.params, this.curComponentInfo.name, {
          select: select || []
        })
      }
      // *文案素材一对一
      if (this.curComponentInfo.name === 'MixContent') {
        if (this.isSingle) {
          if (typeof this.curMixContentIndex === 'number') {
            const docValue = select[0].doc
            const resourceValue = select[0].resource
            if (!this.params.ConfigDoc) {
              this.$set(this.params, 'ConfigDoc', { select: [] })
            }
            if (!this.params.ConfigResource) {
              this.$set(this.params, 'ConfigResource', { select: [] })
            }
            const docTarget = JSON.parse(JSON.stringify(this.params.ConfigDoc.select))
            const resourceTarget = JSON.parse(JSON.stringify(this.params.ConfigResource.select))
            docTarget[this.curMixContentIndex] = docValue
            resourceTarget[this.curMixContentIndex] = resourceValue

            this.$set(this.params, 'ConfigDoc', {
              select: docTarget
            })
            this.$set(this.params, 'ConfigResource', {
              select: resourceTarget
            })
          }
        } else {
          this.$set(this.params, 'ConfigDoc', {
            select: select.map(item => item.doc)
          })
          this.$set(this.params, 'ConfigResource', {
            select: select.map(item => item.resource)
          })
        }
        // this.isSingle = false
        this.mixContentList = this.getMixContent()
      }
    },
    addContent (type) {
      this.handleSetConfig(type, false)
    },
    createPreviewData () {
      this.previewDataList = []
      let count = 0
      let num = 0
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      // * 如果选择了激励式计划，则不能按照素材和文案进行平均分配
      if (adcreativeTemplateInfo.value === 910) {
        this.isLoopUpdate = false
        this.$set(this.params, 'projectConfig', {
          ...this.params.projectConfig,
          createRules: {
            ChineseText: '按定向',
            propertyName: '计划创建规则',
            value: 'orienteering'
          }
        })
        this.$nextTick(() => {
          this.isLoopUpdate = true
        })
      }
      const {
        projectConfig,
        adConfig,
        creativityConfig,
        ConfigOrientation,
        ConfigResource,
        ConfigDoc
      } = this.params

      const { planRules, createRules, campaign_name } = projectConfig
      console.log('projectConfig')
      console.log(projectConfig)
      // ! 如果是素材文案一对一模式
      if (this.isOneToOne) {
        this.createOneToOneRules()
        return
      }
      // ! 重复分配
      const ProjectObj = []
      if (createRules.value === 'resource' && adcreativeTemplateInfo.value !== 910) {
        const { select } = ConfigResource
        for (let m = 0; m < select.length; m++) {
          count++
          const fLevel = select[m]
          const newCamPaignName = `${campaign_name.value}-00${count}`
          for (let i = 0; i < ConfigOrientation.select.length; i++) {
            const sLevel = ConfigOrientation.select[i]
            if (adcreativeTemplateInfo.value !== 722) {
              for (let j = 0; j < ConfigDoc.select.length; j++) {
                const tLevel = ConfigDoc.select[j]
                num++
                ProjectObj.push({
                  resource: {
                    ...fLevel
                  },
                  projectConfig: {
                    ...projectConfig,
                    campaign_name: {
                      ...projectConfig.campaign_name,
                      value: newCamPaignName
                    }
                  },
                  adConfig: {
                    ...adConfig,
                    ad_name: {
                      ...adConfig.ad_name,
                      value: `${adConfig.ad_name.value}-00${num}`
                    }
                  },
                  orientation: {
                    ...sLevel
                  },
                  doc: {
                    ...tLevel
                  }
                })
              }
              // * 如果是贴片视频（没有文案）
            } else {
              num++
              ProjectObj.push({
                resource: {
                  ...fLevel
                },
                projectConfig: {
                  ...projectConfig,
                  campaign_name: {
                    ...projectConfig.campaign_name,
                    value: newCamPaignName
                  }
                },
                adConfig: {
                  ...adConfig,
                  ad_name: {
                    ...adConfig.ad_name,
                    value: `${adConfig.ad_name.value}-00${num}`
                  }
                },
                orientation: {
                  ...sLevel
                }
              })
            }
          }
        }
      } else if (createRules.value === 'doc' && !this.DONT_SHOW_IDS.includes(adcreativeTemplateInfo.value)) {
        for (let m = 0; m < ConfigDoc.select.length; m++) {
          count++
          const fLevel = ConfigDoc.select[m]
          const newCamPaignName = `${campaign_name.value}-00${count}`
          for (let i = 0; i < ConfigOrientation.select.length; i++) {
            const sLevel = ConfigOrientation.select[i]
            for (let j = 0; j < ConfigResource.select.length; j++) {
              const tLevel = ConfigResource.select[j]
              num++
              ProjectObj.push({
                resource: {
                  ...tLevel
                },
                doc: {
                  ...fLevel
                },
                orientation: {
                  ...sLevel
                },
                projectConfig: {
                  ...projectConfig,
                  campaign_name: {
                    ...projectConfig.campaign_name,
                    value: newCamPaignName
                  }
                },
                adConfig: {
                  ...adConfig,
                  ad_name: {
                    ...adConfig.ad_name,
                    value: `${adConfig.ad_name.value}-00${num}`
                  }
                }
              })
            }
          }
        }
      } else if (createRules.value === 'orienteering') {
        for (let m = 0; m < ConfigOrientation.select.length; m++) {
          count++
          const fLevel = ConfigOrientation.select[m]
          const newCamPaignName = `${campaign_name.value}-00${count}`
          // !如果不是激励视频
          if (!this.DONT_SHOW_IDS.includes(adcreativeTemplateInfo.value)) {
            for (let i = 0; i < ConfigDoc.select.length; i++) {
              const sLevel = ConfigDoc.select[i]
              for (let j = 0; j < ConfigResource.select.length; j++) {
                const tLevel = ConfigResource.select[j]
                num++
                ProjectObj.push({
                  orientation: {
                    ...fLevel
                  },
                  doc: {
                    ...sLevel
                  },
                  resource: {
                    ...tLevel
                  },
                  projectConfig: {
                    ...projectConfig,
                    campaign_name: {
                      ...projectConfig.campaign_name,
                      value: newCamPaignName
                    }
                  },
                  adConfig: {
                    ...adConfig,
                    ad_name: {
                      ...adConfig.ad_name,
                      value: `${adConfig.ad_name.value}-00${num}`
                    }
                  }
                })
              }
            }
            // ! 如果是激励视频
          } else if (adcreativeTemplateInfo.value === 910) {
            // * 需要填充的数组长度的值

            ProjectObj.push({
              orientation: {
                ...fLevel
              },
              projectConfig: {
                ...projectConfig,
                campaign_name: {
                  ...projectConfig.campaign_name,
                  value: newCamPaignName
                }
              },
              adConfig: {
                ...adConfig,
                ad_name: {
                  ...adConfig.ad_name,
                  value: `${adConfig.ad_name.value}-00${num}`
                }
              }
            })
            // ! 激励模式根据腾讯的规则的更改也需要进行变更（激励模式支持多个落地页的选择，其它模式只能选择单个）
            //  * 如果是贴片视频，需要素材，不需要文案
          } else if (adcreativeTemplateInfo.value === 722) {
            for (let j = 0; j < ConfigResource.select.length; j++) {
              const tLevel = ConfigResource.select[j]
              num++
              ProjectObj.push({
                orientation: {
                  ...fLevel
                },
                projectConfig: {
                  ...projectConfig,
                  campaign_name: {
                    ...projectConfig.campaign_name,
                    value: newCamPaignName
                  }
                },
                adConfig: {
                  ...adConfig,
                  ad_name: {
                    ...adConfig.ad_name,
                    value: `${adConfig.ad_name.value}-00${num}`
                  }
                },
                resource: {
                  ...tLevel
                }
              })
            }
          }
        }
      }
      // !重复分配
      if (planRules.value === 'repeat') {
        for (let i = 0; i < this.accountSelection.length; i++) {
          let list = []
          const { accountId } = this.accountSelection[i]
          // * 如果是激励视频，则需要针对每个账号再乘以该账号所选的落地页的数量
          if (creativityConfig.adcreativeTemplateInfo.value === 910) {
            const Len = this.getPageSpecLen(accountId)
            list = deepFlatten([...Array.from({ length: Len }, () => {
              return ProjectObj
            })])
            this.$set(this.previewDataList, i, list)
          } else {
            this.$set(this.previewDataList, i, ProjectObj)
          }
        }
      }
      // ! 平均分配
      if (planRules.value === 'average') {
        const obj = {}
        // ! 每个账户可以分配的计划个数(计算per)
        let per
        if (adcreativeTemplateInfo.value !== 910) {
          if (createRules.value === 'resource') {
            per = Math.floor(
              ConfigResource.select.length / this.accountSelection.length
            )
            for (const item of ProjectObj) {
              (obj[item.resource.id] || (obj[item.resource.id] = [])).push(item)
            }
          }
          if (createRules.value === 'doc' && adcreativeTemplateInfo.value !== 722) {
            per = Math.floor(
              ConfigDoc.select.length / this.accountSelection.length
            )
            for (const item of ProjectObj) {
              (obj[item.doc.id] || (obj[item.doc.id] = [])).push(item)
            }
          }
        }
        if (createRules.value === 'orienteering') {
          per = Math.floor(
            ConfigOrientation.select.length / this.accountSelection.length
          )
          for (const item of ProjectObj) {
            (obj[item.orientation.id] || (obj[item.orientation.id] = [])).push(
              item
            )
          }
        }
        const arr = Object.entries(obj)
        // ! 如果计划数小于账户数(优先确保前面的账户被选中)
        if (per === 0) {
          for (let i = 0; i < arr.length; i++) {
            // * 激励视频计划个数需要 * 落地页的数量
            if (adcreativeTemplateInfo.value === 910) {
              const accountId = this.accountSelection[i].accountId
              const Len = this.getPageSpecLen(accountId)
              const list = deepFlatten([...Array.from({ length: Len }, () => {
                return arr[i][1]
              })])
              this.$set(this.previewDataList, i, list)
            } else {
              this.$set(this.previewDataList, i, deepFlatten(arr[i][1]))
            }
          }
        } else {
          for (let i = 0; i < this.accountSelection.length; i++) {
            const { accountId } = this.accountSelection[i]
            const array = i === this.accountSelection.length - 1 ? deepFlatten(arr.slice(i * per, arr.length).map((item) => item[1])) : deepFlatten(arr.slice(i * per, (i + 1) * per).map((item) => item[1]))

            if (adcreativeTemplateInfo.value === 910) {
              const Len = this.getPageSpecLen(accountId)
              const list = deepFlatten(Array.from({ length: Len }, () => array))
              this.$set(this.previewDataList, i, list)
            } else {
              this.$set(this.previewDataList, i, array)
            }

            // i === this.accountSelection.length - 1
            //   ? this.$set(
            //     this.previewDataList,
            //     i,
            //     deepFlatten(
            //       arr.slice(i * per, arr.length).map((item) => item[1])
            //     )
            //   )
            //   : this.$set(
            //     this.previewDataList,
            //     i,
            //     deepFlatten(
            //       arr.slice(i * per, (i + 1) * per).map((item) => item[1])
            //     )
            //   )
          }
        }
      }
    },
    // ! 创建文案素材一对一规则
    createOneToOneRules () {
      const {
        projectConfig,
        adConfig,
        creativityConfig: { adcreativeTemplateInfo },
        ConfigOrientation,
        ConfigResource,
        ConfigDoc
      } = this.params
      const { planRules, createRules, campaign_name } = projectConfig
      const ProjectObj = []
      let count = 0
      let num = 0
      if (createRules.value === 'resource' && adcreativeTemplateInfo.value !== 910) {
        const { select } = ConfigResource

        select.forEach((rItem, ri) => {
          count++

          const docItem = ConfigDoc.select[ri]
          const newCamPaignName = `${campaign_name.value}-00${count}`
          ConfigOrientation.select.forEach((oItem, oi) => {
            // !722 贴片视频
            num++
            ProjectObj.push({
              resource: {
                ...rItem
              },
              projectConfig: {
                ...projectConfig,
                campaign_name: {
                  ...projectConfig.campaign_name,
                  value: newCamPaignName
                }
              },
              adConfig: {
                ...adConfig,
                ad_name: {
                  ...adConfig.ad_name,
                  value: `${adConfig.ad_name.value}-00${num}`
                }
              },
              orientation: {
                ...oItem
              },
              // ! 如果不是贴片视频，则添加文案doc字段
              ...(adcreativeTemplateInfo.value !== 722 && { doc: { ...docItem } })
            })
          })
        })
        // * 文案定向
      } else if (createRules.value === 'doc' && !this.DONT_SHOW_IDS.includes(adcreativeTemplateInfo.value)) {
        const { select } = ConfigDoc
        select.forEach((dItem, dIndex) => {
          count++
          const newCamPaignName = `${campaign_name.value}-00${count}`
          const rItem = ConfigResource.select[dIndex]
          ConfigOrientation.select.forEach((oItem, oIndex) => {
            num++
            ProjectObj.push({
              resource: {
                ...rItem
              },
              doc: {
                ...dItem
              },
              orientation: {
                ...oItem
              },
              projectConfig: {
                ...projectConfig,
                campaign_name: {
                  ...projectConfig.campaign_name,
                  value: newCamPaignName
                }
              },
              adConfig: {
                ...adConfig,
                ad_name: {
                  ...adConfig.ad_name,
                  value: `${adConfig.ad_name.value}-00${num}`
                }
              }

            })
          })
        })
        // * 定向
      } else if (createRules.value === 'orienteering') {
        const { select } = ConfigOrientation
        select.forEach((oItem, oi) => {
          count++
          const newCamPaignName = `${campaign_name.value}-00${count}`
          // ! 如果不是激励视频
          ConfigDoc.select.forEach((dItem, di) => {
            const rItem = ConfigResource.select[di]
            num++
            ProjectObj.push({
              orientation: {
                ...oItem
              },
              ...(!this.DONT_SHOW_IDS.includes(adcreativeTemplateInfo.value) && {
                doc: {
                  ...dItem
                }
              }),
              // ! 如果是激励视频，则没有素材
              ...(adcreativeTemplateInfo.value !== 910 && {
                resource: {
                  ...rItem
                }
              }),
              projectConfig: {
                ...projectConfig,
                campaign_name: {
                  ...projectConfig.campaign_name,
                  value: newCamPaignName
                }
              },
              adConfig: {
                ...adConfig,
                ad_name: {
                  ...adConfig.ad_name,
                  value: `${adConfig.ad_name.value}-00${num}`
                }
              }

            })
          })
        })
      }
      // ! 重复分配
      if (planRules.value === 'repeat') {
        for (let i = 0; i < this.accountSelection.length; i++) {
          this.$set(this.previewDataList, i, ProjectObj)
        }
      }
      // ! 平均分配
      if (planRules.value === 'average') {
        const obj = {}
        // ! 每个账户可以分配的计划个数(计算per)
        let per
        if (adcreativeTemplateInfo.value !== 910) {
          if (createRules.value === 'resource') {
            per = Math.floor(
              ConfigResource.select.length / this.accountSelection.length
            )
            for (const item of ProjectObj) {
              (obj[item.resource.id] || (obj[item.resource.id] = [])).push(item)
            }
          }
          if (createRules.value === 'doc' && adcreativeTemplateInfo.value !== 722) {
            per = Math.floor(
              ConfigDoc.select.length / this.accountSelection.length
            )
            for (const item of ProjectObj) {
              (obj[item.doc.id] || (obj[item.doc.id] = [])).push(item)
            }
          }
        }
        if (createRules.value === 'orienteering') {
          per = Math.floor(
            ConfigOrientation.select.length / this.accountSelection.length
          )
          for (const item of ProjectObj) {
            (obj[item.orientation.id] || (obj[item.orientation.id] = [])).push(
              item
            )
          }
        }
        const arr = Object.entries(obj)
        // ! 如果计划数小于账户数(优先确保前面的账户被选中)
        if (per === 0) {
          for (let i = 0; i < arr.length; i++) {
            this.$set(this.previewDataList, i, deepFlatten(arr[i][1]))
          }
        } else {
          for (let i = 0; i < this.accountSelection.length; i++) {
            i === this.accountSelection.length - 1
              ? this.$set(
                this.previewDataList,
                i,
                deepFlatten(
                  arr.slice(i * per, arr.length).map((item) => item[1])
                )
              )
              : this.$set(
                this.previewDataList,
                i,
                deepFlatten(
                  arr.slice(i * per, (i + 1) * per).map((item) => item[1])
                )
              )
          }
        }
      }
    },
    objectSpanMethod (index, { row, column, rowIndex, columnIndex }) {
      this.$nextTick(() => {
        if (columnIndex === 0) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      })
    },
    chooseTemplate (cb) {
      this.showTemplate = true
      cb()
    },
    async saveTemplate (cb) {
      if (!this.checkAllReady()) {
        cb()
        return this.$message.warning('请完善计划配置!')
      }
      if (!this.checkMixContent(cb)) return
      if (!await this.checkTimeSchedule(cb)) return
      if (!await this.checkCreativeType(cb)) return
      if (!await this.checkIsFlashType(cb)) return
      if (!await this.checkIsDocType(cb)) return
      // ! 新增检测修改模板的账号数据导致模块其余数据与需要提交的数据匹配不一致的问题
      if (!await this.checkBecauseAccountModify(cb)) return

      this.$prompt('请输入模板名称!（编辑时，修改模板名称会新增模板）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.isEdit ? this.templateName : ''
      })
        .then(({ value }) => {
          this.packageParams().then((params) => {
            params.template.template_name = value
            // cb()

            if (!this.isEdit) {
              saveAdqTemplate({
                content: JSON.stringify(params),
                template_info: JSON.stringify(this.params)
              })
                .then(() => {
                  this.$message.success('保存成功!')
                  this.resetParams()
                  this.resetData()
                  this.isEdit = false
                })
                .finally(() => {
                  cb()
                })
            } else {
              // ! 如果修改了模板名字，则新增，否则编辑
              if (this.templateName === value) {
                editAdqTemplate(this.templateId, {
                  content: JSON.stringify(params),
                  template_info: JSON.stringify(this.params)
                })
                  .then(() => {
                    this.$message.success('编辑成功!')
                    this.resetParams()
                    this.resetData()
                    this.isEdit = false
                  })
                  .finally(() => {
                    cb()
                  })
              } else {
                saveAdqTemplate({
                  content: JSON.stringify(params),
                  template_info: JSON.stringify(this.params)
                })
                  .then(() => {
                    this.$message.success('保存成功!')
                    this.resetParams()
                    this.resetData()
                    this.isEdit = false
                  })
                  .finally(() => {
                    cb()
                  })
              }
            }
          })
        })
        .catch(() => {
          cb()
        })
    },
    async submitTemplate (cb) {
      if (!this.checkAllReady()) {
        cb()
        return this.$message.warning('请完善计划配置!')
      }
      if (!this.checkMixContent(cb)) return
      if (!await this.checkTimeSchedule(cb)) return
      if (!await this.checkCreativeType(cb)) return
      if (!await this.checkIsFlashType(cb)) return
      if (!await this.checkIsDocType(cb)) return
      this.$confirm('确定要将此条模板生成计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // ! 组装参数
          this.packageParams().then((params) => {
            uploadAdqTemplate({
              content: JSON.stringify(params),
              template_info: JSON.stringify(this.params)
            })
              .then(() => {
                this.$message.success('操作完成!')
                this.needTips = false
                this.$router.push({
                  name: 'AdPutList'
                })
              })
              .finally(() => {
                cb()
              })
          })
        })
        .catch(() => { })
        .finally(() => {
          cb()
        })
    },
    packageParams () {
      return new Promise((resolve) => {
        const {
          projectConfig,
          adConfig,
          creativityConfig,
          ConfigOrientation,
          ConfigResource,
          ConfigDoc
        } = this.params
        const template = {
          template_name: ''
        }

        const accounts = this.accountSelection
          .map((item) => ({
            account_id: item.accountId
          }))
          .filter(
            (_, index) =>
              this.previewDataList[index] &&
              this.previewDataList[index].length > 0
          )
        const crowdPackages = this.accountSelection
          .map((item) => ({
            account_id: item.accountId,
            audience_id: item.crowd && item.crowd.length > 0 ? item.crowd : ''
          }))
          .filter(
            (_, index) =>
              this.previewDataList[index] &&
              this.previewDataList[index].length > 0
          )
        const plan = {
          campaign_name: projectConfig.campaign_name.value,
          campaign_type: 'CAMPAIGN_TYPE_NORMAL',
          configured_status: 'AD_STATUS_NORMAL',
          daily_budget: projectConfig.daily_budget.value
            ? +projectConfig.daily_budget.value * 100
            : 0,
          total_budget: projectConfig.total_budget.value
            ? +projectConfig.total_budget.value * 100
            : 0,
          promoted_object_type: projectConfig.promoted_object_type.value,
          speed_mode: projectConfig.speed_mode.value,
          plan_rule: projectConfig.planRules.value === 'average' ? 1 : 2,
          create_rule:
            projectConfig.createRules.value === 'resource'
              ? 1
              : projectConfig.createRules.value === 'doc'
                ? 2
                : 3
        }
        // * 筛选出产生有效广告计划的账号，没有被分配到的账号不计入数据当中
        adConfig.dynamic_ad_spec.value = adConfig.dynamic_ad_spec.value.filter((_, index) => {
          return isValidArray(this.previewDataList[index])
        })
        creativityConfig.page_spec.value = creativityConfig.page_spec.value.filter((_, index) => {
          return isValidArray(this.previewDataList[index])
        })

        // * 数据广告
        const adgroups = {
          adgroup_name: adConfig.ad_name.value,
          promoted_object_type: projectConfig.promoted_object_type.value,
          begin_date:
            adConfig.putSchedule.value === 'NONE'
              ? formatTime(new Date(), 'yyyy-MM-dd')
              : adConfig.putSchedule.value[0],
          end_date:
            adConfig.putSchedule.value === 'NONE'
              ? ''
              : adConfig.putSchedule.value[1],
          bid_amount: +adConfig.bid_amount.value * 100,
          optimization_goal: adConfig.optimizationType.value,
          // time_series: Array.from({ length: 48 * 7 }, () => 1).join(''),
          time_series: adConfig.putTime.type === 'ALL_DAY' ? Array.from({ length: 48 * 7 }, () => 1).join('') : adConfig.putTime.value,
          automatic_site_enabled: false,
          site_set: adConfig.positionList.value,
          daily_budget: adConfig.daily_budget.value
            ? +adConfig.daily_budget.value * 100
            : 0,
          configured_status: 'AD_STATUS_NORMAL',
          dynamic_ad_spec: adConfig.dynamic_ad_spec.value.map((item) => ({
            account_id: item.dynamic_ad_spec.account_id,
            product_catalog_id: item.dynamic_ad_spec.product_catalog_id,
            product_mode: item.dynamic_ad_spec.product_mode || 'SINGLE',
            product_source: item.dynamic_ad_spec.product_source
          })),
          user_action_sets:
            adConfig.netWorkValue.value === 'WHOLE_NETWORK'
              ? ''
              : adConfig.netWorkValue.value.map((item) => ({
                type: 'USER_ACTION_SET_TYPE_WEB',
                id: item
              })),
          bid_strategy: adConfig.bid_strategy.value,
          expand_enabled: false,
          bid_mode:
            adConfig.bidMethod.value === 'oCPM'
              ? 'BID_MODE_OCPM'
              : 'BID_MODE_OCPC',
          smart_bid_type: 'SMART_BID_TYPE_CUSTOM'
        }
        if (adConfig.bidAdjustmentSwitch.value === true) {
          adgroups.bid_adjustment = adConfig.bid_adjustment.value
        }
        if (adConfig.trafficPackageType.value !== 'NONE') {
          adgroups.scene_spec = {
            mobile_union_industry: adConfig.trafficPackageType.value
          }
        }
        const adcreatives = {
          adcreative_name: creativityConfig.adcreative_name.value,
          adcreative_template_id: creativityConfig.adcreativeTemplateInfo.value,
          link_page_type: 'LINK_PAGE_TYPE_NOT_USED',
          page_spec: creativityConfig.page_spec.value.map((item) => ({
            ...item,
            page_type:
              'page_url' in item
                ? 'PAGE_TYPE_DEFAULT'
                : 'PAGE_TYPE_YUEBAO_OFFICIAL_ACCOUNT_ARTICLE'
          })),
          promoted_object_type: projectConfig.promoted_object_type.value,
          site_set: adConfig.positionList.value
        }
        // ! 针对激励模式的老数据，需要将page_spec改为数组结构的形式
        if (adcreatives.adcreative_template_id === 910) {
          adcreatives.page_spec = adcreatives.page_spec.map(item => {
            let pageInfoList = item.page_id || item.page_url
            if (!Array.isArray(pageInfoList)) {
              pageInfoList = _.cloneDeep([pageInfoList])
            }
            return {
              ...item,
              [`${item.page_id ? 'page_id' : 'page_url'}`]: pageInfoList
            }
          })
        }

        adcreatives.adcreative_elements = creativityConfig.brandInfo && creativityConfig.buttonText
          ? {
            brand: {
              brand_img: typeof creativityConfig.brandInfo.value.brand_img !== 'object' ? creativityConfig.brandInfo.value.brand_img : creativityConfig.brandInfo.value.brand_img.materialId,
              brand_name: creativityConfig.brandInfo.value.brand_name
            },
            button_text: creativityConfig.buttonText.value

          }
          : {}
        if (creativityConfig.brandInfo && 'brand_description' in creativityConfig.brandInfo.value) {
          adcreatives.adcreative_elements.brand.brand_description = creativityConfig.brandInfo.value.brand_description
        }
        const audiences = ConfigOrientation.select.map((item) => item.id)
        const materials = creativityConfig.adcreativeTemplateInfo.value === 910 ? [] : ConfigResource.select.map((item) => {
          const VIDEO_TYPE = ['video/mp4', 'video/avi', 'video/quicktime']
          // ! 表示"横竖"视频
          if ('video' in item) {
            return {
              video_id: item.video.materialId,
              material_url: item.image.src
            }
          }
          // 横版大图
          if (getElementType(item.mediaInfo) === 'Object') {
            return VIDEO_TYPE.includes(item.mediaInfo.format) ? { video_id: item.mediaInfo.materialId } : { material_id: [item.mediaInfo.materialId] }
          }
          // 横版三图

          if (getElementType(item.image) === 'Array') {
            return {
              material_id: [...item.image.map((img) => img.materialId)]
            }
          }
        })
        const documents = this.DONT_SHOW_IDS.includes(creativityConfig.adcreativeTemplateInfo.value) ? [] : ConfigDoc.select.map((item) => ({
          bottom_text: item.bottom_text || '',
          description: item.description,
          title: item.title || ''
        }))
        resolve({
          accounts,
          template,
          crowdPackages,
          plan,
          adgroups,
          adcreatives,
          audiences,
          materials,
          documents,
          is_combination: this.isOneToOne ? 1 : 0 // 是否是一对一
        })
      })
    },
    // 选择模板回显数据
    chooseSuccess (res) {
      this.resetData()
      this.resetParams()
      this.isEdit = true
      const {
        accounts,
        // adcreatives,
        // adgroups,
        // audiences,
        crowdPackages,
        // documents,
        // materials,
        // plan,
        template
      } = res
      getADQList({
        page: 1,
        pageSize: 9999
      }).then((res) => {
        let accountList = res.list
          .filter((item) =>
            accounts.find((val) => val.account_id === item.accountId)
          )
          .map((item) => {
            return {
              ...item,
              showTable: false
            }
          })
        accountList = accountList.map((item) => {
          return {
            ...item,
            crowd: JSON.parse(
              crowdPackages.find((pack) => pack.account_id === item.accountId)
                .audience_id || '[]'
            )
          }
        })
        this.accountSelection = accountList
        const {
          projectConfig,
          adConfig,
          creativityConfig,
          ConfigOrientation,
          ConfigResource,
          ConfigDoc
        } = JSON.parse(template.templateInfo)
        this.templateId = template.id
        this.$set(this.params, 'projectConfig', projectConfig)
        this.$set(this.params, 'adConfig', adConfig)
        this.$set(this.params, 'creativityConfig', creativityConfig)
        this.$set(this.params, 'ConfigOrientation', ConfigOrientation)
        this.$set(this.params, 'ConfigResource', ConfigResource)
        this.$set(this.params, 'ConfigDoc', ConfigDoc)
        this.templateName = template.templateName
        this.projectProperty = [
          'planRules',
          'createRules',
          'promoted_object_type',
          'speed_mode',
          'daily_budget',
          'total_budget',
          'campaign_name'
        ]
        this.adProperty = [
          'netWorkValue',
          'dynamicAdSpec',
          'bid_adjustment',
          'trafficPackageType',
          'putSchedule',
          'putTime',
          'bidMethod',
          'optimizationType',
          'bidType',
          'bid_strategy',
          'bid_amount',
          'daily_budget',
          'ad_name'
        ]
        if (adConfig.bidAdjustmentSwitch.value === true) {
          this.adProperty = [...this.adProperty, 'bid_adjustmentType']
        }
        this.creativityProperty = [
          'adcreativeTemplateInfo',
          'page_spec',
          'adcreative_name'
        ]

        if (this.params.creativityConfig.buttonText) this.creativityProperty = [...this.creativityProperty, 'buttonText']
        if (this.params.creativityConfig.brandInfo) this.creativityProperty = [...this.creativityProperty, 'brandInfo']
        const packageList = _.cloneDeep(this.params.ConfigOrientation.select)
        this.isOneToOne = template.isCombination === 1
        getAdqAudience({
          page: 1,
          pageSize: 999,
          search: ''
        }).then((res) => {
          const { list } = res
          const existList = packageList.reduce((acc, b, c) => {
            if (list.find(item => item.id === b.id)) acc = [...acc, b]
            return acc
          }, [])
          if (existList.length !== packageList.length) {
            this.$message.warning('检测到模板定向数据与源数据不匹配，已自动删除不匹配数据!')
            this.$set(this.params, 'ConfigOrientation', {
              select: existList
            })
          }
        })
      })
    },
    handleDelAccount (index) {
      this.accountSelection.splice(index, 1)
      // todo=>不重置计划其它模板数据
      // this.resetParams('notAll')
      // this.resetData(false, ['projectProperty'])
    },
    checkMixContent (cb) {
      cb()
      if (!this.isOneToOne) return true
      if (this.mixContentList.length === 0) {
        this.$message.warning('请配置素材文案！')
        return false
      }
      const flag = this.mixContentList.some(item => !item.doc || !item.resource)
      if (flag) {
        this.$message.warning('请完善素材文案!')
        return false
      }
      return true
    },
    async checkTimeSchedule (cb) {
      // ! '判断广告模块自定义时间是否小于当前时间’
      const { adConfig: { putSchedule } } = this.params
      if (putSchedule.value !== 'NONE') {
        const nowDate = new Date(formatTime(new Date(), 'yyyy-MM-dd'))
        const targetValue = new Date(putSchedule.value[0])
        if (+targetValue < +nowDate) {
          cb()
          await this.$confirm(`检测到广告模块自定义投放时间(${putSchedule.value[0]})小于当前时间(${formatTime(new Date(), 'yyyy-MM-dd')})，请修改时间之后再次提交!`, {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'warning'
          })
          return false
        }
      }
      return true
    },
    async checkCreativeType (cb) {
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      const { creativityConfig: { brandInfo } } = this.params
      const { adConfig: { positionList } } = this.params
      if (adcreativeTemplateInfo.value === 712 && positionList.value.includes('SITE_SET_MOBILE_UNION')) {
        if (!brandInfo) {
          cb()
          await this.$confirm('创意模块缺少品牌形象，请选择后再提交!', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'warning'
          })
          return false
        }
      }
      return true
    },
    async checkIsFlashType (cb) {
      const { creativityConfig: { adcreativeTemplateInfo }, projectConfig: { promoted_object_type } } = this.params
      if (adcreativeTemplateInfo.value === 1529 && promoted_object_type.value === 'PROMOTED_OBJECT_TYPE_ECOMMERCE') {
        cb()
        await this.$confirm('闪屏视频无法选择“商品推广”模式，请在计划模块改变模式之后再提交!', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        })
        return false
      }
      return true
    },
    async checkIsDocType (cb) {
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      const { projectConfig: { createRules } } = this.params
      if (adcreativeTemplateInfo.value === 722 && createRules.value === 'doc') {
        cb()
        this.$message.warning("贴片视频不需要文案，无法按照'文案'分配，请选择其它分配模式!")
        return false
      }
      return true
    },
    checkBecauseAccountModify (cb) {
      const { adConfig: { dynamic_ad_spec }, creativityConfig: { page_spec } } = this.params
      if (isValidArray(dynamic_ad_spec.value)) {
        if (this.accountSelection.some(({ accountId }) => !dynamic_ad_spec.value.find(item => item.accountId === accountId))) {
          this.$message.warning('请完善”广告“模块剩余账号的数据!')
          return false
        }
      }
      if (isValidArray(page_spec.value)) {
        if (this.accountSelection.some(({ accountId }) => !page_spec.value.find(item => item.account_id === accountId))) {
          this.$message.warning('请完善”创意“模块剩余账号的数据!')
          return false
        }
      }
      cb()
      return true
    },
    goBack () {
      this.$router.push({ name: 'AdPutList' })
    },
    clearTemplateData (cb) {
      this.$confirm('是否清除当前模板数据!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isEdit = false
          this.templateName = ''
          this.resetParams()
          this.resetData()
          this.$message.success('清除成功!')
          cb()
        })
        .catch(() => { })
        .finally(() => {
          cb()
        })
    },
    deleteItem (id) {
      if (id !== this.templateId) return
      this.resetParams()
      this.resetData()
      this.isEdit = false
      this.templateId = null
    },

    checkSwitchStatus () {
      const { ConfigResource, ConfigDoc } = this.params
      // ! 只有当素材和文案同时有内容才能进行划分
      return !ConfigResource || !isValidArray(ConfigResource.select) || !ConfigDoc || !isValidArray(ConfigDoc.select)
    },
    getMixContent () {
      const maxLen = Math.max(this.params.ConfigResource ? this.params.ConfigResource.select.length : 0, this.params.ConfigDoc ? this.params.ConfigDoc.select.length : 0)
      const mixContent = []
      for (let i = 0; i < maxLen; i++) {
        let value = {}

        const resource = (this.params.ConfigResource && this.params.ConfigResource.select[i]) || null
        const doc = (this.params.ConfigDoc && this.params.ConfigDoc.select[i]) || null
        value = { resource, doc, id: nanoid() }
        mixContent.push(value)
      }
      return mixContent
    },
    spliceItem (item, index, type) {
      item[type] = null
      // ! 如果两者都为空，则去除该项
      // if (!item.resource && !item.doc) {
      //   this.mixContentList.splice(index, 1)
      // }
    },
    getSwitchStatus () {
      const isDisabled = !this.params || !this.params.creativityConfig || !this.params.creativityConfig.adcreativeTemplateInfo || [722, 910].includes(this.params.creativityConfig.adcreativeTemplateInfo.value)
      if (isDisabled) {
        this.isOneToOne = false
        this.isSingle = false
      }
      return isDisabled
    },
    subSituationMixContentList () {
      return this.isSingle ? [this.mixContentList[this.curMixContentIndex]] : this.mixContentList
    },
    getType (val) {
      return isValidObject(val)
    },
    chooseSingle (index) {
      this.curMixContentIndex = index
      this.isSingle = true
      this.addContent('MixContent')
    },

    resetParams (type) {
      if (type === 'notAll') {
        delete this.params.adConfig
        delete this.params.creativityConfig
        delete this.params.ConfigOrientation
        delete this.params.ConfigResource
        delete this.params.ConfigDoc
      } else {
        this.params = {}
      }
    },
    changePromotedType () {
      delete this.params.creativityConfig
      this.params.ConfigResource = null
      this.creativityProperty = []
    },
    getMaxLength () {
      const { creativityConfig } = this.params
      // ! 横版小图3:2
      return creativityConfig && creativityConfig.adcreativeTemplateInfo.value === 714 ? 24 : 30
    },
    getMinLength () {
      const { creativityConfig } = this.params
      return creativityConfig && creativityConfig.adcreativeTemplateInfo.value === 714 ? 16 : 6
    },
    getPageSpecLen (accountId) {
      const { creativityConfig } = this.params
      const target = isValidArray(creativityConfig.page_spec.value) ? creativityConfig.page_spec.value.find(item => item.account_id === accountId) : null
      if (target) {
        return target.page_id ? isValidArray(target.page_id) ? target.page_id.length : 1 : isValidArray(target.page_url) ? target.page_url.length : 1
      }
      return 0
    },
    resetData (flag = true, saveItemList = []) {
      if (flag) {
        this.accountSelection = []
      }
      if (!this.isEdit) {
        this.templateId = null
      }
      this.isOneToOne = false
      this.isSingle = false
      this.previewDataList = []
      if (!saveItemList.includes('projectProperty')) this.projectProperty = []
      if (!saveItemList.includes('adProperty')) this.adProperty = []
      if (!saveItemList.includes('creativityProperty')) { this.creativityProperty = [] }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.needTips) {
      if (confirm('确定要继续吗？未保存的数据将会丢失!') === true) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  components: {
    AddCrowd,
    ConfigAd,
    ConfigDoc,
    AddAccount,
    MixContent,
    TemplatePart,
    TemplateStyle,
    ConfigBaseInfo,
    ConfigResource,
    ChooseTemplate,
    ConfigCreativity,
    ConfigOrientation
  }
}
</script>
<style lang='scss' scoped>
@mixin line-part($name) {
  display: inline-block;
  border: 1px solid $borderColor;
  height: 38px;
  line-height: 38px;
  border-radius: 8px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 14px;
  transition: all 0.2s;
  max-width: 90%;
  cursor: default;
  &:hover {
    border-color: $colorRed;
    color: $colorRed;
  }
}
.line-part {
  @include line-part("default");
  display: block;
  position: relative;
  img {
    width: 20px;
    height: 20px;
  }
  .del-icon {
    position: absolute;
    top: -10%;
    left: 97.5%;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    color: $colorRed;
    display:none;
  }
  &:hover{
    .del-icon{
      display: block;
    }
  }
}
.media-type,
.three-pic {
  height: auto;
  line-height: auto;
  padding: 0;
  > p {
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      object-fit: contain;
    }
  }
  > p:nth-of-type(1) {
    border-bottom: 1px solid $borderColor;
  }
  .doc-list {
    > p {
      padding: 0 10px;
    }
    > p:not(:nth-last-of-type(1)) {
      padding: 0 10px;
      border-bottom: 1px solid $borderColor;
    }
  }
}
.three-pic {
  > p:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid $borderColor;
  }
}
.ad-put {
  .ad-put-operation {
    width: 100%;
    top: -20px;
    position: sticky;
    background: #fff;
    z-index: 999;
    margin-top: -20px;
    padding: 20px 0;
    border-bottom: 1px solid $borderColor;
    h3 {
      line-height: 34px;
    }
  }
  &-container {
    .part {
      margin-bottom: 30px;
      position: relative;
      // & > div:nth-of-type(1) {
      //   overflow: hidden;
      //   /deep/ .template-part,
      //   .template-style {
      //     margin-bottom: 20px;
      //     margin-right: 10px;
      //   }
      // }
      &-container {
        overflow: hidden;
        /deep/ .template-part,
        .template-style {
          margin-bottom: 20px;
          margin-right: 10px;
        }
      }
      .edit {
        position: absolute;
        cursor: pointer;
        font-size: 14px;
        left: 90%;
        top: 0;
      }
    }
    .batch-content {
      .switch {
        margin-left: 20px;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    .another-info {
      margin-top: 20px;
      &-container {
        .account {
          @include line-part("account");
          position: relative;
          overflow: visible;
          width: 310px;
          span {
            display: inline-block;
            width: 100%;
          }
          i {
            position: absolute;
            top: -10%;
            left: 97%;
            color: $colorRed;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            display:none;
          }
          &:hover{
            i{
              display: block;
            }
          }
        }
        .crowd {
          padding: 4px 0px;
          margin-bottom: 20px;
          font-size: 14px;
        }
      }
    }
  }
}
.preview-table {
  margin-bottom: 40px;
  border: 1px solid $borderColor;
  border-radius: 8px;
  .account-name {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
.per-part {
  .three-pic-item {
    background: $borderColor;
    height: 50px;
    border-radius: 4px;
  }
  img {
    width: 40px;
    height: 30px;
    object-fit: contain;
  }
}
@mixin custom {
  border-radius: 8px;
  border: 1px dashed #e6e8ed;
  @include flex(center, center);
  cursor: pointer;
  &:hover {
    color: $main;
    border-color: $main;
  }
}
@mixin mixContentImg {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  object-fit: contain;
  background-color: #e6e8ed;
  line-height: 32px;
}
.mix-content {
  &-item {
    background: #fbfcfd;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 14px;
    .resource-content {
      .mix-item {
        @include flex(flex-start, center);
        border-radius: 8px;
        border: 1px solid #e6e8ed;
        margin: 0 0 10px 0;
        padding: 5px 10px;
        line-height: 38px;
        position: relative;
        img {
          @include mixContentImg;
        }
      }
      .mix-item-style-02 {
        border-radius: 6px;
        margin-bottom: 10px;
        border: 1px solid #e6e8ed;
        position: relative;
        > p {
          padding: 10px;
        }
        img {
          @include mixContentImg;
        }
      }
      .mix-item-style-03 {
        border-radius: 6px;
        margin-bottom: 10px;
        border: 1px solid #e6e8ed;
        position: relative;
        > p {
          padding: 10px;
          @include flex(flex-start, center);
          &:not(:nth-last-of-type(1)) {
            border-bottom: 1px solid $borderColor;
          }
        }

        img {
          @include mixContentImg;
        }
      }
      .upload-resource {
        height: 38px;
        margin-bottom: 10px;
        @include custom;
      }
    }
    .doc-content {
      position: relative;
      .doc-list {
        border-radius: 8px;
        border: 1px solid #e6e8ed;
        > p {
          padding: 10px;
          line-height: 1.5;
        }
        > p:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #e6e8ed;
        }
      }
      .upload-doc {
        height: 100px;
        @include custom;
      }
    }
    .del-icon {
      position: absolute;
      top: -10%;
      left: 97%;
      color: $colorRed;
      border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>
