<template>
  <div class="vertical-pic flex-row-start-start">
    <div class="vertical-pic-container">
      <div
        class="pic-item flex-row-start-start"
        v-for="(item, index) in resourceList"
        :key="item.id"
      >
        <span style="margin-top: 5px">图片</span>
        <div class="pic-item-container flex-row-start-center">
          <div class="">
            <input
              type="file"
              :ref="`uploadInput`"
              @change="
                (file) => {
                  handleFileChange(index, file);
                }
              "
              style="display: none"
            />
            <div class="detail flex-center-center">
              <div
                class="tips"
                @click="handleUpload(index)"
                v-if="!item.mediaInfo.src && !item.loading"
              >
                <p>点击上传</p>
                <p>1080px * 1920px</p>
                <p>JPG/JPEG/PNG/GIF，< 140KB，gif图时长<=5s</p>
              </div>
              <div
                class="item-image"
                v-else-if="item.mediaInfo.src && !item.loading"
              >
                <img :src="item.mediaInfo.src" alt="" />
                <div
                  class="reset-upload-hover flex-center-center"
                  @click="
                    () => {
                      handleUpload(index);
                    }
                  "
                >
                  <span style="font-size: 14px; color: #fff; font-weight: bold"
                    >点击重新上传</span
                  >
                </div>
                <i
                  class="el-icon-error"
                  v-if="item.mediaInfo.src"
                  @click.stop="item.mediaInfo.src = null"
                ></i>
              </div>
              <div class="loading-tips flex-center-center" v-if="item.loading">
                <p>
                  <i class="el-icon-loading"></i>
                  <span style="margin-left: 5px">上传中</span>
                </p>
              </div>
            </div>
            <div
              class="library-choose"
              v-if="Array.isArray(resourceList) && resourceList.length > 0"
            >
              <p @click="chooseLibrary(index)">
                <span><i class="el-icon-picture"></i>从素材库中心选择</span>
              </p>
            </div>
          </div>
          <div
            class="del-action flex-center-center"
            v-if="resourceList.length > 1"
            @click="resourceList.splice(index, 1)"
          >
            <i class="el-icon-minus"></i>
          </div>
        </div>
      </div>
      <div
        class="control-twins-morebtn flex-center-center"
        @click="addContent"
        v-if="totalNum - resourceList.length !== 0"
      >
        <div>
          <i class="el-icon-plus"></i>
          <span>还可以添加{{ totalNum - resourceList.length }}组素材</span>
        </div>
      </div>
    </div>
    <div class="preview-content">
      <div class="preview-content-container">
        <h3 style="text-align: center; margin-bottom: 16px">预览效果</h3>
        <div class="screen template-view">
          <template v-if="[1, 2, 3].includes(unionIndex)">
            <div class="image-header"></div>
          </template>
          <span class="skip" v-if="checkSkipPreview()">跳过 3</span>
          <template v-if="[2,3].includes(unionIndex)">
              <div class='above'></div>
              <div class='content' v-if="unionIndex=== 2">
                 <img class='brand-image' :src="brandSrc" alt="">
                 <img class='close-icon' src="https://puui.qpic.cn/vupload/0/1568885377802_fdhfck89dg.png/0" alt="">
                 <img class='custom-ad' src="https://puui.qpic.cn/vupload/0/20190809_1565329184785_4ic6zomlorf.png/0" alt="">
              </div>
              <div class='below'></div>
              <div class='layout-hover flex-center-center' v-if='unionIndex === 3'>
                  <div class='layout-hover-container'>
                      <img class='main-image' :src="curInfo ? curInfo.mediaInfo.src : 'http://i.gtimg.cn/ams-web/public/ad-creative-preview/common/default-image.png'" alt="">
                      <img class='close-icon' src="https://puui.qpic.cn/vupload/0/1568885377802_fdhfck89dg.png/0" alt="">
                 <img class='custom-ad' src="https://puui.qpic.cn/vupload/0/20190809_1565329184785_4ic6zomlorf.png/0" alt="">
                  </div>
              </div>
          </template>
          <img
            class="default-image"
            src="http://i.gtimg.cn/ams-web/public/ad-creative-preview/common/default-image.png"
            v-if="(curInfo === undefined && curInfo === null) && ![1,2,3].includes(unionIndex)"
            alt=""
          />
          <img
            class="main-image"
            v-else-if="checkCanPreview()"
            :src="curInfo.mediaInfo.src"
            alt=""
          />
          <span class="tips-text" v-else-if='checkTipsText()'>暂不支持在此版位播放</span>
          <div class="logo">
            <img :src="getLogoSrc()" alt="" />
          </div>
        </div>
        
       
       
        <div
          class="union-type-list"
          v-if="
            templateActiveIndex &&
            templateList[templateActiveIndex].en === 'SITE_SET_MOBILE_UNION'
          "
        >
          <div class="union-type-list-container">
            <div class="union-item-name">{{ unionTypeList[unionIndex] }}</div>
            <div class="dot-list flex-center-center">
              <div
                :class="{ 'is-active': unionIndex + 1 === n }"
                @click="handleDotClick(n)"
                v-for="n in unionTypeList.length"
                :key="n"
                class="dot"
              ></div>
            </div>
          </div>
        </div>
        <div class="template-list flex-row-start-start-wrap">
          <span
            :class="{ 'is-active': templateActiveIndex === index }"
            @click="templateActiveIndex = index"
            v-for="(item, index) in templateList"
            class="line-overflow"
            :key="index"
            >{{ item.cn }}</span
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCancel"
    >
      <media-library
        :show="showMediaDialog"
        :maxNum="1"
        mediaType="image"
        ref="mediaLibrary"
      >
        <div slot="footer" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="
              () => {
                handleOk();
              }
            "
            >确定</el-button
          >
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'
import { nanoid } from 'nanoid'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import { imageStatusCheck } from '@/assets/js/utils'
const IMAGE_SIZE_LIMIT = 140 // 140kb
export default {
  name: 'VerticalPic',
  props: {
    previewData: {
      type: Array,
      default: null
    },
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      resourceList: [
        {
          id: nanoid(),
          loading: false,
          mediaInfo: {}
        }
      ],
      totalNum: 20,
      curIndex: null,
      showMediaDialog: false,
      templateList: [],
      templateActiveIndex: null,
      unionIndex: 0,
      brandSrc: null,
      unionTypeList: [
        '优量汇开屏模板',
        '优量汇原生图文模板',
        '优量汇Banner图文模板',
        '优量汇插屏模板'
      ]
    }
  },
  watch: {
    resourceList: {
      handler (newV) {
        this.$emit('uploadData', newV)
      },
      immediate: true
    },
    previewData: {
      handler (newV) {
        if (newV !== undefined && newV !== null) {
          this.echoData()
        }
      },
      immediate: true
    },
    params: {
      handler (newV) {
        if (newV !== undefined && newV !== null) {
          this.getTemplateList()
        }
      },
      immediate: true
    },
    unionIndex: {
      handler (newV) {
        if (newV === 2) {
          this.getBrandImage()
        }
      }
    },
    templateActiveIndex: {
      handler (newV) {
        this.unionIndex = 0
      }
    }
  },
  computed: {
    curInfo: {
      get () {
        return this.resourceList.find((item) => !!item.mediaInfo.src)
      },
      set () {}
    }
  },
  methods: {
    echoData () {
      this.resourceList = _.cloneDeep(this.previewData)
    },
    getTemplateList () {
      const mapping = {
        SITE_SET_KANDIAN: {
          cn: 'QQ看点',
          en: 'SITE_SET_KANDIAN'
        },
        SITE_SET_QQ_MUSIC_GAME: {
          cn: 'QQ、腾讯音乐及游戏',
          en: 'SITE_SET_QQ_MUSIC_GAME'
        },
        SITE_SET_TENCENT_NEWS: {
          cn: '腾讯新闻',
          en: 'SITE_SET_TENCENT_NEWS'
        },
        SITE_SET_TENCENT_VIDEO: {
          cn: '腾讯视频',
          en: 'SITE_SET_TENCENT_VIDEO'
        },
        SITE_SET_MOBILE_UNION: {
          cn: '优量汇',
          en: 'SITE_SET_MOBILE_UNION'
        }
      }
      const {
        bid_adjustment: {
          value: { site_set_package }
        }
      } = this.params.adConfig
      site_set_package.forEach((item) => {
        this.templateList = [...this.templateList, mapping[item.site_set[0]]]
      })
      this.templateActiveIndex = this.templateList.length ? 0 : null
    },
    addContent () {
      this.resourceList.push({
        mediaInfo: {},
        loading: false,
        id: nanoid()
      })
    },
    chooseLibrary (index) {
      this.curIndex = index
      this.showMediaDialog = true
    },
    handleOk () {
      let { imageList } = this.$refs.mediaLibrary
      imageList = imageList.slice(0, 1)
      if (!imageList.every(this.checkImage)) {
        return this.$message.error('所选图片有不符合大小或者尺寸的内容！')
      }
      imageList.forEach((item, index) => {
        if (this.checkImage(item)) {
          this.$set(this.resourceList, this.curIndex, {
            id: this.resourceList[this.curIndex].id,
            mediaInfo: {
              src: item.src,
              name: item.resources[0].title.replace(/\.\w+/g, ''),
              url: item.src,
              materialId: item.resources[0].materialId,
              widthAndHigh: item.resources[0].widthAndHigh,
              title: item.resources[0].title.replace(/\.\w+/g, ''),
              size: item.resources[0].size,
              format: item.resources[0].format
            },
            loading: false
          })
        }
      })
      this.showMediaDialog = false
    },
    handleCancel () {
      this.showMediaDialog = false
    },
    checkImage (file) {
      const { resources } = file
      const imageSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+imageSize > IMAGE_SIZE_LIMIT) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === 1080 && height === 1920) return true
      return false
    },
    async handleConfirm () {
      if (this.resourceList.some((item) => !item.mediaInfo.src)) {
        this.$message.warning('请检查图片配置!')
        return false
      }
      let needUplaodResourceList = _.cloneDeep(
        this.resourceList.filter((item) => !item.mediaInfo.materialId)
      )
      if (needUplaodResourceList.length === 0) {
        this.$emit('confirm', this.resourceList)
        return true
      } else {
        needUplaodResourceList = needUplaodResourceList.map((item) => {
          const { mediaInfo } = item
          return {
            materialType: 2,
            title: mediaInfo.title,
            materialResources: {
              title: mediaInfo.title,
              widthAndHigh: mediaInfo.widthAndHigh,
              format: mediaInfo.format,
              size: mediaInfo.size,
              url: mediaInfo.url,
              sort: 1
            }
          }
        })
        const res = await addMaterialWare(needUplaodResourceList)
        res.forEach((imgInfo) => {
          const targetIndex = this.resourceList.findIndex(
            (item) => item.mediaInfo.src === imgInfo.resources[0].url
          )
          if (targetIndex > -1) {
            this.$set(this.resourceList, targetIndex, {
              ...this.resourceList[targetIndex],
              mediaInfo: {
                ...imgInfo.resources[0],
                src: imgInfo.resources[0].url
              }
            })
          }
        })
        this.$emit('confirm', this.resourceList)
        return true
      }
    },
    handleUpload (index) {
      this.$refs.uploadInput[index].click()
    },
    async handleFileChange (index, e) {
      const files = [...e.target.files]
      const targetFile = files[0]
      const {
        flag,
        message,
        file: compressFile
      } = await imageStatusCheck(targetFile, IMAGE_SIZE_LIMIT, {
        width: 1080,
        height: 1920
      })
      if (!flag) {
        this.$message.error(`${message}`)
        this.$refs.uploadInput[index].value = null
        return
      }
      try {
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          loading: true
        })
        const res = await uploadFile({ file: compressFile })
        const type = compressFile.type.split(/\//g)
        this.$set(this.resourceList, index, {
          id: this.resourceList[index].id,
          loading: false,
          mediaInfo: {
            src: res,
            name: compressFile.name.replace(/\.\w+/g, ''),
            url: res,
            widthAndHigh: '1080 * 1920',
            size: `${Math.floor(compressFile.size / 1024)}KB`,
            title: compressFile.name.replace(/\.\w+/g, ''),
            materialId: null,
            format: type[type.length - 1]
          }
        })
      } catch (e) {
        this.$message.error('上传失败，请稍后再试!')
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          loading: false
        })
      } finally {
        this.$refs.uploadInput[index].value = null
      }
    },
    getLogoSrc () {
      if (
        this.templateActiveIndex === null ||
        this.templateActiveIndex === undefined
      ) { return }
      const name = this.templateList[this.templateActiveIndex].en
      if (name === 'SITE_SET_KANDIAN') { return 'http://qzonestyle.gtimg.cn/gdt_ui_proj/imghub/dist/qq-browser-banner.png?max_age=31536000' }
      if (name === 'SITE_SET_QQ_MUSIC_GAME') { return 'http://puui.qpic.cn/vupload/0/20190807_1565168279744_h64v8lty7pa.png/0' }
    },
    checkCanPreview () {
      return (
        this.curInfo &&
        this.curInfo.mediaInfo.src &&
        (this.templateActiveIndex !== null ||
          this.templateActiveIndex !== undefined) &&
        !['SITE_SET_TENCENT_NEWS', 'SITE_SET_TENCENT_VIDEO'].includes(
          this.templateList[this.templateActiveIndex].en
        ) &&
        ![2, 3].includes(this.unionIndex)
      )
    },
    checkSkipPreview () {
      return ![1, 2, 3].includes(this.unionIndex) && !['SITE_SET_TENCENT_NEWS', 'SITE_SET_TENCENT_VIDEO'].includes(
        this.templateList[this.templateActiveIndex].en
      )
    },
    checkTipsText () {
      return ['SITE_SET_TENCENT_NEWS', 'SITE_SET_TENCENT_VIDEO'].includes(
        this.templateList[this.templateActiveIndex].en) && this.templateList[this.templateActiveIndex].en !== 'SITE_SET_MOBILE_UNION'
    },
    getBrandImage () {
      const { creativityConfig: { brandInfo: { value: { brand_img } } } } = this.params
      this.brandSrc = brand_img.url
    },
    handleDotClick (n) {
      this.unionIndex = n - 1
    },
    clearResource () {
      this.$confirm('确定清空吗？清空的数据无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resourceList = [
            {
              id: nanoid(),
              loading: false,
              mediaInfo: {}
            }
          ]
        })
        .catch(() => {})
    }
  },
  components: {
    MediaLibrary
  }
}
</script>
<style lang='scss' scoped>
$bgColor: #f5f6f8;
$borRadius: 8px;
.vertical-pic {
  &-container {
    .pic-item {
      padding: 20px;
      border-radius: $borRadius;
      border: 1px solid $borderColor;
      height: 254px;
      margin-bottom: 20px;
      > span {
        flex-basis: 50px;
      }
      &-container {
        .detail {
          border-radius: $borRadius;
          background-color: $bgColor;
          width: 600px;
          height: 160px;
          border: 1px dashed $borderColor;
          .tips {
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            > p {
              margin-bottom: 10px;
              &:nth-of-type(1) {
                font-weight: bold;
              }
              &:nth-last-of-type(1) {
                font-size: 12px;
              }
            }
          }
          .item-image {
            position: relative;
            width: 252px;
            height: 140px;
            background: #eceef1;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            i {
              position: absolute;
              left: 95%;
              top: -5%;
              color: $colorRed;
              z-index: 999;
              font-size: 16px;
              opacity: 0;
              background: $main-background;
              border-radius: 50%;
            }
            .reset-upload-hover {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              opacity: 0;
              cursor: pointer;
              transition: all 0.25s;
              background-color: rgba(0, 0, 0, 0.4confirm);
            }
            &:hover {
              .reset-upload-hover,
              i {
                opacity: 1;
              }
            }
          }
          .loading-tips {
            width: 252px;
            height: 140px;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
          }
        }
        .del-action {
          margin-left: 20px;
          border-radius: $borRadius;
          width: 30px;
          height: 30px;
          border: 1px solid $borderColor;
          background-color: $bgColor;
          cursor: pointer;
        }
      }
    }
    .library-choose {
      margin-top: 5px;
      > p {
        cursor: pointer;
        padding: 8px 0;
        transition: all 0.3s;
        &:hover {
          color: $main;
        }
      }
    }
    .control-twins-morebtn {
      cursor: pointer;
      width: 750px;
      height: 36px;
      line-height: 36px;
      border: 1px dashed $borderColor;
      border-radius: $borRadius;
      background-color: $bgColor;
    }
  }
  .preview-content {
    &-container {
      border: 1px solid $borderColor;
      border-radius: 8px;
      margin-left: 20px;
      padding: 20px 25px;
      .template-view {
        transform: scale(0.430152);
        margin: -380.089px -213.693px;
      }
      .screen {
        background: rgb(245, 246, 248);
        width: 750px;
        height: 1328px;
        position: relative;
        border-radius: 2px;
        .skip {
          position: absolute;
          top: 40px;
          right: 30px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 20px;
          width: 100px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
        .logo {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: -6px;
          z-index: 1;
          opacity: 0.8;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .default-image {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 560px;
          object-fit: contain;
          transform: translate(-50%, -50%);
        }
        .main-image {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          z-index: -1;
        }
        .tips-text {
          @include verticalCenter;
          font-size: 30px;
        }
        .image-header {
          position: absolute;
          top: 0;
          width: 100%;
          background-image: url("https://puui.qpic.cn/vupload/0/20190807_1565168944896_dc8ww1a2axr.png/0");
          background-repeat: no-repeat;
          background-size: contain;
          height: 118px;
        }
        .above{
            position: relative;
            height:644px;
            background:url('https://puui.qpic.cn/vupload/0/20190807_1565168913404_9hp6wc6neiu.png/0') no-repeat;
            background-size: contain;
            top:118px;
        }
        .content{
                background-color: rgb(255, 255, 255);
                height: 117px;
                overflow: hidden;
                position: absolute;
                top: 118px;
                width: 750px;
                .brand-image{
                       width:117px;
                       height:auto;

                }
                .close-icon{
                  position: absolute;
                  top:5%;
                  right:0;
                  width:40px;
                }
                .custom-ad{
                    position: absolute;
                    bottom:0;
                    right:0;
                    width:92px;
                }
        }
        .below{
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            height:572px;
            background:url('http://puui.qpic.cn/vupload/0/20190807_1565168928080_c1iclfy5zl.png/0') no-repeat;
            background-size: contain;
        }
        .layout-hover{
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-color:rgba(0, 0, 0, 0.5);
            &-container{
                    align-items: center;
                    background-color: rgb(245, 246, 248);
                    display: flex;
                    height: 1066px;
                    overflow: hidden;
                    width: 600px;
                    position: relative;
                    .main-image{
                        width:100%;
                        object-fit: contain;
                        z-index:1;
                    }
                    .close-icon{
                        position: absolute;
                        width:40px;
                        height:40px;
                        right:3%;
                        top:2%;
                        z-index:2;
                    }
                    .custom-ad{
                        position: absolute;
                        width:92px;
                        right:0;
                        bottom: 0;
                    }

            }
        }

      }
      .template-list {
        margin-top: 10px;
        font-size: 12px;
        > span {
          cursor: pointer;
          padding: 0 10px;
          width: 95px;
          height: 32px;
          line-height: 32px;
          border: 1px solid $borderColor;
          border-radius: 4px;
          text-align: center;
          margin: 0 10px 10px 0;
        }
        .is-active {
          border-color: $main;
          color: $main;
        }
      }
      .union-type-list {
        margin-top: 20px;
        &-container {
          .union-item-name {
            text-align: center;
            margin-bottom: 10px;
          }
          .dot-list {
            .dot {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #ccc;
              margin-left: 10px;
              cursor: pointer;
            }
            .is-active {
              background: $main;
            }
          }
        }
      }
    }
  }
}
</style>
