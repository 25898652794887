export const transformText = {
    average:'各账户平均分配',
    repeat:'各账户重复分配',
    resource:'按素材',
    doc:'按文案',
    orienteering:'按定向',
    PROMOTED_OBJECT_TYPE_ECOMMERCE:'商品推广',
    PROMOTED_OBJECT_TYPE_LEAD_AD:'销售线索搜集',
    SPEED_MODE_STANDARD:'标准投放',
    SPEED_MODE_FAST:'加速投放',
    dynamicAdSpec:"全部账户已选择"

}