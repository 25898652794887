<template>
  <el-dialog
    title="选择封面图"
    width="900px"
    top="20px"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="cover-image-container">
      <div class="cover-image-list">
        <el-tabs v-model="editableTabsValue" type="card">
          <el-tab-pane
            v-for="(item, index) in videoList"
            :key="index"
            :label="`封面图${index + 1}`"
            :name="`${index + 1}`"
          >
            <div class="cover-image-item">
              <div class="item-detail flex-row-start-start-wrap">
                <div
                  class="image-item"

                  v-for="(img, index2) in coverImageList[index]"
                  :key="img"
                  @click='addContent(img,index,index2)'
                >
                  <img :class="{'active':index2 === activeIndex}" :src="img" alt="" />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div slot="footer" class='button-list'>
        <el-button type='primary' @click='handleOk'>确定</el-button>
        <el-button @click='handleCancel'>取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
const LIMIT_NUMBER = 12
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    videoList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editableTabsValue: '1',
      coverImageList: [], // 封面图片合集
      selectList: [], //
      activeIndex: null
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        } else {
          this.selectList = []
          this.activeIndex = null
          this.editableTabsValue = '1'
        }
      }
    },
    editableTabsValue: {
      handler (newV) {
        this.activeIndex = null
        if (newV) {
          const val = this.selectList[+newV - 1]
          if (val) {
            this.activeIndex = val.index
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    initData () {
      if (this.videoList.length > 0) {
        this.selectList = new Array(this.videoList.length)
        this.coverImageList = []
        this.videoList.forEach((video) => {
          const duration = video.videoTime.split(':').reduce((acc, b, c) => {
            if (c === 0) acc += +b * 3600
            if (c === 1) acc += +b * 60
            if (c === 2) acc += +b
            return acc
          }, 0)
          const widthAndHigh = video.widthAndHigh
          const width = +widthAndHigh.split('*')[0].trim()
          const height = +widthAndHigh.split('*')[1].trim()
          const arr = Array.from(
            { length: LIMIT_NUMBER < duration ? LIMIT_NUMBER : duration },
            (_, index) => {
              return `${video.url}?vframe/jpg/offset/${index}/w/${width}/h/${height}`
            }
          )
          this.coverImageList = [...this.coverImageList, arr]
        })
      }
    },
    handleClose () {
      this.$emit('close')
    },
    addContent (img, index, index2) {
      this.selectList.splice(index, 1, {
        src: img,
        index: index2
      })
      this.activeIndex = index2
    },
    handleOk () {
      if (this.selectList.length < this.videoList.length) return this.$message.warning('请选择封面图！')
      this.$emit('chooseCoverSucc', this.selectList)
      this.handleClose()
    },
    handleCancel () {
      this.handleClose()
    }
  }
}
</script>
<style lang='scss' scoped>
.cover-image-item {
  height: 650px;
  overflow: auto;
  .image-item {
    width: 200px;
    height: 250px;
    img {
      margin:5px;
      background: $borderColor;
      padding: 10px 0;
      border-radius: 8px;
      width: 150px;
      height: 200px;
      object-fit: contain;
    }
  }
}
.active{
    box-shadow: 0 0 0 3px #07c160;
}
</style>
