<template>
  <div class="template-style">
    <div class="template-style-title flex-row-between-start">
      <span>{{ title }}</span>
      <span class="config" @click="setConfig">配置</span>
    </div>
    <div class="template-style-container">
      <div class="detail">
        <div class='detail-container' >
          <slot :name='slotName'></slot>
        </div>
        <div class="add-config" >
          <div class="add-config-button flex-center-center" @click='addContent'>
            <i class='el-icon-plus'></i>
            <span>{{tips}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    slotName: {
      type: String,
      default: 'default'
    },
    tips:{
      type:String,
      default:'添加内容'
    }
    
  },
  data () {
    return {
      copyData: null
    }
  },
  watch: {
    data: {
      handler (newV) {
        if (newV) {
          this.copyData = JSON.parse(JSON.stringify(newV))
        }
      },
    }
  },
  methods: {
    setConfig () {
      this.$emit('setConfig')
    },
    addContent () {
      this.$emit('addContent')
    }
  }
}
</script>
<style lang='scss' scoped>
.template-style {
  width: 400px;
  overflow: hidden;
  color: $fontDarkColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  &-title {
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    font-weight: bold;
    font-size: 14px;
    background-color: rgb(251, 252, 253);
    border-bottom: 1px solid $borderColor;
    .config {
      color: $configColor;
      cursor: pointer;
    }
  }
  &-container{
    .detail {
      padding: 24px;
      height: 400px;
      overflow: auto;
    }
    .add-config{
      &-button{
        height:36px;
        line-height: 36px;
        border: 1px dashed $borderColor;
        border-radius:8px;
        font-size:14px;
        cursor: pointer;
        i{
          font-weight: bold;
          color:#ccc;
          margin-right:10px;
        }
        &:hover{
          background-color:#f7f9fc
        }
      }
    }
  }
}
</style>
