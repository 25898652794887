<template>
  <el-dialog
    width="1200px"
    append-to-body
    top="20px"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div slot="title">
      添加账号
    </div>
    <div class="container">
      <dy-table
        ref="mainTable"
        refName="subTable"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="600px"
        :rowList="rowList"
        :loading="loading"
        :dataList="dataList"
        :showPagination="true"
        :isUseCheckbox="true"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
        @handleRowClick="handleRowClick"
      >
        <template #filter>
          <el-select
            v-model="currentOfficial"
            placeholder="请选择公众号"
            @change="handleOfficialChange"
            style="width: 140px"
            class="margin-right-ten"
            clearable
            filterable
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span>
            </el-option>
          </el-select>
          <el-select
            v-model="bookId"
            placeholder="书籍"
            filterable
            remote
            clearable
            reserve-keyword
            :remote-method="getBook"
            style="width: 150px"
            class="margin-right-ten"
            @change="handleBookChange"
          >
            <el-option
              v-for="(item, index) in bookList"
              :key="index"
              :label="item.bookName"
              :value="item.id"
            ></el-option>
          </el-select>
          <c-input
            placeholder="账户id"
            v-model="accountId"
            style="width: 200px"
            class="margin-right-ten"
            :onlyNum="true"
          >
            <el-button slot="append" icon="el-icon-search" @click="pageChange(1)"></el-button>
          </c-input>
          <c-input
            placeholder="账号名称"
            v-model="accountName"
            style="width: 200px"
            class="margin-right-ten"
          >
            <el-button slot="append" icon="el-icon-search" @click="pageChange(1)"></el-button>
          </c-input>
          <c-input placeholder="备注" v-model="remark" style="width: 150px" class="margin-right-ten">
            <el-button slot="append" icon="el-icon-search" @click="pageChange(1)"></el-button>
          </c-input>
          <c-button
            type="primary"
            class="margin-right-ten"
            @click="(cb) => { batchEditRemark(cb) }"
            :disabled="select.length === 0"
          >添加备注</c-button>
          <el-tag type>共{{ total }}条</el-tag>
        </template>
        <template #status="{ row }">
          <el-tag :type="getTagType(row).type" :style="getTagStyle(row)">
            {{
              getTagType(row).text
            }}
          </el-tag>
        </template>
      </dy-table>
      <div class="button-list">
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getBookList
} from '@/api/account'
import { batchEditADQRemark, getADQList } from '@/api/tencent'
import { mapGetters } from 'vuex'
export default {
  name: 'AddAccount',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      page: 1,
      total: 0,
      select: [],
      bookId: '',
      bookList: [],
      remark: '', // 备注
      loading: false,
      accountName: '',
      currentOfficial: null,
      isRenderAuto: true, // ! 是否是回显自动勾选表格行
      accountId: '',
      search: '',
      rowList: [
        {
          label: 'id',
          prop: 'id',
          width: 50
        },
        {
          label: '账户id',
          prop: 'accountId',
          width: '80'
        },
        {
          label: '账户名',
          prop: 'accountName',
          popover: true
        },
        {
          label: '公众号',
          prop: 'channelName',
          popover: true
        },
        {
          label: '书籍',
          prop: 'bookName'
        },
        {
          label: '备注',
          prop: 'remark'
        },
        {
          label: '状态',
          prop: 'status',
          slot: true
        }
      ],
      dataList: [],
      pageSize: 15
    }
  },
  computed: {
    channelList () {
      const { officialList } = this
      return officialList.filter((item) => item.status === 1)
    },
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {
      }
    },
    ...mapGetters(['officialList'])
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (newV.name === this.$options.name && newV.value) {
          this.select = JSON.parse(JSON.stringify(this.account))
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    // 新增点击表格行选中
    handleRowClick (row, column, event) {
      this.$refs.mainTable.$refs.subTable.toggleRowSelection(row)
    },
    initData () {
      this.isRenderAuto = true
      this.loading = true
      this.getBookName()
      getADQList({
        page: this.page,
        remark: this.remark,
        bookId: this.bookId,
        pageSize: this.pageSize,
        accountId: this.accountId,
        accountName: this.accountName,
        accountChannelId: this.currentOfficial
      })
        .then(async (res) => {
          res.list.forEach((item) => {
            item.checked = false
          })
          this.dataList = res.list
          this.total = res.total
          this.loading = false
          await this.echoCheckedStatus()
          await this.checkSelectionList()
        })
        .finally(() => {
          this.loading = false
          this.isRenderAuto = false
        })
    },
    // ! 回显勾选状态
    echoCheckedStatus () {
      return new Promise((resolve) => {
        if (this.account.length === 0) return resolve()
        this.account.forEach((account) => {
          const targetItem = this.dataList.find(
            (item) => item.accountId === account.accountId
          )
          if (targetItem) {
            this.$refs.mainTable.$refs.subTable.toggleRowSelection(
              targetItem,
              true
            )
          }
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    checkSelectionList () {
      return new Promise((resolve) => {
        if (!this.select.length) return resolve()
        this.dataList.forEach((account) => {
          const targetItem = this.select.find(
            (item) => item.accountId === account.accountId
          )
          this.$refs.mainTable.$refs.subTable.toggleRowSelection(
            account,
            !!targetItem
          )
          account.checked = !!targetItem
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    handleSelectionChange (select) {
      this.$nextTick(() => {
        if (this.isRenderAuto) return
        this.dataList.forEach((account) => {
          account.checked = !!select.find(
            (item) => item.accountId === account.accountId
          )
          const targetIndex = this.select.findIndex(
            (item) => item.accountId === account.accountId
          )
          if (account.checked) {
            if (targetIndex < 0) this.select = [...this.select, account]
          } else {
            if (targetIndex > -1) {
              this.select.splice(targetIndex, 1)
            }
          }
        })
      })
    },
    handleClose () {
      // this.$emit("close");

    },
    handleOfficialChange () {
      this.initData()
    },
    handleBookChange () {
      this.page = 1
      this.initData()
    },
    pageChange (val) {
      this.page = val
      this.initData()
    },
    getBookName () {
      getBookList({
        page: 1,
        pageSize: 50
      }).then((res) => {
        this.bookList = res.list
      })
    },
    getBook (bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName
      }).then((res) => {
        this.bookList = res.list
      })
    },
    getTagType (row) {
      if (row.status === 0) {
        return {
          type: 'danger',
          text: '未绑定'
        }
      }
      if (row.status === 1) {
        return {
          type: '',
          text: '绑定'
        }
      }
      if (row.status === 2) {
        return {
          type: 'warning',
          text: '解绑'
        }
      }
    },
    getTagStyle ({ status }) {
      if (status === 1) {
        return {
          cursor: 'pointer'
        }
      }
    },
    handleConfirm () {
      if (!this.select.length) {
        return this.$message.warning('请选择账号！')
      }
      this.$emit('confirm', this.select)
      this.handleCancel()
    },
    handleCancel () {
      this.$emit('close')
    },
    batchEditRemark (cb) {
      const ids = this.select.map((item) => item.id).join(',')
      this.$prompt('添加备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          batchEditADQRemark(ids, { remark: value }).then(() => {
            this.$message.success('操作成功!')
            this.page = 1
            this.initData()
          })
        }).catch(() => {}).finally(() => { cb() })
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.container {
  .button-list {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
}
</style>
