<template>
  <el-dialog
    title="素材中心"
    append-to-body
    width="1000px"
    top='20px'
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="brand-image-list">
      <div class="filter-operation">
        <c-input
          v-model="search"
          placeholder="请输入名称!"
          style="width: 300px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </c-input>
      </div>
      <div class="flex-row-start-start-wrap brand-container" v-if='!loading'>
        <div
          class="brand-item"
          v-for="(item, index) in getImageList()"
          :key="index"
          :class="{'is-active':curIndex===index}"
          @click="handleBrandClick(item,index)"
        >
          <div class="brand-item-container">
            <img :src="item.url" alt="" />
            <p class='info-box flex-row-between-center' >
              <span>{{item.brandName | getUrlName}}</span>
              <span>{{item.createdTime | formatTime}}</span>
            </p>
          </div>
          <i class='el-icon-success choose-item' v-if='curIndex===index'></i>
        </div>
      </div>
      <div v-else class='loading-tips flex-center-center'>
         <div>
           <i class='el-icon-loading' style='font-size:30px;margin-bottom:10px'></i>
           <p>数据加载中...</p>
         </div>
      </div>
    </div>
    <div slot='footer' class='button-list'>
      <el-button type='primary' @click='handleOk'> 确定</el-button>
      <el-button @click='handleCancel'>取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAdqBrandList } from '@/api/tencent'
import { formatTime } from '@/assets/js/utils'
const MAX_IMAGE_SIZE = 400
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      imageList: [],
      curIndex: null,
      curInfo: null,
      loading: false
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      }
    }
  },
  filters: {
    getUrlName (value) {
      return value.split(/\./g)[0]
    },
    formatTime (value) {
      return formatTime(value, 'yyyy-MM-dd')
    }
  },
  methods: {
    initData () {
      this.loading = true
      getAdqBrandList({
        page: 1,
        pageSize: 9999,
        search: this.search
      }).then((res) => {
        this.total = res.length
        this.imageList = res
      }).finally(() => {
        this.loading = false
      })
    },
    handleClose () {
      this.$emit('close')
    },
    getImageList () {
      return this.search
        ? this.imageList.filter(({ brandName, size }) =>
          brandName.includes(this.search) && parseFloat(size) < MAX_IMAGE_SIZE
        )
        : this.imageList.filter(({ size }) => parseFloat(size) < MAX_IMAGE_SIZE)
    },
    handleBrandClick (val, index) {
      this.curIndex = index
      this.curInfo = val
    },
    handleCancel () {
      this.handleClose()
    },
    handleOk () {
      if (!this.curInfo) return this.$message.warning('请选择品牌形象!')
      this.$emit('close')
      this.$emit('confirm', this.curInfo)
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.brand-image-list {
  overflow: auto;
  height:600px;
  position: relative;
}
.brand-container{
  height:500px;
  padding:20px 0 0 5px;
  overflow: auto;
}
.brand-item {
  border: 1px solid $borderColor;
  border-radius: 8px;
  margin: 0 10px 10px 0;
  position: relative;
  &-container {
    width: 222px;
    cursor: pointer;
    box-shadow:0px 6px 15px rgba(0,0,0,0.05);
    border-radius: inherit;
    img {
      width: 100%;
      height: 153px;
      object-fit: contain;
      background: #f8f9fa;
      border-radius:inherit;
    }
    .info-box{
      font-size:12px;
      >span{
        padding:6px 10px;
      }
    }
  }
  .choose-item{
    position: absolute;
    top:2%;
    left:92%;
    color:$main;
  }
  &:hover {
    box-shadow: 0 0 0 2px #ccc;
  }
}
  .is-active{
    box-shadow: 0 0 0 2px $main !important;
  }
  .loading-tips{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
</style>
