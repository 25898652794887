<template>
<el-dialog title="选择计划模板" width='1000px'
 append-to-body :before-close="handleClose"
 :visible.sync='show'
 top='20px'
>
 <dy-table
  height='650px'
  :page='page'
  :pageSize='pageSize'
  :dataList='dataList'
  :rowList='rowList'
  :showPagination="true"
  :loading='loading'
  :total='total'
  @pageChange="pageChange"
  @selection-change="handleSelectionChange"
 >
  <template #filter>
   <el-button class='margin-right-ten' @click='initData'>刷新数据</el-button>
   <c-input class='margin-right-ten' v-model='templateName' style='width:350px;vertical-align: middle' >
       <el-button slot='append' icon='el-icon-search' @click='pageChange(1)'></el-button>
   </c-input>
  </template>
  <template #createdTime={row}>
     <span>{{row.createdTime|customTime}}</span>
  </template>
  <template #operation="{row}">
      <c-button type='primary' class='button-small' @click='(cb)=>{handleSelect(row,cb)}'>选择</c-button>
      <el-button type='danger' class='button-small' @click='handleDelete(row.id)'>删除</el-button>
  </template>
 </dy-table>
</el-dialog>
</template>

<script>
import { getAdqTemplateList, delTemplateData, getTemplateDetail } from '@/api/tencent'
import { formatTime } from '@/assets/js/utils'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dataList: [],
      selection: [],
      rowList: [{
        label: 'ID',
        prop: 'id'
      }, {
        label: '模板名称',
        prop: 'templateName',
        popover: true
      }, {
        label: '创建时间',
        prop: 'createdTime',
        slot: true
      }, {
        label: '操作',
        action: 'operation',
        slot: true
      }],
      page: 1,
      pageSize: 15,
      templateName: '',
      total: 0

    }
  },
  filters: {
    customTime (value) {
      return formatTime(value)
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods:{
    initData () {
      this.loading = true
      getAdqTemplateList({
        page: this.page,
        pageSize: this.pageSize,
        templateName: this.templateName
      }).then(res => {
        this.total = res.total
        this.dataList = res.list
      }).finally(() => {
        this.loading = false
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    handleSelectionChange (select) {
      this.selection = select
    },
    handleClose () {
      this.$emit('close')
    },
    handleSelect (row, cb) {
      getTemplateDetail({
        templateId: row.id
      }).then(res => {
        this.$emit('chooseSuccess', res)
        this.$emit('close')
      }).finally(() => {
        cb()
      })
    },
    handleDelete (id) {
      this.$confirm('确定要删除吗？删除的数据不可恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTemplateData(id).then(() => {
          this.$message.success('删除成功!')
          this.initData()
          this.$emit('deleteItem', id)
        })
      }).catch(() => {})
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
</style>
