var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"width":"1000px","title":"添加文案","top":"20px","append-to-body":"","visible":_vm.show,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.handleCancel}},[_c('div',{staticClass:"config-resource"},[_c('div',{staticClass:"config-resource-container"},[_c('div',{staticClass:"doc-container"},_vm._l((_vm.curParams.text),function(item,i){return _c('div',{key:item.id,staticClass:"flex-row-start-center"},[_c('div',{staticClass:"doc-item"},[(
                _vm.params.creativityConfig.adcreativeTemplateInfo.value !== 714
              )?_c('div',{staticClass:"flex-row-start-center"},[_c('span',[_vm._v("左下辅助文字（选填）")]),_c('el-autocomplete',{staticStyle:{"width":"600px"},attrs:{"placeholder":"请输入辅助文字","clearable":"","fetch-suggestions":function (str, cb) {
                    _vm.querySearchAsync(str, cb, i, 'bottom_text');
                  }},model:{value:(item.bottom_text),callback:function ($$v) {_vm.$set(item, "bottom_text", $$v)},expression:"item.bottom_text"}}),_c('limit-word',{staticStyle:{"margin-left":"10px"},attrs:{"calculationRules":_vm.calculateCharacter,"curValue":item.bottom_text,"initLength":item.lenArr[0],"isDivideByTwo":true,"maxLength":8},on:{"error":function($event){_vm.showError = true},"change":function($event){_vm.showError = false}}})],1):_vm._e(),_c('div',{staticClass:"flex-row-start-center"},[_c('span',[_vm._v("文案（"+_vm._s(_vm.getMinLength())+"-"+_vm._s(_vm.getMaxLength())+"字）")]),_c('el-autocomplete',{staticStyle:{"width":"600px"},attrs:{"placeholder":"使用行动按钮或卖点图时最多支持10个字","clearable":"","fetch-suggestions":function (str, cb) {
                    _vm.querySearchAsync(str, cb, i, 'description');
                  }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('limit-word',{staticStyle:{"margin-left":"10px"},attrs:{"calculationRules":_vm.calculateCharacter,"curValue":item.description,"initLength":item.lenArr[1],"isDivideByTwo":true,"maxLength":_vm.getMaxLength(),"minLength":_vm.getMinLength()},on:{"error":function($event){_vm.showError = true},"change":function($event){_vm.showError = false}}})],1),(
                _vm.params.creativityConfig.adcreativeTemplateInfo.value !== 714
              )?_c('div',{staticClass:"flex-row-start-center"},[_c('span',[_vm._v("文案（1-14字）")]),_c('el-autocomplete',{staticStyle:{"width":"600px"},attrs:{"clearable":"","placeholder":"使用行动按钮或卖点图时最多支持10个字","fetch-suggestions":function (str, cb) {
                    _vm.querySearchAsync(str, cb, i, 'title');
                  }},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('limit-word',{staticStyle:{"margin-left":"10px"},attrs:{"calculationRules":_vm.calculateCharacter,"curValue":item.title,"initLength":item.lenArr[2],"isDivideByTwo":true,"maxLength":14},on:{"error":function($event){_vm.showError = true},"change":function($event){_vm.showError = false}}})],1):_vm._e()]),(_vm.curParams.text.length > 1)?_c('div',{staticClass:"operation-delete",on:{"click":function($event){return _vm.curParams.text.splice(i, 1)}}},[_c('i',{staticClass:"el-icon-minus"})]):_vm._e()])}),0),(10 - _vm.curParams.text.length !== 0)?_c('div',{staticClass:"tips",on:{"click":_vm.addText}},[_c('i',{staticClass:"el-icon-plus"}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("还可以添加 "+_vm._s(10 - _vm.curParams.text.length)+" 组")])]):_vm._e()])]),_c('div',{staticClass:"button-list",staticStyle:{"text-align":"left"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.clearResource}},[_vm._v("清空数据")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("确定")]),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }