<template>
  <el-dialog
    :title="`文案-素材${copyData.length ? `（${copyData.length}个）` : ''}`"
    :visible.sync="show"
    append-to-body
    @close="handleClose"
    :close-on-click-modal="false"
    width="1000px"
    top="20px"
  >
    <div class="mix-content-list">
      <div
        class="mix-content-item"
        v-for="(item, index) in copyData"
        :key="item.id"
      >
        <resource-video
          v-if="[721, 720, 1529].includes(creativeType)"
          size="vertical"
          ref="resourceVideo"
          :widthAndHigh="getWidthAndHigh()"
          :curInfo="item.resource"
          :showInfo="showInfo"
          :params="params"
          :Index="index"
          v-bind="$attrs"
          @chooseDoc="chooseDoc"
        ></resource-video>
        <mix-picture
          ref="mixPicture"
          v-if="[711, 712, 714].includes(creativeType)"
          :curInfo="item.resource"
          :widthAndHigh="getWidthAndHigh()"
          :showInfo="showInfo"
          :limitSize="getLimitSize()"
          :Index="index"
          :params="params"
          @chooseDoc="chooseDoc"
          v-bind="$attrs"
        ></mix-picture>
        <three-pic
          v-if="[718].includes(creativeType)"
          ref="threePic"
          :curInfo="item.resource"
          :widthAndHigh="getWidthAndHigh()"
          :showInfo="showInfo"
          :Index="index"
          :params="params"
          @chooseDoc="chooseDoc"
          v-bind="$attrs"
        ></three-pic>
        <doc
          v-bind="$attrs"
          ref="docContent"
          style="margin-top:20px"
          :curInfo="item.doc"
          :showInfo="showInfo"
          :curDocIndex="curDocIndex"
          :Index="index"
          :params="params"
          :portalDescription="portalDescription"
        ></doc>
        <i class="el-icon-delete-solid del-icon" @click="delData(index)" v-if="!isSingle"></i>
      </div>
    </div>
    <div v-if="!isSingle" class="add-content" @click="addContent">
      <i class="el-icon-plus"></i>
      继续添加
    </div>
    <div slot="footer">
      <el-button @click="handleCancel">取消</el-button>
      <el-button @click="handleOk" type="primary">确定</el-button>
    </div>
    <el-dialog
      :title="`该素材有${alternativeDocList.length}个关联文案，请选择其中一个文案！`"
      append-to-body
      width="800px"
      :close-on-click-modal="false"
      @close="showDocDialog = false"
      :visible.sync="showDocDialog"
    >
      <div class="doc-content">
        <div class="part" v-for="(doc,index) in alternativeDocList" :key="index">
          <el-radio v-model="radio" :label="index">{{ doc.content }}</el-radio>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="(showDocDialog = false, alternativeDocList = [])">取消</el-button>
        <el-button @click="handleChooseDocItem" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
// todo=> 通过Index回显document内容
import resourceVideo from './resource-video.vue'
import mixPicture from './mix-picture.vue'
import threePic from './three-pic.vue'
import Doc from './doc.vue'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { isValidObject, isValidArray } from '@/assets/js/utils'

export default {
  name: 'MixContent',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    isSingle: {
      type: Boolean,
      default: false
    },
    mixContentList: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      creativeType: null,
      copyData: [],
      curIndex: null,
      duplicateData: [],
      portalDescription: '', // ! 从素材当中引入的description
      targetId: '',
      showDocDialog: false,
      alternativeDocList: [],
      radio: 0,
      curDocIndex: 0
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () { }
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.renderData()
        } else {
          this.resetData()
        }
      },
      immediate: true

    }
  },
  methods: {
    renderData () {
      if (!this.params.creativityConfig) return
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      this.creativeType = adcreativeTemplateInfo.value
      const data = _.cloneDeep(this.mixContentList)
      for (const item of data) {
        item.resource && delete item.resource.doc
      }
      this.copyData = _.cloneDeep(data)

      this.duplicateData = _.cloneDeep(this.copyData)
    },
    chooseDoc ({ doc, Index }) {
      this.curDocIndex = Index
      if (isValidArray(doc)) {
        this.targetId = this.copyData[this.curDocIndex].id
        if (doc.length > 1) {
          this.alternativeDocList = doc
          setTimeout(() => {
            const flag = this.handleShowDocDialog()
            if (flag) {
              this.showDocDialog = true
            }
          }, 400)
        } else {
          this.portalDescription = doc[0].content
        }
      }
    },
    handleShowDocDialog () {
      // ! 如果不是横版三小图，则直接返回true
      if (this.creativeType !== 718) {
        return true
      }

      const { docContent } = this.$refs
      const targetValue = docContent[this.curDocIndex]

      if (isValidObject(targetValue)) {
        const { $data: { curParams: { text } } } = targetValue
        if (isValidArray(text)) {
          // ! 如果description 有值，则直接返回
          if (text[0].description) {
            return false
          }
        }
      }
      return true
    },
    handleCancel () {
      this.handleClose()
    },
    handleOk () {
      // ! 首先组装数据
      this.packageData().then(() => {
        if ([721, 720, 1529].includes(this.creativeType)) {
          if (this.duplicateData.some(item => !item.resource || !item.resource.image.src || !isValidObject(item.resource.video))) {
            return this.$message.error('请完善素材!')
          }
        }
        if ([711, 712].includes(this.creativeType)) {
          if (this.duplicateData.some(item => !item.resource || !item.resource.mediaInfo.src)) {
            return this.$message.error('请完善素材!')
          }
        }
        if (this.creativeType === 718) {
          if (this.duplicateData.some(({ resource }) => resource.image.some(item => !item.src))) {
            return this.$message.error('请完善素材!')
          }
        }

        if (this.params && this.params.creativityConfig && this.params.creativityConfig.adcreativeTemplateInfo.value !== 714) {
          if (this.duplicateData.some(({ doc }) => !doc || !doc.bottom_text || !doc.description || !doc.title)) {
            return this.$message.error('请完善文案!')
          }
        } else if (this.params && this.params.creativityConfig && this.params.creativityConfig.adcreativeTemplateInfo.value === 714) {
          if (this.duplicateData.some(({ doc }) => !doc || !doc.description)) {
            return this.$message.error('请完善文案!')
          }
        }
        this.$emit('confirm', this.duplicateData)
        this.$emit('close')
      })
    },
    packageData () {
      return new Promise(resolve => {
        const data = []
        const { resourceVideo, mixPicture, threePic, docContent } = this.$refs
        docContent.forEach(({ $data }, index) => {
          (data[index] || (data[index] = {})).doc = $data.curParams.text[0]
        })
        if (this.creativeType === 718) {
          threePic.forEach(({ $data }, index) => {
            (data[index] || (data[index] = {})).resource = $data.resourceList[0]
          })
        } else {
          // ! 哪个字段存在，resource就赋值为那个字段
          const targetValue = mixPicture || resourceVideo
          targetValue.forEach(({ $data }, index) => {
            (data[index] || (data[index] = {})).resource = $data.resourceInfo
          })
        }
        this.duplicateData = data
        resolve(true)
      })
    },
    delData (index) {
      this.copyData.splice(index, 1)
      this.duplicateData.splice(index, 1)
    },
    addContent () {
      const [f] = this.copyData
      let value = null
      if (f) {
        const { docContent } = this.$refs
        if (isValidArray(docContent)) {
          const [cur] = docContent
          const { $data: { curParams: { text } } } = cur
          if (isValidArray(text)) {
            const [first] = text
            value = {
              bottom_text: first.bottom_text || '',
              title: first.title || '',
              description: '',
              loading: false,
              lenArr: [first.lenArr[0] || 0, 0, first.lenArr[2] || 0]
            }
          }
        }
      }

      this.copyData = [...this.copyData, { id: nanoid(), ...(value && { doc: value }) }]
      this.duplicateData = [...this.duplicateData, { id: nanoid(), ...(value && { doc: value }) }]
    },
    getWidthAndHigh () {
      if (this.creativeType === 721) {
        return { width: 720, height: 1280 }
      }
      if (this.creativeType === 720) {
        return { width: 1280, height: 720 }
      }
      if (this.creativeType === 711) {
        return { width: 1280, height: 720 }
      }
      if (this.creativeType === 712) {
        return { width: 720, height: 1280 }
      }
      if (this.creativeType === 714) {
        return { width: 480, height: 320 }
      }
      if (this.creativeType === 1529) {
        return { width: 1080, height: 1920 }
      }
    },
    getLimitSize () {
      if (this.creativeType === 714) {
        return 100
      } else {
        return 140
      }
    },
    handleChooseDocItem () {
      this.portalDescription = this.alternativeDocList[this.radio].content
      this.showDocDialog = false
      this.radio = 0
    },
    handleClose () {
      this.$emit('close')
    },
    resetData () {
      this.curDocIndex = 0
      this.radio = 0
      this.alternativeDocList = []
      this.portalDescription = ''
    }
  },
  components: { resourceVideo, Doc, mixPicture, threePic }
}
</script>
<style lang='scss' scoped>
.mix-content-list {
  max-height: 600px;
  overflow: auto;
  padding: 20px 10px;
  .mix-content-item {
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 40px;
    position: relative;
    transition: all 0.3s;
    .del-icon {
      color: $colorRed;
      border-radius: 50%;
      background: #fff;
      overflow: hidden;
      position: absolute;
      top: -1%;
      left: 98%;
      font-size: 20px;
      display: none;
      cursor: pointer;
    }
    &:hover {
      .del-icon {
        display: inline-block;
      }
    }
  }
}
.add-content {
  margin-top: 20px;
  border: 1px dashed #ccc;
  text-align: center;
  line-height: 50px;
  border-radius: 6px;
  font-weight: bold;
  &:hover {
    border-color: $main;
    color: $main;
    cursor: pointer;
  }
}
.doc-content {
  .part {
    margin: 0 10px 20px;
  }
}
</style>
