import { render, staticRenderFns } from "./recognition-region.vue?vue&type=template&id=a0c15da0&scoped=true&"
import script from "./recognition-region.vue?vue&type=script&lang=js&"
export * from "./recognition-region.vue?vue&type=script&lang=js&"
import style0 from "./recognition-region.vue?vue&type=style&index=0&id=a0c15da0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0c15da0",
  null
  
)

export default component.exports