<template>
  <el-dialog
    append-to-body
    width="600px"
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div slot="title">批量操作结果</div>
    <div class="modal-form">
      <div class="modal-content">
        <!-- <div class="warn">
          <i class="el-icon-warning"></i>
          <span class="warn-tips"
            >系统识别朝阳区、南山区有重名，请按需求调整并完成导入</span
          >
        </div> -->
        <div class="title-bar">
          <span
            >成功识别
            <span style="color: #00bf8a; font-weight: bold">{{
              succInfo.length
            }}</span>
            个，失败
            <span style="color: #fd5d5a; font-weight: bold">{{
              failedRecognitionList.length
            }}</span>
            个</span
          >
          <div>
            <el-button
              @click="clearDataList"
              type="danger"
              :disabled="succInfo.length === 0"
              >清空</el-button
            >
            <el-button
              type="warning"
              @click="restoreData"
              :disabled="restoreDataList.length === 0"
              :title="`
            --删除了${restoreDataList.length}个地域--支持快捷键单个数据还原(Ctrl+Z)
          `"
              >还原删除数据</el-button
            >
            <el-button type="primary" @click="repeatUpload">重新上传</el-button>
          </div>
          <input
            type="file"
            ref="uploadFile"
            style="display: none"
            @change="handleFileChange"
          />
        </div>
        <div class="form-row" v-if="!loading">
          <p
            v-for="(item, index) in failedRecognitionList"
            :key="'failed_' + index"
            class="form-row-item is-failed"
            style="font-weight: bold"
          >
            {{ item[0] }}
          </p>
          <p
            v-for="(item, index) in succInfo"
            :key="'success' + index"
            class="form-row-item"
          >
            <span style="color: #313233">{{ getTargetName(item) }}</span>
            <span
              @click="handleDelete(item, index)"
              style="color: #939599; cursor: pointer"
              >删除</span
            >
          </p>
        </div>
        <div v-else class="tips flex-center-center">
          <p>
            <i class="el-icon-loading"></i>
          </p>
        </div>
      </div>
    </div>
    <div slot="footer" class="button-list">
      <el-button :loading="okLoading" type="primary" @click="handleOk"
        >导入{{ succInfo.length }}个区域</el-button
      >
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { excludeChinaUnKnow as regionList } from '@/mockData/region'
import { getTXTFileInfo } from '@/api/tencent'
import _ from 'lodash'
const CHINA_ID = 1156
const CHINA_TEXT = '中国'
const CHINA_UNKNOWNS_ID = 156
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      total: 0,
      succInfo: [],
      loading: false,
      okLoading: false,
      regBracket: /[\(\（]([\d\D]+)[\)\）]/g, // 括号匹配,
      regLine: /-/g, // 短横线匹配
      selectList: [],
      isSetAll: false, // 是否全选(包含CHINA_ID)
      restoreDataList: [], // 删除之后存储可能需要还原操作的数据
      succRecognitionList: [],
      failedRecognitionList: []
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV === true) {
          this.loading = true
          this.initEvent()
        } else {
          this.resetData()
        }
      }
    },
    list: {
      handler (newV) {
        if (newV.length > 0) {
          this.renderData(newV)
        }
      }
    }
  },
  methods: {
    initEvent () {
      window.addEventListener('keydown', this.handleKeyDown, false)
    },
    handleKeyDown (e) {
      // !监听Ctrl+z
      if (e.ctrlKey && e.keyCode === 90) {
        if (this.restoreDataList.length === 0) return
        const { index, value } = this.restoreDataList.pop()
        this.succInfo.splice(index, 0, value)
      }
    },
    renderData (newV) {
      this.loading = false
      this.selectList = _.cloneDeep(newV)
      this.handlerSelectList()
    },
    handlerSelectList () {
      let lineList = []
      let normalList = []
      let bracketList = []
      if (this.selectList.includes(CHINA_TEXT)) {
        this.isSetAll = true
        this.mergeData([[CHINA_TEXT]])
      } else {
        this.isSetAll = false
        this.selectList.forEach((region) => {
          //   括号
          if (/[\(\（]([\d\D]+)[\)\）]/g.test(region)) {
            bracketList = [
              ...bracketList,
              region
                .split(/[\(\（]([\d\D]+)[\)\）]/g)
                .filter((item) => !!item)
                .reverse()
            ]
            //   短横线
          } else if (/-/g.test(region)) {
            lineList = [
              ...lineList,
              region.split(/-/g).filter((item) => !!item)
            ]
            //   常规
          } else {
            normalList = [...normalList, [region]]
          }
        })
        this.mergeData([...bracketList, ...lineList, ...normalList])
      }
    },
    // todo合并数据
    mergeData (select) {
      let succList = []
      let succNameList = [] // !存储 识别成功的字段，方便后续删除
      for (let i = 0; i < select.length; i++) {
        const value = select[i]
        const regionInfo = this.recognitionRegion(value)
        if (regionInfo.length === 0) {
          this.failedRecognitionList = [...this.failedRecognitionList, value]
        } else {
          succList = [...succList, regionInfo]
          succNameList = [...succNameList, select[i]]
        }
      }
      succList = succList.map((item, index) => ({
        list: item,
        targetName: succNameList[index]
      }))
      this.succInfo = _.cloneDeep(succList)
    },
    recognitionRegion (value) {
      if (value[0] === CHINA_TEXT) {
        return this.handleAllRegion(value)
      }
      const allRegionList = regionList
      let resArr = []
      // *迭代函数
      const iterationFindItem = (item, regionList, fatherName = null) => {
        for (let m = 0; m < regionList.length; m++) {
          const region = regionList[m]
          //  !通过地域名称进行比对
          if (item === region.name) {
            const { id } = region
            // 找到id前缀
            const preFix = `${id}`.slice(0, 2)
            const idsArr = this.mergeFatherIds(
              preFix,
              region,
              allRegionList[0].children,
              []
            )
            if (region.children) {
              const customArr = []
              for (let n = 0; n < region.children.length; n++) {
                const stack = []
                const myRes = []
                this.getLowerLevelInfo(
                  region.children[n],
                  stack,
                  myRes,
                  customArr
                )
              }
              const prefix = `${customArr[0].id}`.slice(0, 2)
              for (let n = 0; n < customArr.length; n++) {
                const resIds = this.mergeFatherIds(
                  prefix,
                  customArr[n],
                  allRegionList[0].children,
                  []
                )
                resArr.push(resIds)
              }
            } else {
              resArr.push(idsArr)
              // !去掉重复的地区名字
              if (fatherName !== null) {
                resArr = resArr.filter((item) =>
                  item.find((val) => val.name === fatherName)
                )
              }
            }
          } else {
            if (region.children) {
              iterationFindItem(item, region.children, fatherName)
            }
          }
        }
      }
      // !针对文本文字没有短横线或者括号的情况（最常规的情况）
      if (value.length === 1) {
        iterationFindItem(value[0], regionList, null)
      }
      // ! 针对有短横线(北京市-朝阳区)或者括号(朝阳区（北京市）)的情况
      if (value.length === 2) {
        iterationFindItem(value[1], regionList, value[0])
      }
      return resArr
    },
    getLowerLevelInfo (region, stack, myRes, customArr) {
      // !拿到最低层地域的info
      if (!region.children) {
        myRes.push({ id: region.id, name: region.name })
        const res = JSON.parse(JSON.stringify(myRes))
        customArr = customArr.push(res[res.length - 1])
        myRes = []
        stack = [...stack.slice(0, 1)]
      } else {
        for (let j = 0; j < region.children.length; j++) {
          this.getLowerLevelInfo(region.children[j], stack, myRes, customArr)
        }
      }
    },
    mergeFatherIds (preFix, region, regionList, idsArr) {
      // !比较id前缀是否相同
      const provinceArr = regionList.filter((item) => {
        if (item.id !== CHINA_UNKNOWNS_ID) {
          return `${item.id}`.slice(0, `${preFix}`.length).includes(preFix)
        } else {
          return (
            `${item.id}`.slice(0, `${preFix}`.length).includes(preFix) &&
            item.id === CHINA_UNKNOWNS_ID
          )
        }
      })
      // ! 如果长度大于1，适用于北京、天津等地通过比较前缀找不到对应信息的bug
      if (provinceArr.length > 1) {
        const target = provinceArr.find((item) => item.name === region.name)
        if (target) {
          idsArr = [
            ...idsArr,
            {
              id: target.id,
              name: target.name
            }
          ]
        }
        return idsArr
      }
      const province = provinceArr[0]
      if (province) {
        idsArr = [
          ...idsArr,
          {
            id: province.id,
            name: province.name
          }
        ]
        if (province.name === region.name) {
          return idsArr
        }
        if (province.children) {
          // !每次递归将前缀的长度增加两位
          return [
            ...idsArr,
            ...this.mergeFatherIds(
              `${region.id}`.slice(0, preFix.length + 2),
              region,
              province.children,
              []
            )
          ]
        } else {
          return []
        }
      } else {
        return []
      }
    },
    deEmphasisIds () {
      const copyData = _.cloneDeep(this.succRecognitionList)
      let res = []
      copyData.forEach((item) => {
        const lastId = item[item.length - 1]
        // ! 只比较id的最后一项，因为最后一项肯定是唯一的，如果找到重复项，则表示需要去重
        if (!res.find((copyItem) => copyItem[copyItem.length - 1] === lastId)) {
          res = [...res, item]
        }
      })
      this.succRecognitionList = res
    },
    clearDataList () {
      let copyData = _.cloneDeep(this.succInfo)

      copyData.forEach((item, index) => {
        this.restoreDataList = [
          ...this.restoreDataList,
          { value: item, index }
        ]
      })
      this.succInfo = []
      copyData = null
    },
    handleAllRegion (value) {
      const region = regionList[0]
      const resArr = []
      for (let n = 0; n < region.children.length; n++) {
        const customArr = []
        const stack = []
        const myRes = []
        this.getLowerLevelInfo(region.children[n], stack, myRes, customArr)
        const prefix = `${customArr[0].id}`.slice(0, 2)
        for (let n = 0; n < customArr.length; n++) {
          const resIds = this.mergeFatherIds(
            prefix,
            customArr[n],
            regionList[0].children,
            []
          )
          resArr.push(resIds)
        }
      }
      return resArr
    },
    addFailedClass (val) {
      return this.failedRecognitionList.find((item) =>
        val.includes(item[item.length - 1])
      )
    },
    // todo=>删除待续
    handleDelete (val, index) {
      this.restoreDataList.push({
        value: val,
        index
      })
      this.succInfo.splice(index, 1)
    },
    getTargetName (value) {
      return value.targetName.join('-')
    },
    handleOk () {
      if (this.succInfo.length === 0) { return this.$message.warning('当前没有成功识别的地域，请重新上传!') }
      let resIds = []
      const succList = _.cloneDeep(this.succInfo)
      succList.forEach(({ list }) => {
        let ids = []
        list.forEach((val) => {
          ids = [CHINA_ID, ...val.map((k) => k.id)]
          resIds = [...resIds, ids]
        })
      })
      this.succRecognitionList = resIds
      // ! 去重 （广东省、南山区）=>广东省里面已经包含了南山区
      if (!this.isSetAll) this.deEmphasisIds()
      this.$message.info('解析时间可能较长,请耐心等待!')
      setTimeout(() => {
        this.$emit(
          'handleSuccess',
          // ! 如果包含了“CHINA_ID”则需要加上[1156,156]=>全选
          !this.isSetAll
            ? [...this.succRecognitionList]
            : [...this.succRecognitionList, [CHINA_ID, CHINA_UNKNOWNS_ID]]
        )
        this.handleClose()
      }, 100)
    },
    handleCancel () {
      this.$emit('close')
    },
    repeatUpload () {
      this.$refs.uploadFile.click()
    },
    restoreData () {
      const copyData = _.cloneDeep(this.succInfo)
      this.restoreDataList.forEach(({ index, value }) => {
        copyData.splice(index, 0, value)
      })
      this.succInfo = _.cloneDeep(copyData)
      this.restoreDataList = []
    },
    handleClose () {
      this.$emit('close')
    },
    resetData () {
      this.succRecognitionList = []
      this.failedRecognitionList = []
      this.selectList = []
      this.isSetAll = false
      this.restoreDataList = []
      window.removeEventListener('keydown', this.handleKeyDown, false)
    },
    handleFileChange (e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      this.loading = true
      getTXTFileInfo(formData)
        .then((res) => {
          if (res.length > 0) {
            this.failedRecognitionList = []
            this.renderData(res)
          }
        })
        .finally(() => {
          this.loading = false
          this.$refs.uploadFile.value = null
        })
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__header {
  padding: 20px;
  font-weight: bold;
  border-bottom: 1px solid rgb(237, 239, 242);
}
/deep/ .el-dialog__body {
  padding-top: 10px;
}
.is-failed {
  color: $colorRed !important;
}
.modal-form {
  .modal-content {
    .warn {
    }
    .title-bar {
      padding: 16px 0;
      @include flex(space-between, center);
    }
    .form-row {
      border: 1px solid $borderColor;
      border-radius: 8px;
      padding: 20px;
      height: 326px;
      overflow: auto;
      .form-row-item {
        @include flex(space-between, center);
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .tips {
      height: 326px;
      p {
        text-align: center;
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
