<template>
    <div class="vertical-video">
        <div class="vertical-video-container">
            <div class="control-twins-item is-group" ref="controlTwins">
                <div class="control-twins-item-container">
                    <div v-for="(item, index) in resourceList" class="creative-item" :key="item.id">
                        <div class="flex-row-start-start">
                            <div v-for="(img, index2) in item.image" :key="index2">
                                <div class="list-item flex-center-center">
                                    <input
                                        :multiple="isCanMultiple"
                                        accept="image/*"
                                        :ref="`${item.id}`"
                                        type="file"
                                        @change="
                                            (e) => {
                                                handleFileChange(e, item.id, index, index2);
                                            }
                                        "
                                        style="display: none"
                                    />
                                    <div class="item-image" v-if="img.src && !img.loading">
                                        <img :src="img.src" alt />
                                        <div
                                            class="reset-upload-hover flex-center-center"
                                            @click="
                                                () => (
                                                    (isCanMultiple = false), handleUpload(item.id, index2)
                                                )
                                            "
                                        >
                                            <span
                                                style="font-size: 14px; color: #fff; font-weight: bold"
                                            >点击重新上传</span>
                                        </div>
                                        <i
                                            class="el-icon-error"
                                            v-if="img.src"
                                            @click="delImg(index,index2)"
                                        ></i>
                                    </div>
                                    <div
                                        class="tips"
                                        @click="handleUpload(item.id, index2)"
                                        v-else-if="!img.src && !img.loading"
                                    >
                                        <p>点击上传</p>
                                        <p>480 * 320px</p>
                                        <p>小于100kb</p>
                                    </div>
                                    <div class="loading-tips flex-center-center" v-if="img.loading">
                                        <p>
                                            <i class="el-icon-loading"></i>
                                            <span style="margin-left: 5px">上传中</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="operation-delete"
                                @click="resourceList.splice(index, 1)"
                                v-if="resourceList.length > 1"
                            >
                                <i class="el-icon-minus"></i>
                            </div>
                        </div>
                        <div
                            class="library-choose"
                            v-if="Array.isArray(resourceList) && resourceList.length > 0"
                        >
                            <p @click="chooseLibrary(index)">
                                <span>
                                    <i class="el-icon-picture" style="margin-right:4px"></i>从素材库中心选择
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="素材"
            width="1400px"
            v-dialogDrag
            top="20px"
            append-to-body
            :visible.sync="showMediaDialog"
            :before-close="handleCancel"
        >
            <media-library
                :show="showMediaDialog"
                :maxNum="maxImageNum"
                mediaType="image"
                ref="mediaLibrary"
            >
                <div slot="footer" style="margin-top: 10px">
                    <el-button
                        type="primary"
                        @click="
                            () => {
                                handleOk();
                            }
                        "
                    >确定</el-button>
                    <el-button @click="handleCancel">取消</el-button>
                </div>
            </media-library>
        </el-dialog>
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { imageStatusCheck, isValidArray } from '@/assets/js/utils'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import allSettled from 'promise.allsettled'
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'

const IMAGE_SIZE_LIMIT = 100 // 100kb

const LIMIT_NUMBER = 20
export default {
  props: {
    size: {
      type: String,
      default: 'vertical'
    },
    curInfo: {
      default: null
    },
    Index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      LIMIT_NUMBER,
      showMediaDialog: false,
      curIndex: null,
      maxImageNum: 0,
      isCanMultiple: true,
      resourceList: [
        {
          id: nanoid(),
          image: [{ src: '', loading: false }, { src: '', loading: false }, { src: '', loading: false }]
        }
      ]
    }
  },
  watch: {
    curInfo: {
      handler (newV) {
        if (newV) {
          this.resourceList = [JSON.parse(JSON.stringify(newV))]
        }
      },
      immediate: true
    }
  },
  methods: {
    handleCancel () {
      this.showMediaDialog = false
    },
    async handleFileChange (e, id, index, index2) {
      const isMultipleFiles = [...e.target.files].length
      const fileList = [...e.target.files].slice(0, this.isCanMultiple ? 3 : 1)
      if (this.isCanMultiple && isMultipleFiles > 1) {
        return this.batchUpload(fileList, id, index, index2)
      }
      // !重置 isCanMultiple
      this.isCanMultiple = true
      const targetFile = fileList[0]
      try {
        const { flag, message, file: compressFile } = await imageStatusCheck(targetFile, IMAGE_SIZE_LIMIT, { width: 480, height: 320 })
        if (!flag) {
          this.$message.error(`${message}`)
          this.$refs[id][index2].value = ''
          return
        }
        this.resourceList[index].image[index2].loading = true
        const url = await uploadFile({ file: compressFile })
        const type = compressFile.type.split(/\//g)
        this.$set(this.resourceList[index].image, index2, {
          src: url,
          loading: false,
          name: compressFile.name.replace(/\.\w+/g, ''),
          url,
          widthAndHigh: '480 * 320',
          size: `${Math.floor(compressFile.size / 1024)}KB`,
          title: compressFile.name.replace(/\.\w+/g, ''),
          materialId: null,
          format: type[type.length - 1]
        })
        this.uploadNovelPut()
      } catch (e) {
        this.$message.error('上传失败,请稍后再试!')
        this.resourceList[index].image[index2].loading = false
      } finally {
        this.$refs[id][index2].value = null
      }
    },
    async batchUpload (fileList, id, index, index2) {
      const res = await Promise.all(
        [...fileList].map((file) =>
          imageStatusCheck(file, IMAGE_SIZE_LIMIT, { width: 480, height: 320 })
        )
      )
      let targetList = []
      res.forEach(({ flag, file: compressFile }, i) => {
        if (flag) {
          targetList = [...targetList, compressFile]
        }
      })
      if (targetList.length === 0) {
        this.$message.error('所选图片均不符合要求，请重新上传!')
        this.$refs[id][index2].value = ''
        return
      }
      const imageList = JSON.parse(
        JSON.stringify(this.resourceList[index].image)
      )
      targetList.forEach((_, i) => {
        imageList[i].loading = true
      })
      this.resourceList[index].image = imageList
      allSettled(targetList.map((file) => uploadFile({ file })))
        .then((res) => {
          if (res.length > 0) {
            // ! 将成功的返回的loading去掉
            res.forEach((val, j) => {
              const type = targetList[j].type.split(/\//g)
              this.resourceList[index].image[j] = {
                src: val.value || '',
                loading: false,
                name: targetList[j].name.replace(/\.\w+/g, ''),
                url: val.value || '',
                size: `${Math.floor(targetList[j].size / 1024)}KB`,
                title: targetList[j].name.replace(/\.\w+/g, ''),
                widthAndHigh: '480 * 320',
                materialId: null,
                format: type[type.length - 1]
              }
            })
            this.uploadNovelPut()
          }
        })
        .finally(() => {
          // ! 将剩余的item的loading置为false
          const imageList = JSON.parse(JSON.stringify(this.resourceList[index].image))
          imageList.forEach(item => { item.loading = false })
          this.resourceList[index].image = imageList
          this.$refs[id][index2].value = ''
        })
    },
    handleOk () {
      const { imageList } = this.$refs.mediaLibrary
      if (!imageList.every(this.checkImage)) {
        return this.$message.error('所选图片有不符合大小或者尺寸的内容!')
      }
      let targetValue = JSON.parse(JSON.stringify(this.resourceList[this.curIndex].image))
      let targetIndex = 0
      targetValue = targetValue.map((item, index) => {
        const value = imageList[targetIndex]
        if (!item.src && value) {
          item = {
            src: value.src,
            loading: false,
            name: value.resources[0].title.replace(/\.\w+/g, ''),
            materialId: value.id,
            url: value.src,
            title: value.resources[0].title.replace(/\.\w+/g, ''),
            widthAndHigh: value.resources[0].widthAndHigh,
            size: value.resources[0].size,
            format: value.resources[0].format
          }
          targetIndex++
        }
        return item
      })
      this.$set(this.resourceList, this.curIndex, {
        ...this.resourceList[this.curIndex],
        image: targetValue
      })

      this.showMediaDialog = false

      this.boundDocument(imageList)
    },
    boundDocument (imageList) {
      if (isValidArray(imageList)) {
        const docArr = []
        imageList.forEach(item => {
          const { doc } = item
          if (isValidArray(doc)) {
            docArr.push(...doc)
          }
        })
        this.$emit('chooseDoc', { doc: docArr, Index: this.Index })
      }
    },
    checkImage (file) {
      const { resources } = file
      const imageSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+(imageSize) > IMAGE_SIZE_LIMIT) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map(item => +item)
      const [width, height] = imageWH
      if (width === 480 && height === 320) return true
      return false
    },
    handleUpload (id, index) {
      this.$refs[id][index].click()
    },
    chooseLibrary (index) {
      this.curIndex = index
      this.maxImageNum = this.resourceList[this.curIndex].image.filter(item => !item.src).length
      if (this.maxImageNum === 0) return this.$message.warning('只能选择三张图片！')
      this.showMediaDialog = true
    },
    async uploadNovelPut () {
      const needUploadList = JSON.parse(JSON.stringify(this.resourceList)).map(item => {
        const arr = []
        item.image.forEach((img, index) => {
          if (!img.materialId && img.src) {
            arr.push({
              ...img
            })
          }
        })
        return arr
      })
      if (needUploadList.every(item => item.length === 0)) {
        this.$emit('confirm', this.resourceList)
        return true
      }
      const uploadImgList = needUploadList.filter(item => item.length > 0).reduce((acc, b, c) => {
        const imgList = b.map(item => ({
          materialType: 2,
          title: item.title,
          materialResources: {
            title: item.title,
            widthAndHigh: item.widthAndHigh,
            format: item.format,
            size: item.size,
            url: item.url,
            sort: 1
          }
        }))
        return [...acc, ...imgList]
      }, [])
      const res = await addMaterialWare(uploadImgList)
      res.forEach(img => {
        const targetIndex = this.resourceList.findIndex(item => item.image.some(val => val.src === img.resources[0].url))
        const subIndex = this.resourceList[+targetIndex].image.findIndex(val => val.src === img.resources[0].url)
        this.$set(this.resourceList[targetIndex].image, subIndex, {
          ...this.resourceList[targetIndex].image[subIndex],
          materialId: img.materialId
        })
      })
      //   this.$emit('confirm', this.resourceList)
      //   return true
    },
    delImg (index, index2) {
      const target = this.resourceList[index].image
      target[index2].src = null
      this.$set(this.resourceList, index, {
        ...this.resourceList.index,
        image: target
      })
    }
  },
  components: { MediaLibrary }
}
</script>
<style lang='scss' scoped>
.divide-line {
    width: 100%;
    border-top: 1px solid $borderColor;
    margin: 15px 0;
}
.vertical-video {
    &-container {
        .metas {
            .meta {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .control-twins-item {
            margin-top: 50px;
            border: 1px solid $borderColor;
            border-radius: 8px;
            padding: 40px;
            .list-item {
                width: 260px;
                height: 120px;
                background-color: #f5f6f8;
                border: 1px solid $borderColor;
                border-radius: 4px;
                margin-right: -1px;
                font-size: 14px;
                text-align: center;
                color: #b0b4bd;
                position: relative;
                .item-image {
                    position: relative;
                    width: 182px;
                    height: 102px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    i {
                        position: absolute;
                        left: 95%;
                        top: -5%;
                        color: $colorRed;
                        z-index: 999;
                        font-size: 16px;
                        opacity: 0;
                        background: $main-background;
                        border-radius: 50%;
                    }
                    .reset-upload-hover {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        transition: all 0.25s;
                        background-color: rgba(0, 0, 0, 0.4confirm);
                        opacity: 0;
                    }
                    &:hover {
                        .reset-upload-hover,
                        i {
                            opacity: 1;
                        }
                    }
                }
                .tips {
                    width: 80%;
                    cursor: pointer;
                    > p {
                        margin-bottom: 5px;
                    }
                }
                .el-icon-close {
                    position: absolute;
                    top: 5%;
                    left: 90%;
                }
                .el-icon-plus {
                    font-weight: bold;
                    margin-bottom: 10px;
                    font-size: 22px;
                }
                .loading-tips {
                    width: 182px;
                    height: 102px;
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                }
            }
            .operation-delete {
                align-self: center;
                margin-left: 20px;
                border-radius: 8px;
                background-color: #f5f6f8;
                border: 1px solid $borderColor;
                font-size: 18px;
                height: 30px;
                line-height: 30px;
                width: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
        .is-group {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .creative-item {
                margin-bottom: 40px;
            }
        }
    }
    .add-resource-item {
        width: 893px;
        margin-top: 40px;
        height: 36px;
        line-height: 36px;
        border: 1px dashed rgb(223, 225, 230);
        border-radius: 8px;
        background-color: #f5f6f8;
        text-align: center;
        cursor: pointer;
    }
    .library-choose {
        margin-top: 10px;
        > p {
            cursor: pointer;
            padding: 8px 0;
            transition: all 0.3s;
            &:hover {
                color: $main;
            }
        }
    }
}
</style>
