<template>
  <el-dialog
    title="广告配置"
    width="1350px"
    top="20px"
    append-to-body
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="config-ad">
      <div class="config-ad-container">
        <div class="config-ad-part">
          <span>转化归因</span>
          <div>
            <el-radio-group
              v-model="ASSET_TYPE_CONVERSION_SPEC"
              :disabled="disabled"
            >
              <el-radio-button label="WHOLE_NETWORK">全网归因</el-radio-button>
              <el-radio-button label="PRECISE_NETWORK"
                >精准匹配归因</el-radio-button
              >
            </el-radio-group>
            <div
              v-if="ASSET_TYPE_CONVERSION_SPEC === 'PRECISE_NETWORK'"
              style="margin-top: 10px; width: 184px"
            >
              <el-select
                v-model="netWorkValue"
                multiple
                :disabled="disabled"
                placeholder="请选择数据源"
              >
                <el-option
                  v-for="(item, index) in netWorkList"
                  :key="index"
                  :label="`${item.name}(${item.type})`"
                  :value="item.user_action_set_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="config-ad-part">
          <span>商品</span>
          <div class="goods flex-row-start-start">
            <div class="account-status">
              <p
                class="account-item-status flex-row-between-center"
                :class="{ 'is-active': index === curAccountIndex }"
                :key="item.id"
                v-for="(item, index) in copyAccount"
                @click="handleAccountClick(item, index)"
              >
                <span
                  class="line-overflow"
                  :title="`${item.accountName}(${item.accountId})`"
                  >{{ item.accountName }}({{ item.accountId }})</span
                >
                <el-tag :type="item.dynamicAdSpec ? 'success' : 'info'">
                  {{ item.dynamicAdSpec ? "已选择" : "未选择" }}
                </el-tag>
              </p>
            </div>
            <div class="goods-container">
              <div>
                <el-select
                  v-model="copyAccount[curAccountIndex].product_catalog_id"
                  placeholder="选择商品库"
                  clearable
                  filterable
                  @change="handleGoodsChange"
                >
                  <el-option
                    v-for="(item, index) in goodsTypeList"
                    :key="index"
                    :label="item.product_catalog_name"
                    :value="item.product_catalog_id"
                  ></el-option>
                </el-select>
                <el-input
                  clearable
                  style="width: 216px; margin-left: 10px"
                  placeholder="搜索商品名称"
                  :disabled="disabled"
                  v-model="searchProduction"
                ></el-input>
                <div class="choose-production">
                  <div class="choose-production-container">
                    <div
                      v-if="
                        productionList && productionList.length > 0 && !loading
                      "
                      class="choose-production-detail flex-row-start-start-wrap"
                    >
                      <el-radio-group
                        class="flex-row-start-start-wrap"
                        v-model="copyAccount[curAccountIndex].dynamicAdSpec"
                        @change="handleChange"
                      >
                        <el-radio
                          v-for="item in productionList"
                          :label="item.product_name"
                          :key="item.product_outer_id"
                          :value="item"
                          class="flex-row-start-center-wrap"
                        >
                          <div class="detail-item flex-row-start-center">
                            <div class="image-detail flex-center-center">
                              <img :src="item.product_image_url" alt />
                            </div>
                            <div class="info-detail line-overflow">
                              <p style>{{ item.product_name }}</p>
                              <p class='line-overflow' style="font-size: 12px; color: #ccc;margin-top:4px">ID:{{ item.product_outer_id }}</p>
                            </div>
                          </div>
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <div
                      v-if="
                        productionList &&
                        productionList.length === 0 &&
                        !loading
                      "
                      class="empty-tips"
                    >
                      <empty-icon tipsText="暂无数据！！"></empty-icon>
                    </div>
                    <div class="loading-tips" v-if="loading">
                      <i class="el-icon-loading"></i>
                      <p style="font-size: 14px; margin-top: 4px">
                        数据加载中...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="config-ad-part ad-position">
          <span>广告版位</span>
          <div class="ad-position-container">
            <el-button type="primary">选择特定版位</el-button>
            <div class="choose-ad-position">
              <div
                class="title-bar"
                @click="checkSiteType($event, 'TENCENT_SITE')"
              >
                <div class="top-bar">
                  <el-checkbox-group v-model="siteType" :disabled="disabled">
                    <el-checkbox label="TENCENT_SITE"
                      >腾讯平台与内容媒体</el-checkbox
                    >
                  </el-checkbox-group>
                  <div class="short-desc">
                    <p>汇聚腾讯产品矩阵，全场景打通腾讯生态内优质媒体与平台</p>
                  </div>
                </div>
                <div class="bottom-bar">
                  <div class="flex-row-start-start">
                    <div>
                      <el-checkbox-group
                        v-model="positionList"
                        :disabled="disabled"
                      >
                        <el-checkbox label="SITE_SET_KANDIAN"
                          >腾讯看点</el-checkbox
                        >
                        <el-checkbox label="SITE_SET_QQ_MUSIC_GAME"
                          >QQ、腾讯音乐及游戏</el-checkbox
                        >
                        <el-checkbox label="SITE_SET_TENCENT_NEWS"
                          >腾讯新闻</el-checkbox
                        >
                        <el-checkbox label="SITE_SET_TENCENT_VIDEO"
                          >腾讯视频</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="title-bar"
                @click="checkSiteType($event, 'SITE_SET_MOBILE_UNION')"
              >
                <div class="top-bar">
                  <el-checkbox-group
                    v-model="positionList"
                    :disabled="disabled"
                  >
                    <el-checkbox label="SITE_SET_MOBILE_UNION"
                      >优量汇</el-checkbox
                    >
                  </el-checkbox-group>
                  <div class="short-desc">
                    <p>
                      集合数万优质媒体海量曝光，覆盖5亿+人群全营销场景的优量生态
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="config-ad-part" v-if="positionList.includes('SITE_SET_MOBILE_UNION')">
          <span>优量汇行业精选流量包</span>
          <div>
            <el-radio-group v-model="trafficPackageType" :disabled="disabled">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="CUSTOM">自定义</el-radio-button>
            </el-radio-group>
            <div
              v-if="trafficPackageType === 'CUSTOM'"
              class="traffic-package-list"
              style="margin-top: 20px; width: 900px"
            >
              <el-checkbox-group v-model="mobile_union" :disabled="disabled">
                <el-checkbox label="MOBILE_UNION_GAME_ADVERTISER"
                  >重度游戏广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_LIGHT_GAME_ADVERTISER"
                  >轻度游戏广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_READ_ADVERTISER"
                  >阅读行业广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_EDUCATION_ADVERTISER"
                  >教育广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_LONGTAIL_ADVERTISER"
                  >行业优质流量包</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_FINANCE_ADVERTISER"
                  >金融广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_TOURISM_ADVERTISER"
                  >旅游广告主定制</el-checkbox
                >
                <el-checkbox label="MOBILE_UNION_EC_ADVERTISER"
                  >电商广告主定制</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div> -->
        <div class="config-ad-part">
          <span>投放日期</span>
          <div class="put-schedule">
            <el-radio-group v-model="putScheduleType" :disabled="disabled">
              <el-radio-button label="NONE">长期投放</el-radio-button>
              <el-radio-button label="CUSTOM"
                >指定开始日期和结束日期</el-radio-button
              >
            </el-radio-group>
            <div style="margin-top: 10px">
              <el-date-picker
                v-if="putScheduleType === 'CUSTOM'"
                v-model="putSchedule"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div class="config-ad-part put-time">
          <span>投放时间</span>
          <div>
            <el-radio-group v-model="putTime">
              <el-radio-button label="ALL_DAY">全天</el-radio-button>
              <el-radio-button label="START_END"
                >指定开始时间和结束时间</el-radio-button
              >
              <el-radio-button label="CUSTOM_PERIOD"
                >指定多个时间段</el-radio-button
              >
            </el-radio-group>
            <div class="custom-time">
              <div class="start-end-period" v-if="putTime === 'START_END'">
                <el-time-select
                  placeholder="起始时间"
                  v-model="startTime"
                  style="margin-right: 10px"
                  :picker-options="{
                    start: '00:00',
                    step: '01:00',
                    end: '24:00',
                  }"
                ></el-time-select>
                <el-time-select
                  placeholder="结束时间"
                  v-model="endTime"
                  :picker-options="{
                    start: '00:00',
                    step: '01:00',
                    end: '24:00',
                    minTime: startTime,
                  }"
                ></el-time-select>
              </div>
              <div v-if="putTime === 'CUSTOM_PERIOD'">
                <custom-schedule-vue
                  :inputData="putTimeData"
                  targetDomClassName=".config-ad"
                  @returnData="setPutTimeData"
                  @getTimeList="getTimeList"
                ></custom-schedule-vue>
              </div>
            </div>
          </div>
        </div>
        <div class="config-ad-part">
          <span>出价方式</span>
          <el-radio-group v-model="bidMethod" :disabled="disabled">
            <el-radio-button label="oCPM">oCPM</el-radio-button>
            <el-radio-button label="oCPC">oCPC</el-radio-button>
          </el-radio-group>
        </div>
        <div class="config-ad-part">
          <span>优化目标</span>
          <el-select v-model="optimizationType" placeholder>
            <el-option label="下单" value="OPTIMIZATIONGOAL_ECOMMERCE_ORDER"></el-option>
            <el-option label='付费' value='OPTIMIZATIONGOAL_ECOMMERCE_CHECKOUT'></el-option>
          </el-select>
        </div>
        <div class="config-ad-part">
          <span>出价类型</span>
          <el-button type="primary">手动出价</el-button>
        </div>
        <div class="config-ad-part">
          <span style="margin-top: 0">出价策略</span>
          <el-radio-group v-model="bid_strategy" :disabled="disabled">
            <el-radio-button label="BID_STRATEGY_AVERAGE_COST"
              >稳定拿量</el-radio-button
            >
            <el-radio-button label="BID_STRATEGY_TARGET_COST"
              >优先拿量</el-radio-button
            >
            <el-radio-button label="BID_STRATEGY_PRIORITY_LOW_COST"
              >优先低成本</el-radio-button
            >
          </el-radio-group>
        </div>
        <div class="config-ad-part">
          <span class="required">出价</span>
          <c-input
            :onlyNum="true"
            clearable
            :disabled="disabled"
            style="width: 220px"
            v-model="bid_amount"
            :min="0.1"
            :max="5000"
            placeholder="输入价格0.1 ~ 5000"
          ></c-input>
          <span style="margin-top: 10px; margin-left: 5px">元/下单</span>
        </div>
        <div class="config-ad-part" v-if="positionList.length >= 2">
          <span style="margin-top: 5px">分版位出价</span>
          <div style="flex-grow: 1">
            <el-switch
              v-model="bidAdjustmentSwitch"
              :disabled="disabled"
            ></el-switch>
            <div class="position-combination" v-if="bidAdjustmentSwitch">
              <div class="position-combination-container">
                <div class="combination-part flex-row-between-center">
                  <span style="width: 30%">广告位组合</span>
                  <span style="width: 50%">系数(0.5 ~ 1.5)</span>
                  <span style="width: 20%; text-align: right"
                    >出价(元/付费)</span
                  >
                </div>
                <div
                  class="combination-part flex-row-between-start"
                  v-for="item in bid_adjustment.site_set_package"
                  :key="item.id"
                >
                  <span style="width: 30%">
                    {{ item.site_set[0] | customSiteName }}
                  </span>
                  <span style="width: 50%">
                    <el-input-number
                      :min="0.5"
                      :max="1.5"
                      :step="0.1"
                      v-model="item.bid_coefficient"
                    ></el-input-number>
                  </span>
                  <span style="width: 20%; text-align: right">
                    {{
                      `${
                        +bid_amount * item.bid_coefficient
                          ? (+bid_amount * item.bid_coefficient).toFixed(2)
                          : "请设置出价"
                      }`
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="config-ad-part">
          <span>广告日预算</span>
          <div>
            <el-radio-group v-model="dailyBudgetType" :disabled="disabled">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="CUSTOM">自定义</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 10px" v-if="dailyBudgetType === 'CUSTOM'">
              <c-input
                v-model="daily_budget"
                :disabled="disabled"
                style="width: 300px"
                placeholder="金额在50 ~ 40,000,000 之间"
                :min="50"
                :max="40000000"
                :onlyNum="true"
              ></c-input
              >元
            </div>
          </div>
        </div>
        <div class="config-ad-part">
          <span>广告名称</span>
          <div>
            <c-input
              v-model="ad_name"
              :disabled="disabled"
              style="width: 300px"
            ></c-input>
            <limit-word
              style="position: absolute; left: 500px; top: 10px"
              :calculationRules="calculateCharacter"
              :isDivideByTwo="true"
              :maxLength="50"
              :curValue="ad_name"
              @error="showError = true"
              @change="showError = false"
            ></limit-word>
            <p style="margin-top: 5px; color: #fa8e00">
              系统将自动为每个创意名称加上后缀，建议名称不超过50字
            </p>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="button-list" style="text-align: left">
      <el-button type="primary" @click="handleConfirm">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getUserActionSets,
  getProductCatalogs,
  getProductionList
} from '@/api/tencent'
import EmptyIcon from '@/views/material-warehouse/icon.vue'
import LimitWord from '@/components/limit-word'
import { transformText } from './EN_ZH.js'
import { calculateCharacter } from '@/assets/js/utils'
import customScheduleVue from '@/components/custom/custom-schedule.vue'
const MAX_NUM = 50
export default {
  name: 'ConfigAd',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    }
  },
  filters: {
    customSiteName (value) {
      if (value === 'SITE_SET_KANDIAN') return '腾讯看点'
      if (value === 'SITE_SET_QQ_MUSIC_GAME') return 'QQ、腾讯音乐及游戏'
      if (value === 'SITE_SET_MOBILE_UNION') return '优量汇'
      if (value === 'SITE_SET_TENCENT_NEWS') return '腾讯新闻'
      if (value === 'SITE_SET_TENCENT_VIDEO') return '腾讯视频'
    }
  },
  data () {
    return {
      calculateCharacter,
      siteType: [], // 广告版位
      ASSET_TYPE_CONVERSION_SPEC: 'WHOLE_NETWORK',
      copyParams: null,
      netWorkValue: null,
      netWorkList: [],
      copyAccount: [],
      isInvokingAPI: false, // * 是否正在调用api
      isFilterOperation: false,
      goodsType: '',
      disabled: false, // 页面是否禁止交互
      loading: false,
      goodsTypeList: [],
      curAccountIndex: 0,
      searchProduction: '',
      productionList: [], // 商品列表
      copyProductionList: [], // 用于筛选
      positionList: [], // 版位
      bidMethod: 'oCPM',
      bid_amount: '', // 出价
      trafficPackageType: 'NONE', // 流量包类型
      putSchedule: [],
      putScheduleType: 'NONE', // 投放日期
      dailyBudgetType: 'NONE',
      daily_budget: '',
      ad_name: '',
      mobile_union: [
        'MOBILE_UNION_READ_ADVERTISER',
        'MOBILE_UNION_LONGTAIL_ADVERTISER'
      ], // 流量包选择列表
      optimizationType: 'OPTIMIZATIONGOAL_ECOMMERCE_ORDER', // 优化目标类型(默认下单)
      bid_strategy: 'BID_STRATEGY_TARGET_COST', // 出价策略
      bidAdjustmentSwitch: false, // 是否开启分版位出价
      showError: false,
      bid_adjustment: {
        // 分版位出价内容
        site_set_package: []
      },
      typeList: [
        'SITE_SET_KANDIAN',
        'SITE_SET_QQ_MUSIC_GAME',
        'SITE_SET_TENCENT_NEWS',
        'SITE_SET_TENCENT_VIDEO'
      ],
      putTime: 'ALL_DAY', // 投放时间类型
      putTimeData: [], // 投放时间数据
      putFormData: '', // 格式化投放时间数据
      startTime: null,
      endTime: null,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24
        }
      }
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          this.initData()
        } else {
          this.trafficPackageType = 'NONE'
          this.mobile_union = [
            'MOBILE_UNION_READ_ADVERTISER',
            'MOBILE_UNION_LONGTAIL_ADVERTISER'
          ]
        }
      },
      immediate: true
    },
    siteType: {
      handler (newV) {
        if (newV.indexOf('TENCENT_SITE') === -1) {
          for (const item of this.typeList) {
            const targetIndex = this.positionList.indexOf(item)
            if (targetIndex > -1) {
              this.positionList.splice(targetIndex, 1)
            }
          }
        } else {
          for (const item of this.typeList) {
            const targetIndex = this.positionList.indexOf(item)
            if (targetIndex === -1) {
              this.positionList.push(item)
            }
          }
        }
      }
    },
    positionList: {
      handler (newV) {
        const Index = this.siteType.findIndex(
          (item) => item === 'TENCENT_SITE'
        )
        if (this.typeList.every((item) => !newV.includes(item))) {
          if (this.siteType.includes('TENCENT_SITE')) {
            this.siteType.splice(Index, 1)
          }
        }
        if (this.typeList.every((item) => newV.includes(item))) {
          if (Index === -1) {
            this.siteType.push('TENCENT_SITE')
          }
        }

        const arr = []
        for (const type of newV) {
          arr.push({
            bid_coefficient: 1,
            site_set: [type]
          })
        }
        this.bid_adjustment.site_set_package = arr
      },
      immediate: true
    },
    // 商品搜索
    searchProduction: {
      handler (newV) {
        this.isFilterOperation = true
        if (newV) {
          this.productionList = JSON.parse(
            JSON.stringify(this.copyProductionList)
          ).filter((item) => {
            return item.product_name.includes(newV)
          })
        } else {
          this.productionList = JSON.parse(
            JSON.stringify(this.copyProductionList)
          )
        }
        this.$nextTick(() => {
          this.isFilterOperation = false
        })
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {

      }
    }
  },
  methods: {
    initData () {
      this.copyAccount = JSON.parse(JSON.stringify(this.account))
      const ids = this.copyAccount.map((item) => item.accountId).join(',')
      this.curAccountIndex = 0
      this.isInvokingAPI = true
      this.loading = true
      this.disabled = true
      Promise.all([
        getUserActionSets({ accountIds: ids }),
        getProductCatalogs({ accountId: this.copyAccount[0].accountId })
      ])
        .then(async (res) => {
          this.netWorkList = res[0]
          this.goodsTypeList = res[1]
          if (this.goodsTypeList.length === 0) {
            this.loading = false
          }
          if (this.goodsTypeList.length > 0) {
            this.$set(
              this.copyAccount[this.curAccountIndex],
              'product_catalog_id',
              this.goodsTypeList[0].product_catalog_id
            )
            await this.getProductionList()
            if (this.params && this.params.adConfig) {
              // 回显数据
              await this.echoData()
            }
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.disabled = false
        })
    },
    echoData () {
      this.copyParams = JSON.parse(JSON.stringify(this.params))
      const {
        dynamic_ad_spec,
        netWorkValue,
        dynamicAdSpec,
        bid_adjustment,
        trafficPackageType,
        putSchedule,
        putTime,
        bidMethod,
        optimizationType,
        bidType,
        bid_strategy,
        bid_amount,
        bid_adjustmentType,
        bidAdjustmentSwitch,
        ad_name,
        daily_budget,
        positionList
      } = this.copyParams.adConfig
      // * 商品
      const ectype = JSON.parse(JSON.stringify(this.copyAccount))
      ectype.forEach((item, index) => {
        // * 找到对应的账户进行赋值
        const targetValue = dynamic_ad_spec.value.find(v => v.id === item.id)
        item.dynamicAdSpec = targetValue && targetValue.dynamicAdSpec
        item.dynamic_ad_spec = targetValue && targetValue.dynamic_ad_spec
      })
      this.copyAccount = ectype
      // * 转化归因
      if (netWorkValue.value === 'WHOLE_NETWORK') {
        this.ASSET_TYPE_CONVERSION_SPEC = 'WHOLE_NETWORK'
        this.netWorkValue = []
      } else {
        this.ASSET_TYPE_CONVERSION_SPEC = 'PRECISE_NETWORK'
        this.netWorkValue = netWorkValue.value
      }
      // * 广告版位
      this.positionList = positionList ? positionList.value : []
      // * 精选包
      this.trafficPackageType =
        trafficPackageType.value === 'NONE' ? 'NONE' : 'CUSTOM'
      if (this.trafficPackageType === 'CUSTOM') {
        this.mobile_union = trafficPackageType.value
      }

      // * 投放日期
      if (putSchedule.value === 'NONE') {
        this.putScheduleType = 'NONE'
        this.putSchedule = []
      } else {
        this.putScheduleType = 'CUSTOM'
        this.putSchedule = putSchedule.value
      }
      // * 投放时间

      this.putTime = putTime.type
      this.putFormData = putTime.ChineseText
      if (this.putTime === 'CUSTOM_PERIOD') {
        this.putTimeData = putTime.value.split('').map(Number)
      }
      if (this.putTime === 'START_END') {
        this.startTime = putTime.startEndTime.startTime
        this.endTime = putTime.startEndTime.endTime
      }
      // todo => 回显广告优化方式
      this.optimizationType = optimizationType.value
      // * 出价方式
      this.bidMethod = bidMethod.value
      // * 出价策略
      this.bid_strategy = bid_strategy.value
      // * 出价
      this.bid_amount = bid_amount.value
      // * 分版位出价
      this.bidAdjustmentSwitch = bidAdjustmentSwitch.value
      if (this.bidAdjustmentSwitch) {
        this.$nextTick(() => {
          bid_adjustmentType.value.forEach((item, index) => {
            this.bid_adjustment.site_set_package[index].bid_coefficient =
              item.bid_coefficient
          })
        })
      }
      // * 广告日预算
      if (!daily_budget.value) {
        this.dailyBudgetType = 'NONE'
      } else {
        this.daily_budget = daily_budget.value
        this.dailyBudgetType = 'CUSTOM'
      }
      // * 广告名称
      this.ad_name = ad_name.value
    },
    handleGoodsChange (value) {
      if (!value) {
        this.productionList = []
        return
      }
      if (value) {
        this.getProductionList()
      }
    },
    handleConfirm () {
      if (this.copyAccount.some((item) => !item.dynamicAdSpec)) { return this.$message.warning('请检查商品配置项!') }
      if (
        this.ASSET_TYPE_CONVERSION_SPEC === 'PRECISE_NETWORK' &&
        (!this.netWorkValue || Array.isArray(this.netWorkValue)) &&
        this.netWorkValue.length === 0
      ) {
        return this.$message.warning('请选择转化归因!')
      }

      if (this.positionList.length === 0) {
        return this.$message.warning('请选择广告版位!')
      }

      if (
        this.trafficPackageType === 'CUSTOM' &&
        this.mobile_union.length === 0
      ) {
        return this.$message.warning('请勾选行业精选流量包!')
      }

      if (this.putScheduleType === 'CUSTOM') {
        if (!this.putSchedule || this.putSchedule.length !== 2) {
          return this.$message.warning('请选择投放时间段！')
        }
      }
      if (!this.bid_strategy) return this.$message.warning('请选择出价策略')

      if (!this.bid_amount) return this.$message.warning('请输入出价!')

      if (!this.ad_name) return this.$message.warning('请输入广告名称!')
      if (Math.ceil(this.calculateCharacter(this.ad_name) / 2) > MAX_NUM) {
        return this.$message.warning('请调整广告名称的长度!')
      }
      if (this.dailyBudgetType === 'CUSTOM') {
        if (!this.daily_budget) {
          return this.$message.warning('请输入广告日预算!')
        }
      }
      // ! 判断自定义时间段

      if (this.putTime !== 'ALL_DAY') {
        // ! 如果是开始-结束时间段  则需要将startTime 和endTime转化为putTimeData
        if (this.putTime === 'START_END') {
          if (!this.startTime) { return this.$message.warning('请选择投放开始时间!') }
          const startIndex = +this.startTime.split(':')[0] * 2

          const endIndex = this.endTime ? +this.endTime.split(':')[0] * 2 : 48
          if (endIndex < startIndex) return this.$message.warning('投放的开始时间不能大于结束时间!')

          const arr = Array.from({ length: 48 * 7 }, () => 0)
          // ! 取模进行运算
          for (let i = 0; i < arr.length; i++) {
            let model = (i + 1) % 48
            model = model === 0 ? 48 : model
            arr[i] = model > startIndex && model <= endIndex ? 1 : 0
          }
          this.putFormData = `每天：${this.startTime} ~ ${this.endTime}`
          this.putTimeData = arr
        }
        if (
          this.putTimeData.length === 0 ||
          this.putTimeData.every((item) => item === 0)
        ) {
          return this.$message.warning('请设置投放时间!')
        }
      }

      const obj = {
        SITE_SET_KANDIAN: '腾讯看点',
        SITE_SET_QQ_MUSIC_GAME: 'QQ、腾讯音乐及游戏',
        SITE_SET_TENCENT_NEWS: '腾讯新闻',
        SITE_SET_TENCENT_VIDEO: '腾讯视频',
        SITE_SET_MOBILE_UNION: '优量汇'
      }
      const obj2 = {
        MOBILE_UNION_GAME_ADVERTISER: '重度游戏广告主定制',
        MOBILE_UNION_LIGHT_GAME_ADVERTISER: '轻度游戏广告主定制',
        MOBILE_UNION_READ_ADVERTISER: '阅读行业广告主定制',
        MOBILE_UNION_EDUCATION_ADVERTISER: '教育广告主定制',
        MOBILE_UNION_LONGTAIL_ADVERTISER: '行业优质流量包',
        MOBILE_UNION_FINANCE_ADVERTISER: '金融广告主定制',
        MOBILE_UNION_TOURISM_ADVERTISER: '旅游广告主定制',
        MOBILE_UNION_EC_ADVERTISER: '电商广告主定制'
      }
      const obj3 = {
        BID_STRATEGY_AVERAGE_COST: '稳定拿量',
        BID_STRATEGY_TARGET_COST: '优先拿量',
        BID_STRATEGY_PRIORITY_LOW_COST: '优先低成本'
      }
      const optimizationTypeObj = {
        OPTIMIZATIONGOAL_ECOMMERCE_ORDER: '下单',
        OPTIMIZATIONGOAL_ECOMMERCE_CHECKOUT: '付费'
      }
      this.$emit('confirm', {
        netWorkValue: {
          value:
            this.ASSET_TYPE_CONVERSION_SPEC === 'WHOLE_NETWORK'
              ? 'WHOLE_NETWORK'
              : this.netWorkValue,
          ChineseText:
            this.ASSET_TYPE_CONVERSION_SPEC === 'WHOLE_NETWORK'
              ? '全网归因'
              : this.netWorkList
                .filter((item) =>
                  this.netWorkValue.find(
                    (val) => val === item.user_action_set_id
                  )
                )
                .map((item) => item.name)
                .join(','),
          propertyName: '转化归因'
        },
        dynamicAdSpec: {
          value: true,
          ChineseText: transformText.dynamicAdSpec,
          propertyName: '商品'
        },
        dynamic_ad_spec: {
          value: this.copyAccount,
          ChineseText: '商品账号所选合集',
          propertyName: '商品列表'
        },
        bid_adjustment: {
          value: this.bid_adjustment,
          ChineseText: this.bid_adjustment.site_set_package
            .map((item) => obj[item.site_set[0]])
            .join('，'),
          propertyName: '广告版位'
        },
        trafficPackageType: {
          value:
            this.trafficPackageType === 'NONE' ||
            !this.positionList.includes('SITE_SET_MOBILE_UNION')
              ? 'NONE'
              : this.mobile_union,
          ChineseText:
            this.trafficPackageType === 'NONE' ||
            !this.positionList.includes('SITE_SET_MOBILE_UNION')
              ? '不限'
              : this.mobile_union.map((item) => obj2[item]).join('，'),
          propertyName: '精选流量包'
        },
        putSchedule: {
          value: this.putScheduleType === 'NONE' ? 'NONE' : this.putSchedule,
          ChineseText:
            this.putScheduleType === 'NONE'
              ? '长期投放'
              : `${this.putSchedule[0]} ~ ${this.putSchedule[1]}`,
          propertyName: '投放日期'
        },
        putTime: {
          value:
            this.putTime === 'ALL_DAY'
              ? Array.from({ length: 48 * 7 }, () => 1).join('')
              : this.putTimeData.join(''),
          // ChineseText: '全天',
          ChineseText: this.putTime === 'ALL_DAY' ? '全天' : this.putFormData,
          type: this.putTime,
          startEndTime: { startTime: this.startTime, endTime: this.endTime },
          propertyName: '投放时间'
        },
        bidMethod: {
          value: this.bidMethod,
          ChineseText: this.bidMethod,
          propertyName: '出价方式'
        },
        optimizationType: {
          value: this.optimizationType,
          ChineseText: optimizationTypeObj[this.optimizationType],
          propertyName: '优化目标'
        },
        bidType: {
          value: 'SMART_BID_TYPE_CUSTOM',
          ChineseText: '手动出价',
          propertyName: '出价类型'
        },
        bid_strategy: {
          value: this.bid_strategy,
          ChineseText: obj3[this.bid_strategy],
          propertyName: '出价策略'
        },
        bid_amount: {
          value: this.bid_amount,
          ChineseText: `${this.bid_amount}元`,
          propertyName: '出价'
        },
        bidAdjustmentSwitch: {
          value:
            this.positionList.length < 2 ? false : this.bidAdjustmentSwitch,
          ChineseText:
            this.bidAdjustmentSwitch && this.positionList.length >= 2
              ? '开'
              : '关',
          propertyName: '版位是否开启'
        },
        bid_adjustmentType: {
          value: this.bid_adjustment.site_set_package,
          ChineseText: this.bid_adjustment.site_set_package
            .map((item) => `${obj[item.site_set[0]]}-${item.bid_coefficient}`)
            .join(','),
          propertyName: '分版位出价'
        },
        positionList: {
          value: this.positionList,
          ChineseText: this.positionList.map((item) => obj[item]).join(','),
          propertyName: '所选版位'
        },
        daily_budget: {
          value: this.dailyBudgetType === 'NONE' ? null : this.daily_budget,
          ChineseText:
            this.dailyBudgetType === 'NONE' ? '不限' : `${this.daily_budget}元`,
          propertyName: '广告日预算'
        },
        ad_name: {
          value: this.ad_name,
          ChineseText: this.ad_name,
          propertyName: '广告名称'
        }
      })

      this.handleClose()
    },
    handleCancel () {
      // this.handleClose()
      this.$emit('close')
    },
    handleClose () {
      this.$emit('close')
    },
    addType (type) {
      const Index = this.positionList.indexOf(type)
      Index === -1
        ? this.positionList.push(type)
        : this.positionList.splice(Index, 1)
    },
    checkSiteType (e, type) {
      if (this.disabled) return
      e.stopPropagation()
      const checkboxArr = [...document.querySelectorAll('.el-checkbox')]
      if (checkboxArr.some((checkbox) => checkbox.contains(e.target))) return
      // ! 腾讯平台与内容媒体
      if (type === 'TENCENT_SITE') {
        const Index = this.siteType.indexOf(type)
        if (Index === -1) {
          this.siteType.push(type)
          for (const item of this.typeList) {
            const targetIndex = this.positionList.indexOf(item)
            if (targetIndex === -1) {
              this.positionList.push(item)
            }
          }
        } else {
          this.siteType.splice(Index, 1)
          for (const item of this.typeList) {
            const targetIndex = this.positionList.indexOf(item)
            if (targetIndex > -1) {
              this.positionList.splice(targetIndex, 1)
            }
          }
        }
      }
      // ! 优量汇
      if (type === 'SITE_SET_MOBILE_UNION') {
        const Index = this.positionList.indexOf(type)
        Index === -1
          ? this.positionList.push(type)
          : this.positionList.splice(Index, 1)
      }
    },
    getGoodsList () {
      this.loading = true
      this.isInvokingAPI = true
      const { accountId } = this.copyAccount[this.curAccountIndex]
      getProductCatalogs({
        accountId
      })
        .then(async (res) => {
          this.goodsTypeList = res
          if (res.length > 0) {
            this.$set(
              this.copyAccount[this.curAccountIndex],
              'product_catalog_id',
              this.goodsTypeList[0].product_catalog_id
            )
            await this.getProductionList()
          } else {
            this.productionList = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getProductionList () {
      return new Promise((resolve) => {
        this.productionList = []
        const { accountId, product_catalog_id } =
          this.copyAccount[this.curAccountIndex]
        getProductionList({
          accountId,
          product_catalog_id
        })
          .then((res) => {
            res.forEach((item) => {
              item.checked = false
              item.account_id = accountId
            })
            this.productionList = res
            this.copyProductionList = JSON.parse(JSON.stringify(res))
          })
          .finally(() => {
            this.$nextTick(() => {
              resolve()
            })
          })
      })
    },
    handleChange (val) {
      this.isInvokingAPI = false
      const targetItem = this.productionList.find(
        (item) => item.product_name === val
      )
      const { accountId, product_catalog_id } =
        this.copyAccount[this.curAccountIndex]
      if (targetItem) {
        this.$set(this.copyAccount, this.curAccountIndex, {
          ...this.copyAccount[this.curAccountIndex],
          dynamic_ad_spec: {
            account_id: accountId,
            product_catalog_id,
            product_mode: 'SINGLE',
            product_source: targetItem.product_outer_id,
            product_name: targetItem.product_name,
            product_image_url: targetItem.product_image_url
          }
        })
      }
    },
    setPutTimeData (val) {
      this.putTimeData = val.split('')
    },
    getTimeList (val) {
      const arr = ['一', '二', '三', '四', '五', '六', '日']
      const value = val.map((item, index) => {
        return {
          time: `星期${arr[index]}`,
          data:
            Array.isArray(item) && item.length > 0
              ? item.map((v) => v.timePeriod).join(',')
              : null
        }
      })
      this.putFormData = value
        .filter((item) => !!item.data)
        .map((item) => `${item.time}：${item.data}`)
        .join('，')
    },
    handleAccountClick (item, index) {
      this.searchProduction = ''
      this.curAccountIndex = index
      this.getGoodsList()
    }
  },
  components: {
    EmptyIcon,
    LimitWord,
    customScheduleVue
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__body {
  min-height: 700px;
}
.config-ad {
  height: 700px;
  overflow: auto;
  &-container {
    .config-ad-part {
      @include flex-something;
      position: relative;
      > span {
        width: 15%;
      }
    }
    .put-time {
      .custom-time {
        margin-top: 20px;
        /deep/ .custom-schedule {
          width: 900px;
        }
      }
    }
  }
  .goods {
    width: 85%;
    &-container {
      width: 90%;
      .choose-production {
        width: 98%;
        margin-top: 20px;
        position: relative;
        &-container {
          padding: 15px;
          height: 400px;
          overflow: auto;
          border-radius: 8px;
          border: 1px solid $borderColor;
          .choose-production-detail {
            .detail-item {
              width: 350px;
              margin: 0 20px 15px 0 !important;
              .image-detail {
                width: 80px;
                height: 80px;
                flex-shrink: 0;
                margin-left: 10px;
                border-radius: 8px;
                background: #313233;
                img {
                  height: 100%;
                }
              }
              .info-detail {
                margin-left: 10px;
              }
            }
            // 修复插件uibug
            .el-radio + .el-radio {
              margin-left: 0 !important;
            }
          }
          .empty-tips,
          .loading-tips {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .loading-tips {
            text-align: center;
            font-size: 24px;
          }
        }
      }
    }
    .account-status {
      max-height: 450px;
      margin-right: 20px;
      overflow: auto;
      width: 180px;
      border-radius: 8px;
      padding: 10px;
      font-size: 14px;
      border: 1px solid $borderColor;
      top: 54px;
      > p {
        margin-bottom: 20px;
        top: 50%;
        left: 5%;
        font-size: 12px;
        border-radius: 8px;
        padding: 4px 8px;
        cursor: pointer;
        border: 1px solid transparent;
      }
      .is-active {
        color: $main;
        border-color: $main;
      }
    }
  }
  .ad-position {
    margin-top: 40px;
    &-container {
      flex-grow: 1;
      .choose-ad-position {
        margin-top: 20px;
        width: 80%;
        .title-bar {
          border-radius: 8px;
          padding: 10px;
          border: 1px solid $borderColor;
          margin-bottom: 10px;
          > div {
          }
          .top-bar {
            padding: 16px;
            .short-desc {
              margin-left: 25px;
              margin-top: 10px;
              > p {
                margin-bottom: 15px;
              }
            }
          }
          .bottom-bar {
            > div {
              margin-left: 40px;
            }
          }
          &:hover {
            border-color: $main;
          }
        }
      }
    }
  }
  .position-combination {
    width: 80%;
    &-container {
      border: 1px solid $borderColor;
      margin-top: 20px;
      border-radius: 8px;
      .combination-part {
        padding: 15px;
        > span {
          line-height: 32px;
        }
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid $borderColor;
        }
      }
    }
  }
  .traffic-package-list {
    /deep/ .el-checkbox {
      margin-bottom: 10px;
      width: 150px;
    }
  }
}
</style>
