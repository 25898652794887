<template>
  <el-dialog
    top="20px"
    width="900px"
    append-to-body
    title="选择人群包"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <template v-if="show">
      <div class="crowd-container">
        <div class="crowd-list">
          <div
            class="crowd-item"
            v-for="(item, index) in copyAccount"
            :key="item.id"
          >
            <div
              class="line-overflow"
              style="width: 350px; margin: 10px 20px 30px 0"
            >
              {{ item.accountName }}（{{ item.accountId }}）
            </div>
            <el-select
              style="width: 400px"
              v-model="item.crowd"
              placeholder="请选择人群包"
              filterable
              clearable
              multiple
              loading-text="数据加载中..."
              remote
              :loading="loading"
              v-if="!chooseStatus[index]"
            >
              <el-option
                v-for="option in options[index]"
                :key="option.audience_id"
                :label="`${option.name}`"
                :value="option.audience_id"
              >
                <div class="flex-row-between-center">
                  <div class="line-overflow" style="width: 230px">
                    {{ `${option.name}` }}
                  </div>
                  <div style="width: 30%">
                    {{ `(人数：${option.user_count})` }}
                  </div>
                </div>
              </el-option>
            </el-select>
            <el-tooltip
              :content="chooseStatus[index] ? '取消全选' : '全选'"
              placement="left"
            >
              <el-switch
                v-model="chooseStatus[index]"
                :disabled="
                  !(Array.isArray(options[index]) && options[index].length > 0)
                "
                style="margin-top: 6px"
                @change="(val) => chooseAllCrowd(index, val)"
              ></el-switch>
            </el-tooltip>
          </div>
        </div>
      </div>
    </template>
    <div class="button-list" slot="footer">
      <el-button type="primary" @click="handleConfirm">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCrowdList } from '@/api/tencent.js'
export default {
  name: 'AddCrowd',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      page: 1,
      total: 0,
      options: [],
      pageSize: 15,
      loading: false,
      copyAccount: [],
      chooseStatus: []
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {

      }
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    initData () {
      this.copyAccount = JSON.parse(JSON.stringify(this.account))
      this.chooseStatus = this.copyAccount.map((item) => true)

      Promise.all(
        Array.from({ length: this.copyAccount.length }, (_, index) =>
          this.getCrowdList(this.copyAccount[index].accountId)
        )
      ).then((res) => {
        this.options = res
        this.copyAccount.forEach((item, index) => {
          const crowdOption = res[index]
          const { crowd } = item
          this.chooseStatus[index] = !!crowdOption.every(
            (c) => !!crowd && crowd.find((d) => d === c.audience_id)
          )
        })
      })
    },
    chooseAllCrowd (index, val) {
      this.copyAccount[index].crowd = val
        ? this.options[index].map((item) => item.audience_id)
        : null
    },
    handleClose () {
      this.$emit('close')
    },
    getCrowd (accountId, flag) {
      if (flag) {
        this.loading = true
        getCrowdList({
          page: 1,
          pageSize: 100,
          accountId: +accountId
        })
          .then((res) => {
            this.options = res
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.options = []
      }
    },
    getCrowdList (accountId) {
      return new Promise((resolve) => {
        this.loading = true
        getCrowdList({
          page: 1,
          pageSize: 100,
          accountId: +accountId
        })
          .then((res) => {
            this.options = res
            resolve(res)
          })
          .finally(() => {
            this.loading = false
            resolve([])
          })
      })
    },
    handleConfirm () {
      this.$emit('confirm', this.copyAccount)
      this.handleCancel()
    },
    handleCancel () {
      this.$emit('close')
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.crowd-container {
  max-height: 500px;
  overflow: auto;
  .crowd-list {
    .crowd-item {
      @include flex(space-between, flex-start);
      margin-right: 20px;
    }
  }
}
</style>
