<template>
  <el-dialog
    append-to-body
    title="创意配置"
    width="1300px"
    top="20px"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="config">
      <div class="config-container">
        <div class="config-part">
          <span>创意展现</span>
          <div>
            <el-button type="primary" class="button-small">优选模式</el-button>
            <div style="margin-top: 20px" class="type-list flex-row-start-center-wrap">
              <div
                class="type-item"
                @click="curTypeIndex = 721"
                :class="{ 'is-active': curTypeIndex === 721 }"
                data-id="721"
              >
                <svg viewBox="0 0 57 94" width="57" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g
                    transform="translate(.5)"
                    fill="none"
                    fill-rule="evenodd"
                    data-darkreader-inline-fill
                    style="--darkreader-inline-fill: none"
                  >
                    <rect
                      fill="#00bf8a"
                      opacity=".8"
                      width="56"
                      height="94"
                      rx="4"
                      data-darkreader-inline-fill
                      style="--darkreader-inline-fill: #0d43b0"
                    />
                    <path
                      fill="#FFF"
                      d="M22.086 41.363l14.798 8.415-14.798 8.414z"
                      data-darkreader-inline-fill
                      style="--darkreader-inline-fill: #e8e6e3"
                    />
                  </g>
                </svg>
                <span>竖版视频 9:16</span>
              </div>
              <div
                class="type-item"
                @click="curTypeIndex = 711"
                data-id="711"
                :class="{ 'is-active': curTypeIndex === 711 }"
              >
                <svg viewBox="0 0 56 94" width="56" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DCDEE3" fill="#FFF" x=".5" y=".5" width="55" height="93" rx="4" />
                    <path fill="#00bf8a" opacity=".8" d="M0 24h56v32.138H0z" />
                    <path
                      fill="#3B4457"
                      opacity=".044"
                      d="M4 7h7v5H4zm10 0h14v5H14zM4 15h48v5H4zm0 60h12v13H4zm15 0h14v5H19zm0 8h33v5H19zM4 60.138h48v5H4zm-4 9h55v1H0z"
                    />
                  </g>
                </svg>
                <span>横版大图 16:9</span>
              </div>
              <div
                class="type-item"
                @click="curTypeIndex = 720"
                data-id="720"
                :class="{ 'is-active': curTypeIndex === 720 }"
              >
                <svg viewBox="0 0 56 95" width="56" height="95" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect
                      stroke="#DCDEE3"
                      fill="#FFF"
                      x=".5"
                      y="1.5"
                      width="55"
                      height="93"
                      rx="4"
                    />
                    <path fill="#00bf8a" opacity=".8" d="M0 31h56v32.138H0z" />
                    <path fill="#FFF" d="M23.238 41.42l10.818 6.151-10.818 6.152z" />
                    <path
                      fill="#3B4457"
                      opacity=".044"
                      d="M4 66h31v5H4zm39 0h9v5h-9zM4 21h31v5H4zm39 0h9v5h-9zM0 76h56v14.95a4 4 0 01-4 4H4a4 4 0 01-4-4V76zM4 .738h48a4 4 0 014 4v13.074H0V4.738a4 4 0 014-4z"
                    />
                  </g>
                </svg>
                <span>横版视频 16:9</span>
              </div>
              <div
                class="type-item"
                data-id="712"
                @click="curTypeIndex = 712"
                :class="{ 'is-active': curTypeIndex === 712 }"
              >
                <svg viewBox="0 0 56 94" width="56" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DCDEE3" fill="#FFF" x=".5" y=".5" width="55" height="93" rx="4" />
                    <path d="M4 0h48a4 4 0 014 4v73H0V4a4 4 0 014-4z" fill="#00bf8a" opacity=".8" />
                    <path fill="#3B4457" opacity=".044" d="M17 80h22v5H17zM6 87h44v3H6z" />
                  </g>
                </svg>
                <span>竖版大图9:16</span>
              </div>
              <div
                class="type-item"
                @click="curTypeIndex = 718"
                data-id="718"
                :class="{ 'is-active': curTypeIndex === 718 }"
              >
                <svg viewBox="0 0 56 94" width="56" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DCDEE3" fill="#FFF" x=".5" y=".5" width="55" height="93" rx="4" />
                    <path
                      fill="#00bf8a"
                      opacity=".8"
                      d="M4 40h15v11H4zm16 0h15v11H20zm16 0h16v11H36z"
                    />
                    <path
                      fill="#3B4457"
                      opacity=".044"
                      d="M36 10h16v12H36zM4 17h29v4H4zm0-7h29v4H4zm0 23h48v4H4zm0-5h48v1H4zm32 41h16v12H36zM4 76h29v4H4zm0-7h29v4H4zm0 18h48v1H4zm0-24h48v1H4zm0-9h31v4H4z"
                    />
                  </g>
                </svg>
                <span>横版三小图 3:2</span>
              </div>
              <div
                class="type-item"
                data-id="714"
                @click="curTypeIndex = 714"
                :class="{ 'is-active': curTypeIndex === 714 }"
              >
                <svg viewBox="0 0 56 94" width="56" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DCDEE3" fill="#FFF" x=".5" y=".5" width="55" height="93" rx="4" />
                    <path fill="#00bf8a" opacity=".8" d="M32 30h20v13H32z" />
                    <path
                      fill="#3B4457"
                      opacity=".044"
                      d="M4 30h16v3H4zm0 5h25v3H4zm0 5h8v3H4zM2 1h51a2 2 0 012 2v21.882H0V3a2 2 0 012-2zm2 47h48v1H4zm28 5h20v13H32zM4 53h16v3H4zm0 5h25v3H4zm0 5h8v3H4zm0 8h48v1H4zm28 5h20v13H32zM4 76h16v3H4zm0 5h25v3H4zm0 5h8v3H4z"
                    />
                  </g>
                </svg>
                <span>横版小图3:2</span>
              </div>
              <div
                class="type-item"
                data-id="722"
                @click="curTypeIndex = 722"
                :class="{ 'is-active': curTypeIndex === 722 }"
              >
                <svg viewBox="0 0 56 94" width="56" height="94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DCDEE3" fill="#FFF" x=".5" y=".5" width="55" height="93" rx="4" />
                    <path d="M4 0h48a4 4 0 014 4v28H0V4a4 4 0 014-4z" fill="#00bf8a" opacity=".8" />
                    <path fill="#FFF" d="M23.238 10.42l10.818 6.151-10.818 6.152z" />
                    <path
                      fill="#3B4457"
                      opacity=".044"
                      d="M4 50h48v1H4zm0 6h20v13H4zm23 3h16v3H27zm0 5h25v3H27zM4 74h20v13H4zm23 3h16v3H27zm0 5h25v3H27zM4 36h30v3H4zm0 5h23v3H4z"
                    />
                  </g>
                </svg>
                <span>贴片视频16:9</span>
              </div>
              <div
                class="type-item"
                data-id="910"
                @click="curTypeIndex = 910"
                :class="{ 'is-active': curTypeIndex === 910 }"
              >
                <svg width="56" height="94" viewBox="0 0 40 68" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect stroke="#DFE1E6" fill="#FFF" x=".5" y=".5" width="39" height="67" rx="4" />
                    <rect fill="#00bf8a" x="3" y="5" width="34" height="4" rx="1" />
                    <path
                      fill="#313233"
                      opacity=".044"
                      d="M3 44h34v3H3zM3 56h34v3H3zM3 50h34v3H3zM3 62h34v3H3zM3 12h34v29H3z"
                    />
                  </g>
                </svg>
                <span>激励浏览广告</span>
              </div>
              <div
                class="type-item"
                data-id="1529"
                @click="curTypeIndex = 1529"
                :class="{ 'is-active': curTypeIndex === 1529 }"
              >
                <svg width="56" height="94" viewBox="0 0 56 94" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <rect
                      stroke="#DCDEE3"
                      fill="#FFF"
                      fill-rule="nonzero"
                      x=".5"
                      y=".5"
                      width="55"
                      height="93"
                      rx="4"
                    />
                    <path
                      d="M4 0h48a4 4 0 014 4v73H0V4a4 4 0 014-4z"
                      fill="#00bf8a"
                      fill-rule="nonzero"
                      opacity=".8"
                    />
                    <path
                      d="M38.232 40.703l-14.984 9.221a2 2 0 01-3.048-1.703V29.779a2 2 0 013.048-1.703l14.984 9.22a2 2 0 010 3.407z"
                      fill="#FFF"
                    />
                    <path
                      d="M17 80h22v5H17v-5zM6 87h44v3H6v-3z"
                      fill="#3B4457"
                      fill-rule="nonzero"
                      opacity=".044"
                    />
                  </g>
                </svg>
                <span>闪屏视频9:16</span>
              </div>
            </div>
          </div>
        </div>
        <div class="config-part">
          <template v-if="!EXCLUDE_IDS.includes(curTypeIndex)">
            <span>品牌形象</span>
            <div>
              <el-select
                v-model="brandInfo"
                clearable
                style="margin-right: 10px"
                placeholder="选择品牌形象"
              >
                <el-option
                  v-for="item in getBrandList()"
                  :key="item.materialId"
                  :value="item.materialId"
                  :label="item.brandName"
                  class="custom-option"
                >
                  <div class="option-container flex-row-start-center">
                    <img :src="item.url" alt="品牌形象" />
                    <span>{{ item.brandName }}</span>
                    <i
                      class="el-icon-delete-solid"
                      style="margin-left: auto; color: #fd5d5a"
                      @click="delAdqBrand(item.id)"
                    ></i>
                  </div>
                </el-option>
              </el-select>
              <el-button type="warning" @click="uploadFile('bandFile')">上传图片(形象)</el-button>
              <span class="tips" style="margin-left: 10px">图片尺寸512 * 512 不大于50KB</span>
            </div>
          </template>
          <template v-if="checkStatus()">
            <span>创意品牌标识图</span>
            <div class="brand-target">
              <div class="flex-center-center">
                <div class="tips" @click="uploadBrand" v-if="!brandInfo || !brandInfo.url">
                  <p>点击上传</p>
                  <p>512px * 512px</p>
                  <p>小于400KB</p>
                </div>
                <div v-if="brandInfo && brandInfo.url" class="image-info flex-center-center">
                  <img :src="brandInfo.url" alt />
                  <div class="repeat-info flex-center-center" @click="uploadBrand">
                    <div>
                      <p>点击重新上传</p>
                      <p>512px * 512px</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="creative-quick-toolbar" @click="chooseLibrary">
                <i class="el-icon-picture" style="margin-right: 4px"></i>
                <span>从素材中心选择</span>
              </div>
              <template v-if="curTypeIndex === 722">
                <div class="b-part">
                  <c-input v-model="customBrandName" placeholder="品牌名称" style="width: 400px"></c-input>
                  <limit-word
                    style="position: absolute; left: 410px; top: 10px"
                    :calculationRules="calculateCharacter"
                    :isDivideByTwo="true"
                    :maxLength="8"
                    :curValue="customBrandName"
                  ></limit-word>
                </div>
                <div class="b-part">
                  <c-input v-model="brandDoc" placeholder="品牌文案" style="width: 400px"></c-input>
                  <limit-word
                    style="position: absolute; left: 410px; top: 10px"
                    :calculationRules="calculateCharacter"
                    :isDivideByTwo="true"
                    :maxLength="14"
                    :curValue="brandDoc"
                  ></limit-word>
                </div>
              </template>
            </div>
            <input
              type="file"
              ref="brandImage"
              style="display: none"
              @change="
                (file) => {
                  handleFileChange('brandImage', file);
                }
              "
            />
          </template>
        </div>
        <div class="config-part" v-if="checkStatusImg()">
          <span>行动按钮</span>
          <div>
            <el-select
              style="width: 300px !important"
              v-model="actionText"
              filterable
              placeholder="请选择按钮文案"
            >
              <el-option
                v-for="(item, index) in actionList"
                :key="index"
                :label="item"
                :value="item"
              >{{ item }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="config-part landing-page">
          <span>
            落地页(阅宝)
            <i
              class="el-icon-check"
              style="margin-left: 4px; color: #00bf8a; font-size: 12px"
              v-if="copyAccount.every((item) => checkPageSpec(item))"
            ></i>
          </span>
          <div class="landing-page-container">
            <div class="flex-row-start-start">
              <div class="account-status">
                <p
                  class="account-item-status flex-row-between-center"
                  :class="{ 'is-active': index === curAccountIndex }"
                  :key="item.id"
                  v-for="(item, index) in copyAccount"
                  @click="handleAccountClick(item, index)"
                >
                  <span
                    class="line-overflow"
                    :title="`${item.accountName}(${item.accountId})`"
                  >{{ item.accountName }}({{ item.accountId }})</span>
                  <el-tag :type="checkPageSpec(item) ? 'success' : 'info'">
                    {{
                      checkPageSpec(item) ? "已选择" : "未选择"
                    }}
                  </el-tag>
                </p>
              </div>
              <div
                v-if="
                  params.projectConfig.promoted_object_type.value ===
                  'PROMOTED_OBJECT_TYPE_LEAD_AD'
                "
              >
                <div style="margin-bottom: 11px">
                  <el-radio-group v-model="copyAccount[curAccountIndex].landingType">
                    <el-radio-button label="YueBao">阅宝</el-radio-button>
                    <el-radio-button label="CUSTOM">自定义</el-radio-button>
                  </el-radio-group>
                </div>
                <el-select
                  v-if="copyAccount[curAccountIndex].landingType === 'YueBao'"
                  v-model="copyAccount[curAccountIndex].pageSpec"
                  :style="selectStyle"
                  filterable
                  clearable
                  remote
                  :multiple="curTypeIndex === 910"
                  placeholder="请选择落地页"
                  loading-text="数据加载中..."
                  :loading="loading"
                  :remote-method="
                    () => {
                      getLandingPage();
                    }
                  "
                >
                  <el-option
                    v-for="page in landingPageList"
                    :key="page.page_id"
                    :label="`${page.page_name}(${page.page_id})`"
                    :value="page.page_id"
                  ></el-option>
                </el-select>
                <div v-else-if="curTypeIndex === 910" class="page-spec-list border-radius-6">
                  <div
                    class="page-spec-item"
                    v-for="(item, index) in copyAccount[curAccountIndex].pageSpec"
                    :key="index"
                  >
                    <el-input
                      v-model="copyAccount[curAccountIndex].pageSpec[index]"
                      placeholder="请填写落地页链接!"
                      style="width: 400px;"
                      clearable
                    />
                    <i class="el-icon-circle-plus add-icon" @click="copyAccount[curAccountIndex].pageSpec.push('')" v-if="index === copyAccount[curAccountIndex].pageSpec.length - 1"> </i>
                    <i class="el-icon-remove remove-icon" style="margin-left:4px" @click="copyAccount[curAccountIndex].pageSpec.splice(index,1)" v-if=" copyAccount[curAccountIndex].pageSpec.length > 1"></i>
                  </div>
                </div>
                <el-input
                  v-else
                  v-model="copyAccount[curAccountIndex].pageSpec"
                  placeholder="请填写落地页链接"
                  style="width:400px"
                  clearable
                ></el-input>
              </div>
              <div v-else-if="curTypeIndex === 910" class="page-spec-list border-radius-6">
                <div class="page-spec-item" v-for="(item,index) in copyAccount[curAccountIndex].pageSpec" :key="index">
                     <el-input v-model="copyAccount[curAccountIndex].pageSpec[index]" placeholder="请填写落地页链接" style="width:400px" clearable  />
                     <i class="el-icon-circle-plus add-icon" @click="copyAccount[curAccountIndex].pageSpec.push('')" v-if="index === copyAccount[curAccountIndex].pageSpec.length - 1"></i>
                     <i class="el-icon-remove remove-icon" style="margin-left:4px" @click="copyAccount[curAccountIndex].pageSpec.splice(index,1)" v-if=" copyAccount[curAccountIndex].pageSpec.length > 1"></i>
                </div>
              </div>
              <el-input
                v-else
                v-model="copyAccount[curAccountIndex].pageSpec"
                placeholder="请填写落地页链接!"
                style="width: 400px"
                clearable
              />
            </div>
          </div>
        </div>
        <div class="config-part">
          <span class="required">创意名称</span>
          <div style="position: relative">
            <el-input
              v-model="adcreative_name"
              style="width: 400px"
              placeholder="请填写创意名称"
              clearable
            ></el-input>
            <limit-word
              style="position: absolute; left: 410px; top: 10px"
              :calculationRules="calculateCharacter"
              :isDivideByTwo="true"
              :initLength="initLength"
              :maxLength="50"
              :curValue="adcreative_name"
              @error="showError = true"
              @change="showError = false"
            ></limit-word>
            <p style="margin-top: 5px; color: #fa8e00">系统将自动为每个创意名称加上后缀，建议名称不超过50字</p>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="button-list">
      <el-button type="primary" @click="handleConfirm">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
    <el-dialog
      title="上传品牌形象"
      append-to-body
      :visible.sync="showUploadDialog"
      :before-close="
        () => {
          showUploadDialog = false;
        }
      "
    >
      <input
        style="display: none"
        type="file"
        ref="bandFile"
        @change="
          (file) => {
            handleFileChange('bandFile', file);
          }
        "
      />
      <div class="upload-container">
        <div class="brand-image flex-center-center">
          <div class="loading-tips" @click="handleUpload" v-if="!cBrandImage">
            <p>点击上传</p>
            <p>512px * 512px</p>
            <p class="divide-line"></p>
            <p>小于50KB</p>
          </div>
          <div v-else class="image-container flex-center-center" @click="resetUpload">
            <img :src="cBrandImage.src" alt />
            <div class="tips">
              <i class="el-icon-error del-icon" @click.stop="cBrandImage = null"></i>
              <p>点击重新上传</p>
            </div>
          </div>
        </div>
        <div class="brand-title">
          <p>品牌名称</p>
        </div>
        <div class="brand-name">
          <c-input style="width: 400px" v-model="customBrandName" placeholder="请输入品牌名称"></c-input>
          <limit-word
            style="position: absolute; left: 410px; top: 10px"
            :calculationRules="calculateCharacter"
            :isDivideByTwo="true"
            :maxLength="8"
            :curValue="customBrandName"
            @getInputLen="getInputLen"
          ></limit-word>
        </div>
      </div>
      <div slot="footer" class="button-list">
        <c-button
          type="primary"
          @click="
            (cb) => {
              handleOk(cb);
            }
          "
        >确定</c-button>
        <el-button @click="handleReject">取消</el-button>
      </div>
    </el-dialog>
    <brand-image-list :show="showBrandImage" @confirm="confirm" @close="showBrandImage = false"></brand-image-list>
  </el-dialog>
</template>

<script>
import LimitWord from '@/components/limit-word'
import BrandImageList from './brand-image-list'
import { addMaterialWare } from '@/api/material'
import { calculateCharacter, imageStatusCheck, isValidArray } from '@/assets/js/utils'
import { uploadFile } from '@/api/common'
import _ from 'lodash'
import {
  getLandingPage,
  addAdqBrand,
  getAdqBrandList,
  delAdqBrand
} from '@/api/tencent'

const EXCLUDE_IDS = [712, 722, 910, 1529] // 不需要展示下拉框选择品牌列表的模块（其中712,722,是展示其它替代模块，后面的直接不展示）
const IMAGE_LIMIT_SIZE = 50

export default {
  name: 'ConfigCreativity',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      landingType: 'YueBao',
      actionText: '了解更多',
      adcreative_name: '',
      curTypeIndex: 0,
      showError: false,
      calculateCharacter,
      uploadLoading: false, // 上传状态
      copyAccount: [],
      curAccountIndex: 0,
      accountChangeFlag: false, // 账户是否改变
      search: '',
      customBrandName: '', // 上传图片品牌名称，
      cBrandNameLen: null,
      cBrandImage: null, // 上传图片地址
      brandInfo: null,
      brandList: [], // 品牌形象
      landingPageList: [],
      initLength: 0, // 创意名称初始长度
      loading: false,
      showUploadDialog: false,
      showBrandImage: false,
      brandTargetImage: null,
      brandDoc: null, // 品牌文案
      urlRegExp:
        /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
      EXCLUDE_IDS,
      actionList: [
        '查看详情',
        '了解更多',
        '立即购买',
        '立即预约',
        '立即咨询',
        '立即申请',
        '立即预定'
      ]
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {

      }
    },
    curAccountInfo: {
      get () {
        return this.copyAccount[this.curAccountIndex]
      },
      set () { }
    },
    // ! 创建一个合集 用于监听变化
    landingTypeList: {
      get () {
        return this.copyAccount.length === 0
          ? []
          : this.copyAccount.map((item) =>
            'landingType' in item ? item.landingType : false
          )
      },
      set () { }
    },
    selectStyle: {
      get () {
        return {
          width: this.curTypeIndex === 910 ? '850px' : '400px'
        }
      },
      set () { }
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          this.initData()
        } else {
          this.resetData()
        }
      },
      immediate: true
    },
    curAccountInfo: {
      handler (newV) {
        if (newV) {
          this.accountChangeFlag = true
        }
      },
      immediate: true
    },
    landingTypeList: {
      handler (newV, oldV) {
        const { projectConfig: { promoted_object_type } } = this.params
        // ! 只判断前后长度相等的情况
        // 当某个landType改变了 则需要pageSpec的内容
        if (newV.length > 0 && oldV.length > 0 && newV.length === oldV.length) {
          let Index = null
          for (let i = 0; i < newV.length; i++) {
            if (newV[i] !== oldV[i]) {
              Index = i
              this.$set(this.copyAccount, Index, {
                ...this.copyAccount[Index],
                // * 判断条件为 ：如果是激励视频（910）如果满足是“阅宝”不是自定义，还满足商品推广模式为"""
                pageSpec: this.curTypeIndex === 910
                  ? this.copyAccount[this.curAccountIndex].landingType === 'YueBao'
                    ? promoted_object_type.value === 'PROMOTED_OBJECT_TYPE_LEAD_AD'
                      ? []
                      : ['']
                    : ['']
                  : ''
              })
              break
            }
          }
        }
      }
    },
    curTypeIndex: {
      handler (newV) {
        this.brandInfo = null
        this.addIcon()
        // ! 如果是激励视频
        if (newV === 910) {
          _.cloneDeep(this.copyAccount).forEach((item, index) => {
            this.$set(this.copyAccount, index, {
              ...this.copyAccount[index],
              // ! 如果 “推广目标”是 商品推广 则初始化为 带有一个空字符的数组，否则为空数组
              pageSpec: this.params.projectConfig.promoted_object_type.value === 'PROMOTED_OBJECT_TYPE_LEAD_AD' && this.copyAccount[this.curAccountIndex].landingType === 'YueBao' ? [] : ['']
            })
          })
        } else {
          _.cloneDeep(this.copyAccount).forEach((item, index) => {
            this.$set(this.copyAccount, index, {
              ...this.copyAccount[index],
              pageSpec: ''
            })
          })
        }
      }
      // immediate: true
    },
    curAccountIndex: {
      handler (newV) {
        if (this.curTypeIndex === 910) {
          // ! 如果当前账号下的 落地页有值，则直接不进行操作
          if (this.copyAccount[newV].landingType === 'YueBao') {
            if (isValidArray(this.copyAccount[newV].pageSpec)) return
          }
          if (this.copyAccount[newV].landingType === 'CUSTOM') {
            // 只要某一项有值，就不进行操作
            if (isValidArray(this.copyAccount[newV].pageSpec) && this.copyAccount[newV].pageSpec.some(item => item)) return
          }

          this.$set(this.copyAccount, newV, {
            ...this.copyAccount[newV],
            pageSpec: this.copyAccount[newV].landingType === 'YueBao' && this.params.projectConfig.promoted_object_type.value === 'PROMOTED_OBJECT_TYPE_LEAD_AD' ? [] : ['']
          })
        }
      }
    }
  },
  methods: {
    initData () {
      this.copyAccount = JSON.parse(JSON.stringify(this.account)).map(
        (item) => ({ ...item, landingType: 'YueBao' })
      )
      this.curAccountIndex = 0
      this.getLandingPage()
      this.getAdqBrandList()
      if (this.params && this.params.creativityConfig) {
        this.echoData()
      }
    },
    addIcon () {
      this.$nextTick(() => {
        const typeItemList = [...document.querySelectorAll('.type-item')]
        const targetIcon = document.querySelector('.success-icon')
        const icon = document.createElement('i')
        icon.classList.add('el-icon-success', 'success-icon')
        icon.style =
          'position:absolute;font-size:16px;color:#00bf8a;top:2%;left:90%'
        typeItemList.forEach((dom) => {
          const dataId = +dom.getAttribute('data-id')
          if (dataId === this.curTypeIndex) {
            dom.append(icon)
          } else {
            if (dom.contains(targetIcon)) {
              dom.removeChild(targetIcon)
            }
          }
        })
      })
    },
    echoData () {
      const {
        adcreativeTemplateInfo,
        brandInfo,
        buttonText,
        page_spec,
        adcreative_name
      } = this.params.creativityConfig
      this.adcreative_name = adcreative_name.value
      if (brandInfo) this.brandInfo = brandInfo.value.brand_img
      if (buttonText) this.actionText = buttonText.value
      this.curTypeIndex = adcreativeTemplateInfo.value
      if (this.curTypeIndex === 722) {
        this.brandDoc = brandInfo.value.brand_description
        this.customBrandName = brandInfo.value.brand_name
      }
      const arr = JSON.parse(JSON.stringify(this.copyAccount))
      this.initLength = Math.ceil(
        this.calculateCharacter(this.adcreative_name) / 2
      )
      arr.forEach((item, index) => {
        const targetValue = page_spec.value.find(v => v.account_id === item.accountId)
        const pArr = targetValue ? Object.keys(targetValue) : null
        item.pageSpec = echoPageSpec(pArr, targetValue, this.params)
        item.landingType = pArr ? pArr.includes('page_id') ? 'YueBao' : 'CUSTOM' : 'CUSTOM'
      })

      this.copyAccount = arr

      function echoPageSpec (pArr, targetValue, params) {
        if (!pArr) return null
        const { creativityConfig } = params
        // ! 如果是激励模式
        const isStimulateStatus = creativityConfig && creativityConfig.adcreativeTemplateInfo && creativityConfig.adcreativeTemplateInfo.value === 910
        let value = pArr.includes('page_url') ? targetValue.page_url : targetValue.page_id
        if (isStimulateStatus && !Array.isArray(value)) {
          value = [value]
        }
        return value
      }
    },
    getAdqBrandList () {
      return new Promise((resolve) => {
        getAdqBrandList({
          page: 1,
          pageSize: 9999,
          search: this.search
        })
          .then((res) => {
            this.brandList = res
          })
          .finally(() => {
            resolve()
          })
      })
    },
    handleClose () {
      this.$emit('close')
    },
    handleConfirm () {
      if (!this.curTypeIndex) {
        return this.$message.warning('请选择创意模式!')
      }
      if (![910, 1529].includes(this.curTypeIndex) && !this.brandInfo) {
        const {
          adConfig: {
            bid_adjustment: {
              value: { site_set_package }
            }
          }
        } = this.params
        // *是否包含优量汇
        const isContainsUnion = !!site_set_package.find(
          (item) => item.site_set[0] === 'SITE_SET_MOBILE_UNION'
        )
        if (!(this.curTypeIndex === 712 && !isContainsUnion)) {
          return this.$message.warning('请选择品牌形象!')
        }
      }
      if (this.copyAccount.some((item) => !item.pageSpec)) {
        return this.$message.warning('请完善配置落地页素材!')
      }
      if (!this.adcreative_name) {
        return this.$message.warning('请输入创意名称!')
      }
      if (this.showError) {
        return this.$message.error('请调整创意名称的长度!')
      }
      if (this.curTypeIndex === 722) {
        if (!this.customBrandName) { return this.$message.error('请输入品牌名称!') }
        if (!this.brandDoc) return this.$message.error('请输入品牌文案!')
      }
      const needCheck = this.copyAccount.filter(
        (item) => typeof item.pageSpec === 'string'
      )
      if (needCheck.length !== 0) {
        if (needCheck.some((item) => !this.urlRegExp.test(item.pageSpec))) {
          return this.$message.error('请填写正确的落地页链接!')
        }
      }

      const creativeType = [
        {
          id: 721,
          text: '竖版视频9:16'
        },
        {
          id: 711,
          text: '横版大图16:9'
        },
        {
          id: 712,
          text: '竖版大图9:16'
        },
        {
          id: 718,
          text: '横版小三图3:2'
        },
        {
          id: 720,
          text: '横版视频16:9'
        },
        {
          id: 714,
          text: '横版小图3:2'
        },
        {
          id: 722,
          text: '贴片视频16:9'
        },
        {
          id: 910,
          text: '激励浏览广告'
        },
        {
          id: 1529,
          text: '闪屏视频9:16'
        }
      ]
      this.$emit('confirm', {
        ...this.structureObj(creativeType)
      })

      this.handleClose()
    },
    structureObj (creativeType) {
      const obj = {
        adcreativeTemplateInfo: {
          value: this.curTypeIndex,
          ChineseText: creativeType.find(
            (item) => item.id === this.curTypeIndex
          ).text,
          propertyName: '创意展现'
        },
        page_spec: {
          value: this.copyAccount.map((item) => ({
            account_id: item.accountId,
            [`${this.params.projectConfig.promoted_object_type.value ===
              'PROMOTED_OBJECT_TYPE_LEAD_AD'
              ? item.landingType === 'YueBao'
                ? 'page_id'
                : 'page_url'
              : 'page_url'
              }`]: item.pageSpec
          })),
          ChineseText: '全部配置',
          propertyName: '落地页素材'
        },
        adcreative_name: {
          value: this.adcreative_name,
          ChineseText: this.adcreative_name,
          propertyName: '创意名称'
        }
      }
      if (![910, 1529].includes(this.curTypeIndex)) {
        if (this.curTypeIndex === 722) {
          obj.brandInfo = {
            value: {
              brand_img: this.brandInfo,
              brand_name: this.customBrandName,
              brand_description: this.brandDoc
            },
            ChineseText: this.customBrandName,
            propertyName: '品牌形象'
          }
        } else if (this.curTypeIndex === 712) {
          const {
            adConfig: {
              bid_adjustment: {
                value: { site_set_package }
              }
            }
          } = this.params
          // *是否包含优量汇
          const isContainsUnion = !!site_set_package.find(
            (item) => item.site_set[0] === 'SITE_SET_MOBILE_UNION'
          )
          if (isContainsUnion) {
            obj.brandInfo = {
              value: {
                brand_img: this.brandInfo,
                brand_name: this.brandInfo.brandName
              },
              ChineseText: this.brandInfo.brandName,
              propertyName: '品牌形象'
            }
          }
        } else {
          obj.brandInfo = {
            value: {
              brand_img: this.brandInfo,
              brand_name: this.brandList.find(
                (item) => item.materialId === this.brandInfo
              ).brandName
            },
            ChineseText: this.brandList.find(
              (item) => item.materialId === this.brandInfo
            ).brandName,
            propertyName: '品牌形象'
          }
        }
      }
      if (this.checkStatusImg()) {
        obj.buttonText = {
          value: this.actionText,
          ChineseText: this.actionText,
          propertyName: '行动按钮'
        }
      }

      return obj
    },
    handleCancel () {
      this.$emit('close')
    },
    uploadFile (type = 'bandFile') {
      this.cBrandImage = null
      this.cBrandNameLen = null
      this.customBrandName = ''
      this.showUploadDialog = true
    },
    getLandingPage () {
      this.loading = true
      if (
        this.params.projectConfig.promoted_object_type.value ===
        'PROMOTED_OBJECT_TYPE_LEAD_AD'
      ) {
        getLandingPage({
          accountId: this.copyAccount[this.curAccountIndex].accountId,
          promotedObjectType:
            this.params.projectConfig.promoted_object_type.value
        })
          .then((res) => {
            this.landingPageList = res
            this.accountChangeFlag = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleAccountClick (item, index) {
      this.curAccountIndex = index
      this.getLandingPage()
    },
    delAdqBrand (id) {
      this.$confirm('是否要继续? 删除的内容将不可恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAdqBrand(id).then(() => {
            this.$message.success('删除成功!')
            this.getAdqBrandList()
          })
        })
        .catch(() => { })
    },
    handleVisibleChange (flag) {
      if (flag) {
        this.getLandingPage()
      }
    },
    handleOk (cb) {
      if (!this.cBrandImage) return (cb(), this.$message.warning('请上传图片!'))
      if (this.cBrandNameLen > 8) {
        return (cb(), this.$message.warning('品牌名称不能超过8个字符!'))
      }
      if (!this.customBrandName) {
        return (cb(), this.$message.warning('请输入品牌名称!'))
      }
      addMaterialWare([
        {
          materialType: 2,
          title: this.cBrandImage.name,
          materialResources: {
            title: this.cBrandImage.name,
            widthAndHigh: this.cBrandImage.widthAndHigh,
            format: this.cBrandImage.format,
            size: this.cBrandImage.size,
            url: this.cBrandImage.src,
            sort: 1
          }
        }
      ])
        .then(async (res) => {
          const obj = {
            material_id: res[0].materialId,
            brand_name: this.customBrandName
          }
          addAdqBrand(obj).then(async () => {
            this.$message.success('上传成功')
            await this.getAdqBrandList()
            this.brandInfo = this.brandList[0].materialId
            this.showUploadDialog = false
          })
        })
        .finally(() => {
          cb()
        })
    },
    handleReject () {
      this.showUploadDialog = false
    },
    getInputLen (val) {
      this.cBrandNameLen = val
    },
    handleUpload () {
      this.$refs.bandFile.value = null
      this.$refs.bandFile.click()
    },
    handleFileChange (type = 'bandFile', e) {
      return new Promise((resolve) => {
        const targetFile = e.target.files[0]

        let limitKB = 50
        if (type === 'brandImage') {
          limitKB = 400
        }
        imageStatusCheck(
          targetFile,
          limitKB,
          { width: 512, height: 512 },
          false
        ).then(({ flag, message, file }) => {
          if (!flag) {
            this.$message.error(`${message}`)
            this.$refs[type].value = null
          } else {
            this.uploadLoading = true
            uploadFile({ file })
              .then((res) => {
                const imageType = file.type.split(/\//g)
                this.cBrandImage = {
                  src: res,
                  name: file.name.replace(/\.\w+/g, ''),
                  url: res,
                  widthAndHigh: '512 * 512',
                  size: `${Math.floor(file.size / 1024)}KB`,
                  materialId: null,
                  format: imageType[imageType.length - 1]
                }
                // ! 如果是竖版大图，则需要上传到素材库才能展示图片
                if (type === 'brandImage') {
                  this.uploadLibrary()
                }
              })
              .finally(() => {
                this.uploadLoading = false
              })
          }
        })
      })
    },
    uploadLibrary () {
      addMaterialWare([
        {
          materialType: 2,
          title: this.cBrandImage.name,
          materialResources: {
            title: this.cBrandImage.name,
            widthAndHigh: this.cBrandImage.widthAndHigh,
            format: this.cBrandImage.format,
            size: this.cBrandImage.size,
            url: this.cBrandImage.src,
            sort: 1
          }
        }
      ]).then((res) => {
        this.brandInfo = res[0].resources[0]
      })
    },
    resetUpload () {
      this.handleUpload()
    },
    checkPageSpec (val) {
      if (this.curTypeIndex !== 910) {
        return !!val.pageSpec && typeof val.pageSpec === 'string'
          ? this.urlRegExp.test(val.pageSpec)
          : !!val.pageSpec
      } else {
        return isValidArray(val.pageSpec) && val.pageSpec.every(item => !!item)
      }
    },
    uploadBrand () {
      this.$refs.brandImage.click()
    },
    chooseLibrary () {
      this.showBrandImage = true
    },
    checkStatus () {
      const {
        adConfig: {
          positionList: { value }
        }
      } = this.params
      if (
        (value.includes('SITE_SET_MOBILE_UNION') &&
          this.curTypeIndex === 712) ||
        this.curTypeIndex === 722
      ) { return true }
      return false
    },
    checkStatusImg () {
      const {
        adConfig: {
          positionList: { value }
        }
      } = this.params
      if (
        (this.curTypeIndex === 712 &&
          !value.includes('SITE_SET_MOBILE_UNION')) ||
        [910, 1529].includes(this.curTypeIndex)
      ) {
        return false
      }
      return true
    },
    getBrandList () {
      return this.brandList.filter(({ size }) => {
        return parseInt(size) < IMAGE_LIMIT_SIZE
      })
    },
    confirm (val) {
      this.brandInfo = val
    },
    resetData () {
      this.brandDoc = null
      this.customBrandName = null
      this.brandInfo = null
    }
  },
  components: { LimitWord, BrandImageList }
}
</script>
<style lang='scss' scoped>
.config {
  height: 680px;
  overflow: auto;
  &-container {
    .config-part {
      @include flex-something;
      position: relative;
      > div {
        flex-grow: 1;
        position: relative;
        .type-list {
          width: 1100px;
          > div {
            @include flex(flex-start, center);
            border: 1px solid $borderColor;
            border-radius: 8px;
            padding: 16px;
            margin: 0 10px 10px 0;
            transition: all 0.15s;
            cursor: pointer;
            width: 200px;
            position: relative;
            > span {
              margin-left: 10px;
            }
            &:hover {
              border-color: $main;
              color: $main;
            }
          }
          .is-active {
            border-color: $main;
            color: $main;
            > span {
              font-weight: bold;
            }
          }
        }
      }
      .landing-page-container {
        max-height: 200px;
        .account-status {
          max-height: 190px;
          overflow: auto;
          width: 180px;
          border-radius: 8px;
          padding: 10px;
          font-size: 14px;
          border: 1px solid $borderColor;
          margin-right: 20px;
          > p {
            margin-bottom: 20px;
            top: 50%;
            left: 5%;
            font-size: 12px;
            border-radius: 8px;
            padding: 4px 8px;
            cursor: pointer;
            border: 1px solid transparent;
          }
          .is-active {
            color: $main;
            border-color: $main;
          }
        }
      }
    }
  }
}
.custom-option {
  padding: 10px;
  height: 60px;
}
.option-container {
  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 10px;
  }
  span {
    font-size: 12px;
  }
}
.upload-container {
  .brand-image {
    border-radius: 8px;
    background-color: #f5f7fa;
    height: 158px;
    border: 1px solid $borderColor;
    .loading-tips {
      cursor: pointer;
      > p {
        margin-bottom: 10px;
      }
    }
    .image-container {
      width: 252px;
      height: 142px;
      position: relative;
      img {
        width: 142px;
        height: 142px;
        object-fit: contain;
      }
      .tips {
        display: none;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.2s;
        cursor: pointer;
        .del-icon {
          position: absolute;
          top: -2%;
          left: 96%;
          color: $colorRed;
          background: #fff;
          border-radius: 50%;
          cursor: pointer;
        }
        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:hover {
        .tips {
          display: block;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .brand-title {
    margin-top: 30px;
  }

  .brand-name {
    margin-top: 15px;
    position: relative;
  }
}

.brand-target {
  > div:nth-of-type(1) {
    width: 600px;
    height: 160px;
    border-radius: 8px;
    border: 1px dashed $borderColor;
    background: #f3f5f7;
    .tips {
      text-align: center;
      cursor: pointer;
      > p {
        margin-bottom: 10px;
      }
    }
  }
  .image-info {
    width: 252px;
    height: 142px;
    position: relative;
    img {
      width: 142px;
      height: 142px;
      object-fit: contain;
    }
    .repeat-info {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    &:hover {
      .repeat-info {
        display: flex;
      }
    }
  }
  .creative-quick-toolbar {
    margin-top: 10px;
    cursor: pointer;
    &:hover {
      color: $main;
    }
  }
}
.b-part {
  position: relative;
  margin-top: 20px;
}
.page-spec-list {
  border:1px solid $borderColor;
  max-height:150px;
  overflow:auto;
  padding:10px;
  .page-spec-item {
    position: relative;
    &:not(:nth-last-of-type(1)){
      margin-bottom: 10px;
    }
  }
  .add-icon {
    margin-left: 5px;
    color:$main;
    cursor: pointer;
  }
}
</style>
