<template>
  <el-dialog
    title="设置基础信息"
    width="700px"
    append-to-body
    top="20px"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="config-base-info">
      <div class="config-base-info-container">
        <div class="choose-custom-rules part">
          <div class="choose-custom-rules-container">
            <div class="choose-custom-rules-part">
              <span>分配计划规则</span>
              <el-radio-group v-model="planRules">
                <el-radio-button label="average">各账户平均分配</el-radio-button>
                <el-radio-button label="repeat">各账户重复分配</el-radio-button>
              </el-radio-group>
            </div>
            <div class="choose-custom-rules-part">
              <span>计划创建规则</span>
              <el-radio-group v-model="createRules">
                <el-radio-button
                  label="resource"
                  :disabled="params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo.value === 910"
                >按素材</el-radio-button>
                <el-radio-button
                  label="doc"
                  :disabled="DONT_SHOW_IDS.includes(params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo.value)"
                >按文案</el-radio-button>
                <el-radio-button label="orienteering">按定向</el-radio-button>
              </el-radio-group>
            </div>
            <div class="choose-custom-rules-part">
              <span>计划类型</span>
              <el-radio-group>
                <el-radio-button>展示广告计划</el-radio-button>
              </el-radio-group>
            </div>
            <div class="choose-custom-rules-part">
              <span>推广目标</span>
              <el-radio-group v-model="promoted_object_type">
                <el-radio-button label="PROMOTED_OBJECT_TYPE_ECOMMERCE">商品推广</el-radio-button>
                <el-radio-button label="PROMOTED_OBJECT_TYPE_LEAD_AD">销售线索搜集</el-radio-button>
              </el-radio-group>
              <div
                style="width:250px;margin-top:10px;margin-left:5px;color:#fd5d5a"
              >修改推广目标会重置创意模块的内容!</div>
            </div>
          </div>
        </div>
        <div class="metas part">
          <div class="metas-container">
            <div>
              <span>投放方式</span>
              <el-radio-group v-model="speed_mode">
                <el-radio border label="SPEED_MODE_STANDARD">标准投放</el-radio>
                <el-radio border label="SPEED_MODE_FAST">加速投放</el-radio>
              </el-radio-group>
            </div>
            <div>
              <span>计划日预算</span>
              <div>
                <el-radio-group v-model="dailyBudType">
                  <el-radio border label="NONE">不限</el-radio>
                  <el-radio border label="APPOINT">指定日预算</el-radio>
                </el-radio-group>
                <div style="margin-top: 10px; width: 250px">
                  <c-input
                    v-if="dailyBudType === 'APPOINT'"
                    v-model="daily_budget"
                    placeholder="50 元 ~ 40,000,000 元"
                    :onlyNum="true"
                    :min="50"
                    :max="40000000"
                  ></c-input>
                </div>
              </div>
            </div>
            <div>
              <span>计划总预算</span>
              <div>
                <el-radio-group v-model="totalBudType">
                  <el-radio border label="NONE">不限</el-radio>
                  <el-radio border label="APPOINT">指定总预算</el-radio>
                </el-radio-group>
                <div style="margin-top: 10px; width: 250px">
                  <c-input
                    v-if="totalBudType === 'APPOINT'"
                    v-model="total_budget"
                    placeholder="50 元 ~ 40,000,000 元"
                    :onlyNum="true"
                    :min="50"
                    :max="40000000"
                  ></c-input>
                </div>
              </div>
            </div>
            <div>
              <span>推广计划名称</span>
              <div>
                <el-input style="width: 300px" v-model="campaign_name" placeholder="推广计划名称!"></el-input>
                <p
                  style="font-size: 12px; color: #fa8e00; margin-top: 5px"
                >系统将自动为每个推广计划名称加上后缀，建议名称不超过50字</p>
              </div>
              <limit-word
                :calculationRules="calculateCharacter"
                :curValue="campaign_name"
                :initLength="initLength"
                @error="showError = true"
                @change="showError = false"
                :isDivideByTwo="true"
                :maxLength="50"
              ></limit-word>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-list">
      <el-button type="primary" @click="handleConfirm">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LimitWord from '@/components/limit-word'
import { calculateCharacter } from '@/assets/js/utils'
import { transformText } from './EN_ZH.js'
const DONT_SHOW_IDS = [910, 722]
export default {
  name: 'ConfigBaseInfo',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      planRules: 'average',
      campaign_name: '',
      createRules: 'resource',
      showError: false,
      promoted_object_type: 'PROMOTED_OBJECT_TYPE_ECOMMERCE',
      calculateCharacter,
      dailyBudType: 'NONE',
      daily_budget: null,
      totalBudType: 'NONE',
      total_budget: '',
      speed_mode: 'SPEED_MODE_STANDARD',
      initLength: 0,
      copyParams: {},
      DONT_SHOW_IDS
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === 'ConfigBaseInfo' && this.showInfo.value
      },
      set () {

      }
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (newV.name === 'ConfigBaseInfo' && newV.value) {
          this.initData()
          this.initWatcher()
        }
      },
      immediate: true
    },
    params: {
      handler (newV) {
        if (newV && newV.projectConfig) {
          this.copyParams = JSON.parse(JSON.stringify(newV))
        }
      },
      immediate: true,
      deep: true
    },
    promoted_object_type: {
      handler (newV, oldV) {
        this.$emit('changePromotedType')
      }
    }
  },
  methods: {
    initData () {
      if (this.params && this.params.projectConfig) {
        this.copyParams = JSON.parse(JSON.stringify(this.params))
        const {
          campaign_name,
          createRules,
          daily_budget,
          planRules,
          promoted_object_type,
          speed_mode,
          total_budget
        } = this.copyParams.projectConfig
        this.planRules = planRules.value
        this.createRules = createRules.value
        this.promoted_object_type = promoted_object_type.value
        this.speed_mode = speed_mode.value
        this.dailyBudType = daily_budget.value ? 'APPOINT' : 'NONE'
        this.daily_budget = daily_budget.value ? daily_budget.value : null
        this.totalBudType = total_budget.value ? 'APPOINT' : 'NONE'
        this.total_budget = total_budget.value ? total_budget.value : null
        this.campaign_name = campaign_name.value
        if (this.campaign_name) {
          this.initLength = Math.ceil(
            this.calculateCharacter(this.campaign_name) / 2
          )
        }
      }
    },
    initWatcher () {
      this.$watch('dailyBudType', (newV) => {
        if (newV === 'NONE') {
          this.daily_budget = null
        }
      })
      this.$watch('totalBudType', (newV) => {
        if (newV === 'NONE') {
          this.total_budget = null
        }
      })
    },
    handleClose () {
      this.$emit('close')
    },
    handleConfirm () {
      if (!this.campaign_name) {
        return this.$message.warning('请输入计划名称!')
      }
      if (Math.ceil(this.calculateCharacter(this.campaign_name) / 2) > 50) {
        return this.$message.warning('请调整计划名称的长度！')
      }
      this.$emit('confirm', {
        planRules: {
          value: this.planRules,
          ChineseText: transformText[this.planRules],
          propertyName: '分配计划规则'
        },
        createRules: {
          value: this.createRules,
          ChineseText: transformText[this.createRules],
          propertyName: '计划创建规则'
        },
        promoted_object_type: {
          value: this.promoted_object_type,
          ChineseText: transformText[this.promoted_object_type],
          propertyName: '推广目标'
        },
        speed_mode: {
          value: this.speed_mode,
          ChineseText: transformText[this.speed_mode],
          propertyName: '投放方式'
        },
        daily_budget: {
          value: this.daily_budget,
          ChineseText:
            this.dailyBudType === 'NONE' ? '不限' : `${this.daily_budget}元`,
          propertyName: '计划日预算'
        },
        total_budget: {
          value: this.total_budget,
          ChineseText:
            this.totalBudType === 'NONE' ? '不限' : `${this.total_budget}元`,
          propertyName: '计划总预算'
        },
        campaign_name: {
          value: this.campaign_name,
          ChineseText: this.campaign_name,
          propertyName: '计划名称'
        }
      })
      this.handleCancel()
    },
    checkStatus () {
      return this.params.creativityConfig ? this.params.creativityConfig.adcreativeTemplateInfo.value === 910 : false
    },
    handleCancel () {
      this.$emit('close')
    }
  },
  components: {
    LimitWord
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-drawer__header {
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #edeff2;
  background: #f9fafc;
}
/deep/ .el-radio {
  margin-right: 0;
}
/deep/ .is-bordered {
  margin-left: 0 !important;
}
.button-list {
  padding: 20px;
  border-bottom: 1px solid #edeff2;
}
.config-base-info {
  &-container {
    .part {
      border-bottom: 1px solid #edeff2;
    }
    .choose-custom-rules {
      &-container {
        padding: 20px;
        .choose-custom-rules-part {
          @include flex-something;
        }
      }
    }
    .metas {
      &-container {
        padding: 20px;
        > div {
          @include flex-something;
          position: relative;
          /deep/ .limit-word {
            position: absolute;
            top: 10px;
            left: 70%;
          }
        }
      }
    }
  }
}
</style>
