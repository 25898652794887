var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"width":"1200px","top":"20px","append-to-body":"","visible":_vm.show,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.handleClose}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("添加素材"+_vm._s(((_vm.resourceList.length) + "组素材")))]),_c('div',{staticClass:"config-resource"},[_c('div',{staticClass:"config-resource-container"},[(_vm.creativeType === 721)?_c('div',[_c('vertical-video',{ref:"verticalVideo",attrs:{"widthAndHigh":{ width: 720, height: 1280 },"previewData":_vm.previewData},on:{"confirm":function (data) {
              _vm.confirm(data, 721);
            },"uploadData":_vm.handleUploadData}})],1):_vm._e(),(_vm.creativeType === 711)?_c('div',[_c('horizontal-image',{ref:"horizontalImage",attrs:{"previewData":_vm.previewData},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 711);
            }}})],1):_vm._e(),(_vm.creativeType === 720)?_c('div',[_c('vertical-video',{ref:"verticalVideo",attrs:{"previewData":_vm.previewData,"widthAndHigh":{ width: 1280, height: 720 }},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 720);
            }}})],1):_vm._e(),(_vm.creativeType === 718)?_c('div',[_c('config-three-pic',{ref:"configThreePic",attrs:{"previewData":_vm.previewData},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 718);
            }}})],1):_vm._e(),(_vm.creativeType === 712)?_c('div',[_c('vertical-pic',{ref:"verticalPic",attrs:{"params":_vm.params,"previewData":_vm.previewData},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 712);
            }}})],1):_vm._e(),(_vm.creativeType === 714)?_c('div',[_c('horizontal-image',{ref:"verticalImage",attrs:{"limitSize":100,"widthAndHigh":{ width: 480, height: 320 },"previewData":_vm.previewData},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 714);
            }}})],1):_vm._e(),(_vm.creativeType === 722)?_c('div',[_c('horizontal-image',{ref:"wallpaperVideo",attrs:{"mediaType":"video","widthAndHigh":{ width: 1280, height: 720 },"limitSize":1024 * 1024 * 100,"previewData":_vm.previewData},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 722);
            }}})],1):_vm._e(),(_vm.creativeType === 1529)?_c('div',[_c('vertical-video',{ref:"flashVideo",attrs:{"durationInfo":{ minTime: 5, maxTime: 5 },"limitSize":{ imageSize: '140kb', videoSize: '200mb' },"previewData":_vm.previewData,"widthAndHigh":{ width: 1080, height: 1920 }},on:{"uploadData":_vm.handleUploadData,"confirm":function (data) {
              _vm.confirm(data, 1529);
            }}})],1):_vm._e()])]),_c('div',{staticClass:"button-list",staticStyle:{"text-align":"left"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.clearResource}},[_vm._v("清空素材组")]),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("确定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }