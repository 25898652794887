<template>
  <div class="vertical-video">
    <div class="vertical-video-container">
      <div class="metas">
        <div class="meta">
          <div style="width: 120px; text-align: right; margin-right: 50px">
            视频封面成组
          </div>
          <el-switch v-model="isVideoGroup" disabled></el-switch>
        </div>
      </div>
      <div class="control-twins-item is-group" ref="controlTwins">
        <div style="width: 130px">视频与封面</div>
        <div class="control-twins-item-container">
          <div
            v-for="(item, index) in resourceList"
            class="creative-item flex-row-start-start"
            :key="item.id"
          >
            <div
              class="list-item flex-center-center"
              @mouseenter="handleMouseEnter('tipsIndexVideo', item)"
              @mouseleave="handleMouseLeave('tipsIndexVideo')"
            >
              <input
                type="file"
                @change="
                  (e) => {
                    handleVideoChange(e, index);
                  }
                "
                :ref="`inputVideo`"
                style="display: none"
              />
              <div
                class="meta-item"
                v-if="item.video.src && !item.video.loading"
              >
                <video controls muted :src="item.video.src"></video>
                <i
                  class="el-icon-error"
                  v-if="item.video.src"
                  @click.stop="item.video = {}"
                ></i>
              </div>
              <div
                class="tips"
                v-else-if="!item.video.src && !item.video.loading"
                @click="handleUpload('video', index)"
              >
                <p>
                  (推荐尺寸:{{ widthAndHigh.width }} *
                  {{ widthAndHigh.height }})
                </p>
                <p>点击上传</p>
                <p>{{ getRate() }}</p>
                <template v-if="tipsIndexVideo === item.id">
                  <p class="divide-line"></p>
                  <p style="font-size: 12px">MP4/MOV/AVI</p>
                  <p style="font-size: 12px">
                    {{ limitSize.videoSize }}，时长{{
                      `${
                        durationInfo.minTime === durationInfo.maxTime
                          ? `${durationInfo.maxTime}s`
                          : `${durationInfo.minTime}s -- ${durationInfo.maxTime}s`
                      }`
                    }}，必须带有声音
                  </p>
                </template>
              </div>
              <div
                class="loading-tips flex-center-center"
                v-if="item.video.loading"
              >
                <p>
                  <i class="el-icon-loading"></i>
                  <span style="margin-left: 5px">上传中</span>
                </p>
              </div>
            </div>
            <div
              class="list-item flex-center-center"
              @mouseenter="handleMouseEnter('tipsIndex', item)"
              @mouseleave="handleMouseLeave('tipsIndex')"
            >
              <input
                type="file"
                @change="
                  (e) => {
                    handleImageChange(e, index);
                  }
                "
                :ref="`inputImage`"
                style="display: none"
              />
              <div
                class="meta-item"
                v-if="item.image.src && !item.image.loading"
              >
                <img :src="item.image.src" alt="" />
                <div class="reset-upload-hover flex-center-center" @click='chooseSingleCover(item,index)'>
                  <p style='color:#fff;font-weight:bold'>重新选择</p>
                </div>
                <i
                  class="el-icon-error"
                  v-if="item.image.src"
                  @click.stop="item.image = {}"
                ></i>
              </div>
              <div
                class="loading-tips flex-center-center"
                v-if="item.image.loading"
              >
                <p>
                  <i class="el-icon-loading"></i>
                  <span style="margin-left: 5px">上传中</span>
                </p>
              </div>
              <div
                class="tips"
                v-else-if="!item.image.src && !item.image.loading"
                @click="chooseSingleCover(item, index)"
              >
               <div style="cursor: pointer">
                  <p>封面图</p>
                <p>{{ widthAndHigh.width }}px * {{ widthAndHigh.height }}px</p>
               </div>
                <template v-if="tipsIndex === item.id"> </template>
              </div>
            </div>
            <div
              class="operation-delete"
              @click="resourceList.splice(index, 1)"
              v-if="resourceList.length > 1"
            >
              <i class="el-icon-minus"> </i>
            </div>
          </div>
          <div
            class="add-resource-item"
            @click="addContent"
            v-if="resourceList.length < 20"
          >
            <i
              class="el-icon-plus"
              style="font-weight: bold; margin-right: 5px"
            ></i>
            <span>还可以添加{{ LIMIT_NUMBER - resourceList.length }}组</span>
          </div>
          <div
            class="library-choose"
            v-if="Array.isArray(resourceList) && resourceList.length > 0"
          >
            <p @click="chooseLibrary('video')">
              <span
                ><i
                  class="el-icon-video-camera-solid"
                  style="margin-right: 4px"
                ></i
                >从素材库中心选择(视频)</span
              >
            </p>
            <p @click="chooseCover('image')">
              <span
                ><i class="el-icon-picture" style="margin-right: 4px"></i
                >选择封面图(图片)</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCancel"
    >
      <media-library
        :show="showMediaDialog"
        :mediaType="mediaType"
        ref="mediaLibrary"
      >
        <div slot="footer" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="
              () => {
                handleOk();
              }
            "
            >确定</el-button
          >
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
    <choose-cover-img
      @close="showCoverImg = false"
      :show="showCoverImg"
      :videoList="filterVideoList()"
      @chooseCoverSucc="chooseCoverSucc"
    ></choose-cover-img>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { uploadFile } from '@/api/common'
import {
  imageStatusCheck,
  videoStatusCheck,
  _changeTimeBySecond,
  gcd
} from '@/assets/js/utils'
import { addMaterialWare } from '@/api/material'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import ChooseCoverImg from './choose-cover-img'
const LIMIT_NUMBER = 20
const VIDEO_TYPE = ['video/mp4', 'video/avi', 'video/quicktime']
// const IMAGE_SIZE_LIMIT = 140 // 140kb
// const VIDEO_SIZE_LIMIT = 100 // 100mb
export default {
  props: {
    size: {
      type: String,
      default: 'vertical'
    },
    previewData: {
      type: Array,
      default: null
    },
    widthAndHigh: {
      type: Object,
      default: () => ({
        width: 720,
        height: 1280
      })
    },
    limitSize: {
      type: Object,
      default: () => ({
        imageSize: '140kb',
        videoSize: '100mb'
      })
    },
    durationInfo: {
      type: Object,
      default: () => ({
        minTime: 5,
        maxTime: 60
      })
    }
  },
  data () {
    return {
      LIMIT_NUMBER,
      tipsIndex: null,
      isVideoGroup: true,
      mediaType: '',
      tipsIndexVideo: null,
      showMediaDialog: false,
      showCoverImg: false,
      curIndex: null,
      resourceList: [
        {
          id: nanoid(),
          image: {},
          video: {}
        }
      ],
      gcd
    }
  },
  watch: {
    resourceList: {
      handler (newV) {
        this.$emit('uploadData', newV)
      },
      immediate: true,
      deep: true
    },
    previewData: {
      handler (newV) {
        if (newV) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleMouseEnter (type, val) {
      if (type === 'tipsIndex') {
        if (val.image.src) return
      }
      if (type === 'tipsIndexVideo') {
        if (val.video.src) return
      }
      this[type] = val.id
    },
    handleMouseLeave (type) {
      this[type] = null
    },
    addContent () {
      this.resourceList.push({
        image: {},
        video: {},
        id: nanoid()
      })
    },
    echoData () {
      this.resourceList = JSON.parse(JSON.stringify(this.previewData))
    },
    async handleImageChange (e, index) {
      try {
        const targetFile = e.target.files[0]
        const {
          flag,
          message,
          file: compressFile
        } = await imageStatusCheck(
          targetFile,
          +this.limitSize.imageSize.replace(/[a-zA-Z]/g, ''),
          this.widthAndHigh
        )
        if (!flag) {
          this.$message.error(`${message}`)
          this.$refs.inputImage[index].value = ''
          return
        }
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          image: {
            loading: true,
            name: null,
            src: null
          }
        })
        const res = await uploadFile({ file: compressFile })
        const type = compressFile.type.split(/\//g)
        this.$set(this.resourceList, index, {
          id: this.resourceList[index].id,
          video: this.resourceList[index].video,
          image: {
            src: res,
            name: compressFile.name.replace(/\.\w+/g, ''),
            loading: false,
            title: compressFile.name.replace(/\.\w+/g, ''),
            url: res,
            widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
            size: `${Math.floor(compressFile.size / 1024)}KB`,
            materialId: null,
            format: type[type.length - 1]
          }
        })
        this.$refs.inputImage[index].value = ''
      } catch (e) {
        this.$message.error('上传失败,请稍后再试!')
        this.$refs.inputImage[index].value = ''
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          image: {
            loading: false
          }
        })
      }
    },
    async handleVideoChange (e, index) {
      const targetFile = e.target.files[0]
      const { type } = targetFile
      if (!VIDEO_TYPE.includes(type)) {
        return this.$message.error('上传的视频格式不符合要求!')
      }
      const { minTime, maxTime } = this.durationInfo
      const { file, message, duration } = await videoStatusCheck(
        targetFile,
        +this.limitSize.videoSize.replace(/[a-zA-Z]/g, ''),
        {
          minTime: +minTime,
          maxTime: +maxTime
        },
        this.widthAndHigh
      )
      if (!file) {
        this.$refs.inputVideo[index].value = ''
        return this.$message.warning(`${message}`)
      }
      if (file) {
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          video: {
            src: null,
            loading: true
          }
        })
        uploadFile({ file })
          .then((res) => {
            let videoSize
            if (file.size / 1024 / 1024 < 1) {
              videoSize = `${Math.floor(file.size / 1024)}KB`
            } else {
              videoSize = `${Math.floor(file.size / 1024 / 1024)}MB`
            }
            const type = file.type.split(/\//g)
            this.$set(this.resourceList, index, {
              ...this.resourceList[index],
              video: {
                src: res,
                loading: false,
                name: targetFile.name.replace(/\.\w+/g, ''),
                url: res,
                widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
                videoTime: _changeTimeBySecond(Number(duration)),
                size: videoSize,
                format: type[type.length - 1],
                materialId: null,
                sort: 1,
                title: targetFile.name.replace(/\.\w+/g, '')
              }
            })
          })
          .finally(() => {
            this.$refs.inputVideo[index].value = ''
            this.resourceList[index].video.loading = false
          })
      }
    },
    handleUpload (type, index) {
      if (type === 'image') {
        this.$refs.inputImage[index].click()
      }
      if (type === 'video') {
        this.$refs.inputVideo[index].click()
      }
    },
    clearResource () {
      this.$confirm('确定清空吗？清空的数据无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resourceList = [
            {
              id: nanoid(),
              image: {},
              video: {}
            }
          ]
        })
        .catch(() => {})
    },
    handleOk (data = null) {
      const emptyList = this.getEmptyList()
      if (emptyList.length === 0) {
        this.$message.warning('没有剩余空间可以分配所选素材!')
        this.showMediaDialog = false
        return
      }
      let { imageList } = this.$refs.mediaLibrary
      const len = this.resourceList.length

      const flag = imageList.every(
        this.mediaType === 'video' ? this.checkVideo : this.checkImage
      )
      if (!flag) { return this.$message.error('所选素材至少有一项不符合格式要求！') }
      imageList = imageList.slice(0, emptyList.length)
      if (this.mediaType === 'image') {
        imageList.forEach((item, index) => {
          if (index + 1 <= len) {
            if (this.checkImage(item)) {
              this.$set(this.resourceList, emptyList[index], {
                ...this.resourceList[emptyList[index]],
                image: {
                  src: item.src,
                  loading: false,
                  name: item.resources[0].title.replace(/\.\w+/g, ''),
                  materialId: item.id,
                  url: item.url,
                  title: item.resources[0].title.replace(/\.\w+/g, ''),
                  widthAndHigh: item.resources[0].widthAndHigh,
                  size: item.resources[0].size
                }
              })
            }
          }
        })
      }
      if (this.mediaType === 'video') {
        imageList.forEach((item, index) => {
          if (index + 1 <= len) {
            if (this.checkVideo(item)) {
              this.$set(this.resourceList, emptyList[index], {
                ...this.resourceList[emptyList[index]],
                video: {
                  src: item.src,
                  loading: false,
                  name: item.resources[0].title.replace(/\.\w+/g, ''),
                  materialId: item.id,
                  url: item.src,
                  title: item.resources[0].title.replace(/\.\w+/g, ''),
                  widthAndHigh: item.resources[0].widthAndHigh,
                  size: item.resources[0].size,
                  videoTime: item.resources[0].videoTime
                }
              })
            }
          }
        })
      }
      this.showMediaDialog = false
    },
    getEmptyList () {
      return this.resourceList.reduce((acc, b, c) => {
        if (!b.video || Object.keys(b.video).length === 0) acc = [...acc, c]
        return acc
      }, [])
    },
    async handleConfirm () {
      if (
        this.resourceList.some((item) => !item.image.src || !item.video.src)
      ) {
        this.$message.warning('请检查素材配置！')
        return false
      }
      const needUploadList = this.resourceList.filter(
        (item) => !item.image.materialId || !item.video.materialId
      )
      if (needUploadList.length === 0) {
        this.$emit('confirm', this.resourceList)
        return true
      }
      let videoList = []
      for (const val of needUploadList) {
        const { video } = val
        if (!video.materialId) {
          videoList = [
            ...videoList,
            {
              materialType: 1,
              title: video.title,
              materialResources: [
                {
                  title: video.title,
                  widthAndHigh: video.widthAndHigh,
                  format: video.format,
                  size: video.size,
                  url: video.url,
                  videoTime: video.videoTime,
                  sort: 1
                }
              ]
            }
          ]
        }
        if (videoList.length !== 0) {
          const res = await Promise.all([addMaterialWare(videoList)])
          for (const val of res[0]) {
            const targetIndex = this.resourceList.findIndex(
              (item) => item.video.src === val.resources[0].url
            )
            if (targetIndex > -1) {
              this.$set(this.resourceList, targetIndex, {
                ...this.resourceList[targetIndex],
                video: {
                  materialId: val.materialId,
                  src: val.resources[0].url,
                  url: val.resources[0].url,
                  loading: false,
                  name: val.resources[0].title,
                  title: val.resources[0].title,
                  widthAndHigh: val.resources[0].widthAndHigh,
                  size: val.resources[0].size,
                  videoTime: val.resources[0].videoTime
                }
              })
            }
          }
        }
      }
      this.$emit('confirm', this.resourceList)
      return true
    },
    handleCancel () {
      this.showMediaDialog = false
    },
    checkImage (file) {
      const { resources } = file
      const imageSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+imageSize > +this.limitSize.imageSize.replace(/[a-zA-Z]/g, '')) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },
    checkVideo (file) {
      const { resources } = file
      const videoSize = resources[0].size.replace(/[^\d+]/g, '')
      if (
        +videoSize / 1024 >
        +this.limitSize.videoSize.replace(/[a-zA-Z]/g, '')
      ) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },
    getRate () {
      const { width, height } = this.widthAndHigh
      const cd = this.gcd(+width, +height)
      return `${width / cd} : ${height / cd}`
    },
    chooseLibrary (type) {
      this.mediaType = type
      this.showMediaDialog = true
    },
    chooseCover () {
      this.curIndex = null
      this.showCoverImg = true
    },
    chooseSingleCover (val, index) {
      if (!val.video || Object.keys(val.video).length === 0) return
      this.curIndex = index
      this.showCoverImg = true
    },
    filterVideoList () {
      const list = this.resourceList
        .filter((item) => item.video && Object.keys(item.video).length > 0)
        .map((item) => item.video)
      return this.curIndex === null ? list : [list[this.curIndex]]
    },
    chooseCoverSucc (select) {
      this.curIndex ? this.$set(this.resourceList, this.curIndex, {
        ...this.resourceList[this.curIndex],
        image: { loading: false, src: select[0].src, title: '封面图', name: '封面图' }
      }) : select.forEach((item, index) => {
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          image: {
            loading: false,
            src: item.src,
            title: '封面图',
            name: '封面图'
          }
        })
      })
    }
  },
  components: {
    MediaLibrary,
    ChooseCoverImg
  }
}
</script>
<style lang='scss' scoped>
.divide-line {
  width: 100%;
  border-top: 1px solid $borderColor;
  margin: 15px 0;
}
.vertical-video {
  &-container {
    .metas {
      .meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .control-twins-item {
      margin-top: 50px;
      border: 1px solid $borderColor;
      border-radius: 8px;
      padding: 40px;
      .per-part {
        margin-bottom: 40px;
        &-list {
          width: 90%;
        }
      }
      .list-item {
        width: 298px;
        height: 158px;
        background-color: #f5f6f8;
        border: 1px solid $borderColor;
        // cursor: pointer;
        border-radius: 4px;
        margin-right: -1px;
        font-size: 14px;
        text-align: center;
        color: #b0b4bd;
        position: relative;
        .tips {
          width: 80%;
        }
        .loading-tips {
          width: 182px;
          height: 102px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
        }
        .el-icon-close {
          position: absolute;
          top: 5%;
          left: 90%;
        }
        .el-icon-plus {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 22px;
        }
      }
      .operation-delete {
        align-self: center;
        margin-left: 20px;
        border-radius: 8px;
        background-color: #f5f6f8;
        border: 1px solid $borderColor;
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        width: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
    .is-group {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .creative-item {
        margin-bottom: 40px;
      }
    }
  }
  .add-resource-item {
    width: 594px;
    // margin-top: 40px;
    height: 36px;
    line-height: 36px;
    border: 1px dashed rgb(223, 225, 230);
    border-radius: 8px;
    background-color: #f5f6f8;
    text-align: center;
    cursor: pointer;
  }
  .library-choose {
    margin-top: 20px;
    > p {
      cursor: pointer;
      padding: 8px 0;
      transition: all 0.3s;
      &:hover {
        color: $main;
      }
    }
  }
  .meta-item {
    position: relative;
    width: 182px;
    height: 102px;
    img,
    video {
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: contain;
    }
    i {
      position: absolute;
      left: 95%;
      top: -5%;
      color: $colorRed;
      z-index: 999;
      font-size: 16px;
      opacity: 0;
      background: $main-background;
      border-radius: 50%;
    }
    .reset-upload-hover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.25s;
      background-color: rgba(0, 0, 0, 0.4confirm);
      opacity: 0;
      cursor: pointer;
    }
    &:hover {
      .reset-upload-hover,
      i {
        opacity: 1;
      }
    }
  }
}
</style>
