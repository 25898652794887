<template>
  <el-dialog
    title="定向配置"
    width="1000px"
    top="20px"
    append-to-body
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="orient-container">
      <dy-table
        height="500px"
        ref="mainTable"
        refName="subTable"
        :page="page"
        :total="total"
        :rowList="rowList"
        :pageSize="pageSize"
        :dataList="dataList"
        :showPagination="true"
        :loading="tableLoading"
        :isUseCheckbox="true"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
        @handleRowClick="handleRowClick"
      >
        <template #filter>
          <el-button @click="initData" style="margin-right: 10px"
            >刷新数据</el-button
          >
          <el-input
            v-model="search"
            placeholder="请输入信息"
            style="width: 300px; margin-right: 10px"
            clearable
          >
            <el-button
              slot="append"
              @click="pageChange(1)"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-button @click="addOrientation" type="primary">新增定向</el-button>
        </template>
        <template #createdTime="{ row }">
          <span v-if="row.createdTime">{{
            row.createdTime | customFormaTime
          }}</span>
        </template>
        <template #operation="{ row }">
          <c-button
            @click="
              (cb) => {
                handleEdit(row, cb);
              }
            "
            type="primary"
            class="button-small"
            >编辑</c-button
          >
          <c-button
            :autoConfirm="true"
            @confirm="
              (cb) => {
                handleDelete(row.id, cb);
              }
            "
            type="danger"
            class="button-small"
            >删除</c-button
          >
        </template>
      </dy-table>
    </div>
    <div slot="footer" class="button-list" style="text-align: left">
      <el-button type="primary" @click="handleOk">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
    <el-dialog
      title="新增定向"
      append-to-body
      width="1000px"
      top="20px"
      :visible.sync="showDialog"
      :before-close="
        () => {
          showDialog = false;
        }
      "
    >
      <div class="add-content">
        <div class="per-part">
          <span>地理位置</span>
          <div class="region">
            <el-radio-group v-model="curParams.geo_location.type">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="CUSTOM">按区域</el-radio-button>
            </el-radio-group>
            <div
              class="region-container"
              v-show="curParams.geo_location.type === 'CUSTOM'"
            >
              <!-- 单独提取element级联选择器的选择框组件 -->
              <div
                class="already-choose-region"
                v-if="chooseRegionList.length > 0"
              >
                <div style="margin: 10px 0">
                  <span>已选列表{{ `（${chooseRegionList.length}个）` }}</span>
                  <span
                    style="color: #fd5d5a; margin-left: 20px; cursor: pointer"
                    @click="clearRegion"
                    >清空</span
                  >
                </div>
                <div class="flex-row-start-start-wrap region-list">
                  <p
                    v-for="(region, index) in chooseRegionList"
                    :key="region.value"
                    class="flex-center-center"
                  >
                    <span>{{ region.label }}</span>
                    <i
                      class="el-icon-close del-icon"
                      @click="delRegionItem(region, index)"
                    ></i>
                  </p>
                </div>
              </div>

              <template v-if="showCascaDerPanel">
                <div class="import-region">
                  <span @click="batchImportRegion"
                    ><i class="el-icon-office-building"></i> 批量导入地域</span
                  >
                  <input
                    type="file"
                    ref="importRegion"
                    style="display: none"
                    @change="handleImportRegionChange"
                  />
                </div>
                <el-cascader-panel
                  ref="cascaderPanel"
                  :options="regionList"
                  :props="{ label: 'name', value: 'id', multiple: true }"
                  @change="handleNodeChange"
                  v-model="selectedRegionList"
                ></el-cascader-panel>
                <div class="location-types" style="margin-top: 8px">
                  <el-checkbox-group
                    v-model="curParams.geo_location.location_types"
                  >
                    <el-checkbox label="RECENTLY_IN">近期到访</el-checkbox>
                    <el-checkbox label="LIVE_IN">常住地</el-checkbox>
                    <el-checkbox label="TRAVEL_IN">旅行到访</el-checkbox>
                    <el-checkbox label="VISITED_IN">曾经到访</el-checkbox>
                  </el-checkbox-group>
                </div>
              </template>

              <div v-else class="loading-icon">
                <div>
                  <i class="el-icon-loading"></i>
                  <p style="font-size: 12px">数据加载中.....</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="per-part">
          <span>年龄</span>
          <div>
            <el-radio-group v-model="curParams.age.type">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="14-18">14~18岁</el-radio-button>
              <el-radio-button label="19-24">19~24岁</el-radio-button>
              <el-radio-button label="25-29">25~29岁</el-radio-button>
              <el-radio-button label="30-39">30~39岁</el-radio-button>
              <el-radio-button label="40-49">40~49岁</el-radio-button>
              <el-radio-button label="50-50">50岁及以上</el-radio-button>
              <el-radio-button label="CUSTOM">自定义</el-radio-button>
            </el-radio-group>
            <div
              style="margin-top: 10px"
              v-if="curParams.age.type === 'CUSTOM'"
            >
              <el-select
                style="width: 120px"
                filterable
                clearable
                v-model="curParams.age.min"
                placeholder="最小年龄"
              >
                <el-option
                  v-for="item in ageList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="
                    curParams.age.max ? +item.value > +curParams.age.max : false
                  "
                ></el-option>
              </el-select>
              <span class="control-group-separator"></span>
              <el-select
                style="width: 120px"
                filterable
                clearable
                v-model="curParams.age.max"
                placeholder="最大年龄"
              >
                <el-option
                  v-for="item in ageList.slice(1)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="+item.value < +curParams.age.min"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="per-part">
          <span>性别</span>
          <el-radio-group v-model="curParams.gender">
            <el-radio-button label="NONE">不限</el-radio-button>
            <el-radio-button label="MALE">男</el-radio-button>
            <el-radio-button label="FEMALE">女</el-radio-button>
          </el-radio-group>
        </div>
        <div class="per-part">
          <span>学历</span>
          <el-checkbox-group
            @change="
              (val) => {
                handleCheckout(val, curParams.education);
              }
            "
            v-model="curParams.education"
          >
            <el-checkbox-button label="NONE">不限</el-checkbox-button>
            <el-checkbox-button label="DOCTOR">博士</el-checkbox-button>
            <el-checkbox-button label="MASTER">硕士</el-checkbox-button>
            <el-checkbox-button label="BACHELOR">本科</el-checkbox-button>
            <el-checkbox-button label="SENIOR">高中</el-checkbox-button>
            <el-checkbox-button label="JUNIOR">初中</el-checkbox-button>
            <el-checkbox-button label="PRIMARY">小学</el-checkbox-button>
            <el-checkbox-button label="JUNIOR_COLLEGE">专科</el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="per-part">
          <span>联网方式</span>
          <el-checkbox-group
            @change="
              (val) => {
                handleCheckout(val, curParams.network_type);
              }
            "
            v-model="curParams.network_type"
          >
            <el-checkbox-button label="NONE">不限</el-checkbox-button>
            <el-checkbox-button label="WIFI">WiFi</el-checkbox-button>
            <el-checkbox-button label="NET_4G">4G</el-checkbox-button>
            <el-checkbox-button label="NET_3G">3G</el-checkbox-button>
            <el-checkbox-button label="NET_2G">2G</el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="per-part">
          <span>财产状态</span>
          <el-checkbox-group
            @change="
              (val) => {
                handleCheckout(val, curParams.financial_situation);
              }
            "
            v-model="curParams.financial_situation"
          >
            <el-checkbox-button label="NONE">不限</el-checkbox-button>
            <el-checkbox-button label="CAR_OWNERS">有车人士</el-checkbox-button>
            <el-checkbox-button label="HOME_OWNERS"
              >有房人士</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <div class="per-part">
          <span>排除已转化用户</span>
          <el-radio-group v-model="curParams.excluded_dimension">
            <el-radio-button label="NONE">不限</el-radio-button>
            <el-radio-button label="EXCLUDED_DIMENSION_PRODUCT"
              >同商品</el-radio-button
            >
          </el-radio-group>
        </div>
        <div class="per-part">
          <span class="required">名称</span>
          <c-input
            v-model="targetingName"
            placeholder="请输入定向包名称!"
            style="width: 300px"
          ></c-input>
        </div>
        <div class="per-part">
          <span>描述</span>
          <div style="margin-top: 10px">
            <el-input
              type="textarea"
              v-model="curParams.description"
              style="width: 300px"
              placeholder="请输入关于定向包的描述！"
            ></el-input>
          </div>
        </div>
      </div>
      <div slot="footer" class="button-list" style="text-align: left">
        <el-button type="danger" @click="clearData">清空定向</el-button>
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
    <recognition-region
      :show="showBatchRegionDialog"
      :list="selectList"
      @handleSuccess="handleSuccess"
      @close="showBatchRegionDialog = false"
    />
  </el-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import RecognitionRegion from './children/recognition-region'
import ageList from '@/mockData'
import { regionList } from '@/mockData/region'
import { formatTime } from '@/assets/js/utils'

import {
  getAdqAudience,
  adqAudience,
  editAdqAudience,
  deleteAdqAudience,
  getTXTFileInfo
} from '@/api/tencent'

const TOP_LEVEL_NODE_ID = 1156 // *代表中国的id
export default {
  name: 'ConfigOrientation',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    customFormaTime (val) {
      return formatTime(val)
    }
  },
  data () {
    return {
      page: 1,
      ageList,
      id: null,
      total: 0,
      search: '',
      pageSize: 15,
      dataList: [],
      isEdit: false,
      selectList: [],
      copyParams: null,
      selectedList: [],
      targetingName: '',
      showDialog: false,
      isRenderAuto: true,
      tableLoading: false,
      editableTabsValue: '1',
      isEchoProcess: false, // 是否处于回显数据的状态
      curParams: {
        // 地理位置
        geo_location: {
          regions: [],
          type: 'NONE',
          location_types: ['RECENTLY_IN']
        },
        age: {
          min: null,
          max: null,
          type: 'NONE'
        },
        education: ['NONE'],
        gender: 'NONE',
        network_type: ['NONE'], // 联网方式
        financial_situation: ['NONE'], //  财产状态
        excluded_dimension: 'NONE', // 排除已转化用户
        save_package: 0,
        description: ''
      },
      tabs: [
        {
          name: '1',
          id: nanoid()
        }
      ],
      chooseRegionList: [], // ! 被选中的地域合集
      rowList: [
        {
          label: 'id',
          prop: 'id'
        },
        {
          label: '定向包名称',
          prop: 'targetingName'
        },
        {
          label: '描述',
          prop: 'description',
          popover: true
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          slot: true
        },
        {
          label: '操作',
          action: 'operation'
        }
      ],
      selectedRegionList: [], // 选中地域的列表（用于回显）
      showCascaDerPanel: false,
      showBatchRegionDialog: false
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {}
    },
    curAccountInfo: {
      get () {
        return this.copyAccount[this.curAccountIndex]
      },
      set () {}
    },
    regionList () {
      return regionList.reverse()
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          if (!newV.immediate) {
            if (this.params && this.params.ConfigOrientation) {
              this.copyParams = JSON.parse(JSON.stringify(this.params))
              this.selectedList = JSON.parse(
                JSON.stringify(this.params.ConfigOrientation.select)
              )
            }
          } else {
            this.isEdit = false
            this.showDialog = true
          }
          this.initData()
        }
      },
      immediate: true
    },
    'curParams.geo_location.type': {
      handler (newV) {
        if (newV === 'CUSTOM') {
          setTimeout(() => {
            this.showCascaDerPanel = true
          }, 50)
        } else {
          this.showCascaDerPanel = false
        }
      }
    },
    'curParams.age.type': {
      handler (newV) {
        if (!newV || newV === 'CUSTOM') {
          if (!this.isEchoProcess) {
            this.curParams.age.min = null
            this.curParams.age.max = null
          }
        } else {
          this.curParams.age.min = newV.split('-')[0]
          this.curParams.age.max = newV.split('-')[1]
        }
      }
    }
  },
  methods: {
    // 新增点击表格行选中
    handleRowClick (row, column, event) {
      this.$refs.mainTable.$refs.subTable.toggleRowSelection(row)
    },
    initData () {
      this.isRenderAuto = true
      this.tableLoading = true
      getAdqAudience({
        page: this.page,
        pageSize: this.pageSize,
        search: this.search
      })
        .then(async (res) => {
          this.total = res.total
          this.dataList = res.list
          if (this.copyParams) {
            await this.echoTableList()
            await this.checkSelectionList()
          }
        })
        .finally(() => {
          this.tableLoading = false
          this.isRenderAuto = false
        })
    },
    // ! 回显勾选状态
    echoTableList () {
      return new Promise((resolve) => {
        this.selectedList.forEach((item) => {
          const targetItem = this.dataList.find((val) => val.id === item.id)
          if (targetItem) {
            this.$refs.mainTable.$refs.subTable.toggleRowSelection(
              targetItem,
              true
            )
          }
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    checkSelectionList () {
      return new Promise((resolve) => {
        if (!this.selectedList.length) return resolve()
        this.dataList.forEach((account) => {
          const targetItem = this.selectedList.find(
            (item) => item.id === account.id
          )
          this.$refs.mainTable.$refs.subTable.toggleRowSelection(
            account,
            !!targetItem
          )
          account.checked = !!targetItem
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    addOrientation () {
      this.isEdit = false
      this.showDialog = true
    },
    handleCheckout (val, arr) {
      if (arr.includes('NONE')) {
        const copyArr = JSON.parse(JSON.stringify(arr))
        // copyArr.forEach((item) => {
        for (let i = 0; i < copyArr.length; i++) {
          const item = copyArr[i]
          const targetIndex = arr.findIndex((key) => key === item)
          if (copyArr.length > 2) {
            if (item !== 'NONE') {
              arr.splice(targetIndex, 1)
            }
          }
          if (copyArr.length === 2) {
            if (copyArr[0] === 'NONE') {
              arr.splice(targetIndex, 1)
            } else {
              arr.splice(0, 1)
            }
            break
          }
        }
      }
    },
    clearData () {
      this.$confirm('确定要清除数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(() => {
          this.resetData()
        })
        .catch(() => {})
    },
    handleNodeChange () {
      this.chooseRegionList = []
      const checkedNode = this.$refs.cascaderPanel.getCheckedNodes()
      const res = this.mergeRegionData(checkedNode)
      // todo=> new
      this.chooseRegionList = res
    },
    // * 合并字段显示
    mergeRegionData (nodes) {
      let customArr = []
      const topLevelNode = nodes.find(
        (item) => item.data.id === TOP_LEVEL_NODE_ID
      )
      // const _self = this
      if (topLevelNode) {
        customArr = [topLevelNode]
        return customArr
      }
      // ! 获取半选状态的node
      const halfChoiceNodes = []

      // ! 从底层元素从底向上
      const LowestLevelNodes = []
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (!node.hasChildren) {
          LowestLevelNodes.push(node)
        }
        if (node.parent && node.parent.indeterminate) {
          if (
            !halfChoiceNodes.find(
              (halfNode) => halfNode.data.id === node.parent.data.id
            )
          ) {
            halfChoiceNodes.push(node.parent)
          }
        }
      }

      // ! 如果半选状态只有一个顶层元素 则 表示第二层元素 则直接添加第二层元素即可
      if (
        halfChoiceNodes.length === 1 &&
        halfChoiceNodes[0].data.id === TOP_LEVEL_NODE_ID
      ) {
        const targetNodeList = halfChoiceNodes[0].children.filter(
          (child) => child.checked
        )

        customArr = [...customArr, ...targetNodeList]
        return customArr
      }
      function recursionFindParent (node) {
        const { parent } = node
        // !排除'顶层元素ID'元素的影响
        if (parent && parent.data.id !== TOP_LEVEL_NODE_ID) {
          const {
            data: { id }
          } = parent
          // ! 如果在半选状态里面找到了父元素 则表示 该父元素的子元素没有被全选中
          // ! 需要把被选中的子元素合集加入到chooseRegionList里面

          if (halfChoiceNodes.find((n) => n.data.id === id)) {
            // ! 当前父节点下被选中的子元素列表
            // todo => new
            for (let i = 0; i < parent.children.length; i++) {
              let flag = true
              const item = parent.children[i]
              if (item.checked) {
                for (let j = 0; j < customArr.length; j++) {
                  const region = customArr[j]
                  if (region.data.id === item.data.id) {
                    flag = false
                    break
                  }
                }
                if (flag) {
                  customArr = [...customArr, item]
                }
              }
            }
          } else {
            // ! 如果父元素没有半选 则表示父元素全选
            recursionFindParent(parent)
          }
        } else {
          // ! 这种情况 表示 找到了 顶层节点  直接push 第二层被选中的节点即可
          // todo => new
          const topNode = halfChoiceNodes.find(
            (halfNode) => halfNode.data.id === TOP_LEVEL_NODE_ID
          )
          const secondLevelNodes = []
          for (let i = 0; i < topNode.children.length; i++) {
            const node = topNode.children[i]
            if (node.checked) {
              secondLevelNodes.push(node)
            }
          }
          for (let m = 0; m < secondLevelNodes.length; m++) {
            let flag = true
            const item = secondLevelNodes[m]
            for (let n = 0; n < customArr.length; n++) {
              const region = customArr[n]
              if (region.data.id === item.data.id) {
                flag = false
                break
              }
            }
            if (flag) {
              customArr = [...customArr, item]
            }
          }
        }
      }
      for (let i = 0; i < LowestLevelNodes.length; i++) {
        const node = LowestLevelNodes[i]
        recursionFindParent(node)
      }
      return customArr
    },
    clearRegion () {
      this.$refs.cascaderPanel.clearCheckedNodes()
    },
    handleEdit (row, cb) {
      this.isEdit = true
      this.showDialog = true
      this.echoData(row)
      cb()
    },
    echoData (row) {
      this.isEchoProcess = true
      this.id = row.id
      const arr = ['14-18', '19-24', '25-29', '30-39', '40-49', '50-50']
      const {
        ageMax,
        ageMin,
        description,
        education,
        excludedDimension,
        financialSituation,
        gender,
        locationTypes,
        networkType,
        regions,
        regionsList,
        targetingName
      } = row
      this.targetingName = targetingName
      this.selectedRegionList = regionsList ? JSON.parse(regionsList) : []
      this.curParams = {
        age: {
          min: ageMin || null,
          max: ageMax || null,
          type:
            ageMin && ageMax
              ? arr.includes(`${+ageMin}-${+ageMax}`)
                ? `${+ageMin}-${+ageMax}`
                : 'CUSTOM'
              : 'NONE'
        },
        education: education === '' ? ['NONE'] : JSON.parse(education),
        excluded_dimension: excludedDimension || 'NONE',
        financial_situation: financialSituation
          ? JSON.parse(financialSituation)
          : ['NONE'],
        network_type: networkType === '' ? ['NONE'] : JSON.parse(networkType),
        save_package: 0,
        geo_location: {
          regions: regions === '' ? [] : JSON.parse(regions),
          location_types: locationTypes === '' ? [] : JSON.parse(locationTypes),
          type: !regions ? 'NONE' : 'CUSTOM'
        },
        regions_list: this.selectedRegionList,
        gender: gender ? JSON.parse(gender)[0] : 'NONE',
        description
      }
      let timer = setInterval(() => {
        if (this.$refs.cascaderPanel) {
          clearInterval(timer)
          timer = null
          this.$nextTick(() => {
            this.handleNodeChange()
          })
        }
      }, 50)
      this.$nextTick(() => {
        this.isEchoProcess = false
      })
    },
    delRegionItem (region, index) {
      const {
        data: { id }
      } = region
      this.selectedRegionList = this.selectedRegionList.filter(
        (item) => !item.includes(id)
      )
      this.chooseRegionList.splice(index, 1)
    },
    handleDelete (id, cb) {
      deleteAdqAudience(id)
        .then(() => {
          this.$message.success('删除成功')
          this.initData()
        })
        .finally(() => {
          cb()
        })
    },
    handleOk () {
      if (this.selectedList.length === 0) {
        return this.$message.warning('请勾选定向包配置!')
      }
      this.$emit('confirm', this.selectedList)
      this.$emit('close')
    },
    async handleConfirm () {
      const { geo_location } = this.curParams
      if (geo_location.type !== 'NONE') {
        if (this.chooseRegionList.length === 0) {
          return this.$message.warning('请选择地域!')
        }
        if (geo_location.location_types.length === 0) {
          return this.$message.warning('请至少选择需至少选择一种人群类型！')
        }
      }
      if (!this.targetingName) {
        return this.$message.warning('请输入定向包名称!')
      }
      geo_location.regions = this.chooseRegionList.map((item) => item.data.id)
      const {
        age: { min, max },
        education,
        gender,
        network_type,
        financial_situation,
        excluded_dimension,
        description
      } = this.curParams
      const parameters = {
        targeting_name: this.targetingName,
        age_min: +min || '',
        age_max: +max || '',
        gender: gender === 'NONE' ? '' : [gender],
        education:
          education.includes('NONE') || education.length === 0 ? '' : education,
        network_type:
          network_type.includes('NONE') || network_type.length === 0
            ? ''
            : network_type,
        financial_situation:
          financial_situation.includes('NONE') ||
          financial_situation.length === 0
            ? ''
            : financial_situation,
        excluded_dimension:
          excluded_dimension === 'NONE' ? '' : excluded_dimension,
        location_types:
          geo_location.type === 'NONE' ? '' : geo_location.location_types,
        regions: geo_location.type === 'NONE' ? '' : geo_location.regions,
        regions_list:
          geo_location.type === 'NONE' ? '' : this.selectedRegionList,
        description
      }
      try {
        if (!this.isEdit) {
          await adqAudience(parameters)
          this.$message.success('新增成功!')
        } else {
          await editAdqAudience(this.id, parameters)
          this.$message.success('编辑成功!')
        }
        this.resetData()
        this.showDialog = false
        this.page = 1
        this.initData()
      } catch (e) {}
    },
    handleCancel () {
      this.resetData()
      this.showDialog = false
    },
    handleImportRegionChange (e) {
      const targetFile = e.target.files[0]
      this.showBatchRegionDialog = true
      const formData = new FormData()
      formData.append('file', targetFile)
      getTXTFileInfo(formData)
        .then((res) => {
          this.selectList = res
        })
        .finally(() => {
          this.$refs.importRegion.value = null
        })
    },
    batchImportRegion () {
      this.$refs.importRegion.click()
    },
    handleSelectionChange (select) {
      this.$nextTick(() => {
        if (this.isRenderAuto) return
        this.dataList.forEach((account) => {
          account.checked = !!select.find((item) => item.id === account.id)
          const targetIndex = this.selectedList.findIndex(
            (item) => item.id === account.id
          )
          if (account.checked) {
            if (targetIndex < 0) {
              this.selectedList = [...this.selectedList, account]
            }
          } else {
            if (targetIndex > -1) {
              this.selectedList.splice(targetIndex, 1)
            }
          }
        })
      })
    },
    handleClose () {
      this.$emit('close')
    },
    handleSuccess (val) {
      this.selectedRegionList = val
      this.$nextTick(() => {
        this.handleNodeChange()
      })
    },
    resetData () {
      this.isEdit = false
      this.chooseRegionList = []
      this.description = ''
      this.targetingName = ''
      this.selectedRegionList = []
      this.curParams = {
        // 地理位置
        geo_location: {
          regions: [],
          type: 'NONE',
          location_types: ['RECENTLY_IN']
        },
        age: {
          min: null,
          max: null,
          type: 'NONE'
        },
        education: ['NONE'],
        gender: 'NONE',
        network_type: ['NONE'], // 联网方式
        financial_situation: ['NONE'], //  财产状态
        excluded_dimension: 'NONE', // 排除已转化用户
        save_package: 0
      }
    }
  },
  components: { RecognitionRegion }
}
</script>
<style lang='scss' scoped>
/deep/ .el-cascader-menu__wrap {
  height: 245px;
}
.config {
  &-container {
    height: 500px;
  }
}
.add-content {
  max-height: 650px;
  overflow: auto;
  .per-part {
    @include flex-something;
    .control-group-separator {
      display: inline-block;
      width: 10px;
      height: 1px;
      margin: 0 20px;
      overflow: hidden;
      background: #575757;
    }
    .region {
      &-container {
        margin-top: 10px;
        position: relative;
        .already-choose-region {
          .region-list {
            width: 800px;
            max-height: 200px;
            overflow: auto;
            margin-bottom: 20px;
            p {
              height: 30px;
              padding: 0 19px;
              line-height: 30px;
              position: relative;
              border-radius: 20px;
              margin: 5px 10px 10px 0;
              border: 1px solid #ccc;
              i {
                color: $colorRed;
                background: #fff;
                display: none;
                cursor: pointer;
                font-weight: bold;
              }
              &:hover {
                color: $colorRed;
                border-color: $colorRed;
                padding-left: 14px;
                padding-right: 10px;
                i {
                  display: inline-block;
                }
              }
            }
          }
        }
        .import-region {
          margin: 20px 0 10px 0;
          > span {
            cursor: pointer;
            &:hover {
              color: $main;
            }
          }
        }
        .loading-icon {
          width: 800px;
          height: 240px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            margin-left: 20px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
</style>
