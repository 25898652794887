<template>
  <div class="mix-picture">
    <div class="mix-picture-container">
      <div class="control-twins-item">
        <div class="image-cover per-part">
          <div class>
            <div class="image-over-list per-part-list">
              <div class="image-over-list-item">
                <input
                  type="file"
                  ref="uploadInput"
                  @change="handleFileChange"
                  style="display:none"
                />
                <div class="item-media" v-if="resourceInfo.mediaInfo.src && !resourceInfo.loading">
                  <img v-if="mediaType === 'image'" :src="resourceInfo.mediaInfo.src" alt />
                  <video
                    v-else-if="mediaType === 'video'"
                    :src="resourceInfo.mediaInfo.src"
                    controls
                    muted
                  ></video>
                  <div
                    class="reset-upload-hover flex-center-center"
                    v-if="mediaType === 'image'"
                    @click="handleUpload"
                  >
                    <span style="font-size:14px;color:#fff;font-weight: bold;">点击重新上传</span>
                  </div>
                  <i
                    class="el-icon-error"
                    v-if="resourceInfo.mediaInfo.src"
                    @click="handleDelMediaInfo($event)"
                  ></i>
                </div>
                <div
                  class="tips"
                  @click="handleUpload"
                  v-else-if="!resourceInfo.mediaInfo.src && !resourceInfo.loading"
                >
                  <p>点击上传</p>
                  <p>{{ widthAndHigh.width }}px * {{ widthAndHigh.height }}px</p>
                  <template v-if="tipsIndex === 0">
                    <p class="divide-line"></p>
                    <p style="font-size: 12px" v-if="mediaType === 'image'">JPG/JPEG/PNG</p>
                    <p style="font-size: 12px" v-else-if="mediaType === 'video'">
                      MP4,<={{ Math.floor(limitSize / 1024 / 1024) }}MB,时长>={{ durationInfo.minTime }}s,
                      <br />
                      <={{ durationInfo.maxTime }}s,必须带有声音
                    </p>
                    <p style="font-size: 12px" v-if="mediaType === 'image'">小于{{ limitSize }}KB</p>
                  </template>
                </div>
                <div class="loading-tips flex-center-center" v-if="resourceInfo.loading">
                  <p>
                    <i class="el-icon-loading"></i>
                    <span style="margin-left: 5px">上传中</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCancel"
    >
      <media-library :show="showMediaDialog" :mediaType="`${mediaType}`" ref="mediaLibrary">
        <div slot="footer" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="
              () => {
                handleOk();
              }
            "
          >确定</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
    <div class="library-choose" v-if="isValidObject(resourceInfo)">
      <p @click="chooseLibrary">
        <span>
          <i class="el-icon-picture"></i>从素材库中心选择
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'
import { imageStatusCheck, videoStatusCheck, _changeTimeBySecond, isValidObject, isValidArray } from '@/assets/js/utils'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import { nanoid } from 'nanoid'
import _ from 'lodash'
export default {
  props: {
    curInfo: {
      type: Object,
      default: () => ({})
    },
    widthAndHigh: {
      type: Object,
      default: () => ({
        width: 1280,
        height: 720
      })
    },
    limitSize: {
      type: [String, Number],
      default: 140
    },
    // ! 媒体类型
    mediaType: {
      type: String,
      default: 'image'
    },
    durationInfo: {
      type: Object,
      default: () => ({
        minTime: 5,
        maxTime: 30
      })
    },
    Index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isValidObject,
      showMediaDialog: false,
      tipsIndex: 0,
      resourceInfo: {
        id: nanoid(),
        loading: false,
        mediaInfo: {}
      }
    }
  },
  watch: {
    curInfo: {
      handler (newV) {
        if (isValidObject(newV)) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {
    echoData () {
      this.resourceInfo = _.cloneDeep(this.curInfo)
    },
    async handleFileChange (e) {
      const targetFile = [...e.target.files][0]
      if (this.mediaType === 'video') return this.handleVideoFile(targetFile)
      const { flag, message, file: compressFile } = await imageStatusCheck(targetFile, +this.limitSize, {
        width: +this.widthAndHigh.width,
        height: +this.widthAndHigh.height
      })
      if (!flag) {
        this.$message.error(`${message}`)
        this.$refs.uploadInput.value = null
        return
      }
      try {
        this.resourceInfo.loading = true
        const res = await uploadFile({ file: compressFile })
        const type = compressFile.type.split(/\//g)
        this.resourceInfo = {
          id: this.resourceInfo.id,
          loading: false,
          mediaInfo: {
            src: res,
            name: compressFile.name.replace(/\.\w+/g, ''),
            url: res,
            widthAndHigh: `${+this.widthAndHigh.width} * ${+this.widthAndHigh.height}`,
            size: `${Math.floor(compressFile.size / 1024)}KB`,
            title: compressFile.name.replace(/\.\w+/g, ''),
            materialId: null,
            format: type[type.length - 1]
          }
        }
        this.uploadNovelPut()
      } catch (e) {
        this.$message.error('上传失败，请稍后再试!')
        this.resourceInfo.loading = false
      } finally {
        this.$refs.uploadInput.value = null
      }
    },
    async uploadNovelPut () {
      const { mediaInfo } = this.resourceInfo
      const obj = {
        materialType: this.mediaType === 'image' ? 2 : 1,
        title: mediaInfo.title,
        materialResources: {
          title: mediaInfo.title,
          widthAndHigh: mediaInfo.widthAndHigh,
          format: mediaInfo.format,
          size: mediaInfo.size,
          name: mediaInfo.title,
          url: mediaInfo.url,
          sort: 1
        }
      }
      const [firstElement] = await addMaterialWare([obj])
      const { resources: [Value] } = firstElement
      this.resourceInfo = {
        id: this.resourceInfo.id,
        loading: false,
        mediaInfo: {
          ...Value,
          name: mediaInfo.title,
          src: Value.url
        }
      }
    },
    async handleVideoFile (targetFile) {
      const { minTime, maxTime } = this.durationInfo
      const { file, message, duration } = await videoStatusCheck(targetFile, {
        minTime, maxTime
      }, this.widthAndHigh)
      if (!file) {
        this.$refs.uploadInput.value = null
        return this.$message.warning(`${message}`)
      }
      this.resourceInfo = {
        ...this.resourceInfo,
        loading: true
      }
      try {
        const res = await uploadFile({ file })
        const type = file.type.split(/\//g)
        let videoSize
        if (file.size / 1024 / 1024 < 1) {
          videoSize = `${Math.floor(file.size / 1024)}KB`
        } else {
          videoSize = `${Math.floor(file.size / 1024 / 1024)}MB`
        }
        this.resourceInfo = {
          ...this.resourceInfo,
          loading: false,
          mediaInfo: {
            src: res,
            url: res,
            name: targetFile.name.replace(/\.\w+/g, ''),
            title: targetFile.name.replace(/\.\w+/g, ''),
            widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
            videoTime: _changeTimeBySecond(Number(duration)),
            size: videoSize,
            format: type[type.length - 1],
            materialId: null,
            sort: 1
          }
        }
      } catch (e) {
        this.$message.error('上传失败!')
      } finally {
        this.$refs.uploadInput.value = null
      }
    },
    handleOk () {
      const { imageList } = this.$refs.mediaLibrary
      const imageInfo = imageList[0]
      const { src, resources: [Value] } = imageInfo
      if (!this.checkMedia(imageInfo)) return this.$message.error('所选素材有不符合要求,请重新选择上传!')
      this.resourceInfo = {
        id: this.resourceInfo.id,
        mediaInfo: {
          src: src,
          url: src,
          size: Value.size,
          format: Value.format,
          materialId: Value.materialId,
          widthAndHigh: Value.widthAndHigh,
          name: Value.title.replace(/\.\w+/g, ''),
          title: Value.title.replace(/\.\w+/g, '')
        }
      }
      this.showMediaDialog = false
      this.boundDocument(imageInfo)
    },
    boundDocument (imageInfo) {
      const { doc } = imageInfo
      if (!isValidArray(doc)) return
      this.$emit('chooseDoc', { doc, Index: this.Index })
    },
    checkMedia (file) {
      const { resources } = file
      const mediaSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+mediaSize > this.limitSize) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },
    handleUpload () {
      this.$refs.uploadInput.click()
    },
    handleDelMediaInfo (e) {
      e.stopPropagation()
      this.$set(this.resourceInfo, 'mediaInfo', Object.assign({}, { ...this.resourceInfo.mediaInfo, src: null }))
    },
    handleCancel () {
      this.showMediaDialog = false
    },
    chooseLibrary () {
      this.showMediaDialog = true
    }
  },
  components: { MediaLibrary }
}
</script>
<style lang='scss' scoped>
.mix-picture {
  position: relative;
  .library-choose {
    position: absolute;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    &:hover {
      color: $main;
    }
  }
}
.control-twins-item {
  border: 1px solid $borderColor;
  border-radius: 8px;
  padding: 40px;
  .per-part {
    margin-bottom: 40px;
  }
  .image-over-list {
    @include flex(center, center);
  }
  .image-over-list-item {
    width: 298px;
    height: 158px;
    background-color: #f5f6f8;
    border: 1px solid $borderColor;
    cursor: pointer;
    border-radius: 4px;
    margin-right: -1px;
    font-size: 14px;
    text-align: center;
    color: #b0b4bd;
    position: relative;
    text-align: center;
    @include flex(center, center);
    .tips {
      p {
        line-height: 25px;
        cursor: pointer;
      }
    }
    .item-media {
      position: relative;
      width: 182px;
      height: 102px;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      i {
        position: absolute;
        left: 95%;
        top: -5%;
        color: $colorRed;
        z-index: 999;
        font-size: 16px;
        opacity: 0;
        background: $main-background;
        border-radius: 50%;
      }
      .reset-upload-hover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.25s;
        background-color: rgba(0, 0, 0, 0.4confirm);
        opacity: 0;
      }
      &:hover {
        .reset-upload-hover,
        i {
          opacity: 1;
        }
      }
    }
    .el-icon-close {
      position: absolute;
      top: 5%;
      left: 90%;
    }
    .el-icon-plus {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 22px;
    }
    .loading-tips {
      width: 182px;
      height: 102px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  .library-choose {
    margin-top: 10px;
    text-align: center;
    > p {
      cursor: pointer;
      padding: 8px 0;
      transition: all 0.3s;
      &:hover {
        color: $main;
      }
    }
  }
  .operation-delete {
    align-self: center;
    margin-left: 20px;
    border-radius: 8px;
    background-color: #f5f6f8;
    border: 1px solid $borderColor;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    width: 40px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
