<template>
  <el-dialog
    width="1000px"
    title="添加文案"
    top="20px"
    append-to-body
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div class="config-resource">
      <div class="config-resource-container">
        <div class="doc-container">
          <div
            class="flex-row-start-center"
            v-for="(item, i) in curParams.text"
            :key="item.id"
          >
            <div class="doc-item">
              <div
                class="flex-row-start-center"
                v-if="
                  params.creativityConfig.adcreativeTemplateInfo.value !== 714
                "
              >
                <span>左下辅助文字（选填）</span>
                <el-autocomplete
                  style="width: 600px"
                  v-model="item.bottom_text"
                  placeholder="请输入辅助文字"
                  clearable
                  :fetch-suggestions="
                    (str, cb) => {
                      querySearchAsync(str, cb, i, 'bottom_text');
                    }
                  "
                >
                </el-autocomplete>
                <limit-word
                  style="margin-left: 10px"
                  :calculationRules="calculateCharacter"
                  :curValue="item.bottom_text"
                  :initLength="item.lenArr[0]"
                  @error="showError = true"
                  @change="showError = false"
                  :isDivideByTwo="true"
                  :maxLength="8"
                ></limit-word>
              </div>
              <div class="flex-row-start-center">
                <span>文案（{{ getMinLength() }}-{{ getMaxLength() }}字）</span>
                <el-autocomplete
                  style="width: 600px"
                  v-model="item.description"
                  placeholder="使用行动按钮或卖点图时最多支持10个字"
                  clearable
                  :fetch-suggestions="
                    (str, cb) => {
                      querySearchAsync(str, cb, i, 'description');
                    }
                  "
                >
                </el-autocomplete>
                <limit-word
                  style="margin-left: 10px"
                  :calculationRules="calculateCharacter"
                  :curValue="item.description"
                  :initLength="item.lenArr[1]"
                  @error="showError = true"
                  @change="showError = false"
                  :isDivideByTwo="true"
                  :maxLength="getMaxLength()"
                  :minLength="getMinLength()"
                ></limit-word>
              </div>
              <div
                class="flex-row-start-center"
                v-if="
                  params.creativityConfig.adcreativeTemplateInfo.value !== 714
                "
              >
                <span>文案（1-14字）</span>
                <el-autocomplete
                  style="width: 600px"
                  v-model="item.title"
                  clearable
                  placeholder="使用行动按钮或卖点图时最多支持10个字"
                  :fetch-suggestions="
                    (str, cb) => {
                      querySearchAsync(str, cb, i, 'title');
                    }
                  "
                ></el-autocomplete>
                <limit-word
                  style="margin-left: 10px"
                  :calculationRules="calculateCharacter"
                  :curValue="item.title"
                  :initLength="item.lenArr[2]"
                  @error="showError = true"
                  @change="showError = false"
                  :isDivideByTwo="true"
                  :maxLength="14"
                ></limit-word>
              </div>
            </div>
            <div
              class="operation-delete"
              @click="curParams.text.splice(i, 1)"
              v-if="curParams.text.length > 1"
            >
              <i class="el-icon-minus"> </i>
            </div>
          </div>
        </div>
        <div
          class="tips"
          @click="addText"
          v-if="10 - curParams.text.length !== 0"
        >
          <i class="el-icon-plus"></i>
          <span style="margin-left: 5px"
            >还可以添加 {{ 10 - curParams.text.length }} 组</span
          >
        </div>
      </div>
    </div>
    <div slot="footer" class="button-list" style="text-align: left">
      <el-button type="danger" @click="clearResource">清空数据</el-button>
      <el-button type="primary" @click="handleOk">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LimitWord from '@/components/limit-word'
import { calculateCharacter } from '@/assets/js/utils'
import { getCreativeToolsText } from '@/api/tencent'
import { nanoid } from 'nanoid'
export default {
  name: 'ConfigDoc',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      calculateCharacter,
      curParams: {
        text: [
          {
            title: '',
            description: '',
            bottom_text: '',
            id: nanoid(),
            loading: false,
            lenArr: [0, 0, 0]
          }
        ]
      },
      showError: false,
      initLength: 0,
      loading: false,
      buttonTextList: [],
      descList: [],
      titleList: []
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {}
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    async initData () {
      try {
        if (this.params && this.params.ConfigDoc) {
          this.curParams.text = JSON.parse(
            JSON.stringify(this.params.ConfigDoc.select)
          )
        }
      } catch (error) {
        this.$message.warning('请求接口失败!')
      }
    },
    getDocList (type = 'bottom_text', i = null, keyword = null) {
      return new Promise((resolve) => {
        if (i) this.curParams.text[i].loading = true
        getCreativeToolsText({
          accountId: this.account[0].accountId,
          maxTextLength:
            type === 'bottom_text'
              ? 8
              : type === 'title'
                ? 14
                : this.getMaxLength(),
          keyword,
          number: 50
        })
          .then((res) => {
            if (type === 'bottom_text') this.buttonTextList = res
            if (type === 'description') this.descList = res
            if (type === 'title') this.titleList = res
          })
          .finally(() => {
            if (i) this.curParams.text[i].loading = false
            this.$nextTick(() => {
              resolve()
            })
          })
      })
    },
    addText () {
      this.curParams.text = [
        ...this.curParams.text,
        {
          title: '',
          description: '',
          bottom_text: '',
          id: nanoid(),
          loading: false,
          lenArr: [0, 0, 0]
        }
      ]
    },
    handleOk () {
      const {
        creativityConfig: { adcreativeTemplateInfo }
      } = this.params
      if (adcreativeTemplateInfo.value !== 714) {
        if (
          this.curParams.text.some(
            (item) => this.showError || !item.title || !item.description
          )
        ) {
          return this.$message.warning('请完善文案配置!')
        }
        // ! 横版小图3:2 只需要description文案
      } else {
        if (
          this.curParams.text.some(
            (item) => this.showError || !item.description
          )
        ) {
          return this.$message.warning('请完善文案配置!')
        }
      }
      if (
        this.curParams.text.some(
          (item) =>
            Math.ceil(calculateCharacter(item.bottom_text) / 2) > 8 ||
            Math.ceil(calculateCharacter(item.title) / 2) > 14 ||
            Math.ceil(calculateCharacter(item.description) / 2) > 30 ||
            Math.ceil(calculateCharacter(item.description) / 2) < 6
        )
      ) {
        return this.$message.error('请检查文案的字符长度!')
      }
      this.curParams.text.forEach((item) => {
        item.lenArr = [
          Math.ceil(calculateCharacter(item.bottom_text) / 2),
          Math.ceil(calculateCharacter(item.description) / 2),
          Math.ceil(calculateCharacter(item.title) / 2)
        ]
      })
      this.$emit('confirm', this.curParams.text)
      this.$emit('close')
    },
    handleCancel () {
      this.$emit('close')
    },
    handleClose () {
      this.$emit('close')
    },
    clearResource () {
      this.$confirm('确定清空吗？清空的数据无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.curParams = {
            text: [
              {
                id: nanoid(),
                title: '',
                description: '',
                bottom_text: '',
                lenArr: [0, 0, 0]
              }
            ]
          }
        })
        .catch(() => {})
    },
    querySearchAsync (queryString, cb, index, type) {
      let res = []
      this.getDocList(type, index, queryString)
        .then(() => {
          if (type === 'bottom_text') {
            res = !queryString
              ? this.buttonTextList
              : this.buttonTextList.filter((item) =>
                item.text.includes(queryString)
              )
          }
          if (type === 'description') {
            res = !queryString
              ? this.descList
              : this.descList.filter((item) => item.text.includes(queryString))
          }
          if (type === 'title') {
            res = !queryString
              ? this.titleList
              : this.titleList.filter((item) =>
                item.text.includes(queryString)
              )
          }
        })
        .finally(() => {
          cb(res.map((item) => ({ value: item.text })))
        })
    },
    getMaxLength () {
      const {
        creativityConfig: { adcreativeTemplateInfo }
      } = this.params
      // ! 横版小图3:2
      return adcreativeTemplateInfo.value === 714 ? 24 : 30
    },
    getMinLength () {
      const {
        creativityConfig: { adcreativeTemplateInfo }
      } = this.params
      return adcreativeTemplateInfo.value === 714 ? 16 : 6
    }
  },
  components: { LimitWord }
}
</script>
<style lang='scss' scoped>
.config-resource-container {
  .doc-container {
    max-height: 650px;
    overflow: auto;
    .doc-item {
      border: 1px solid $borderColor;
      padding: 20px;
      border-radius: 8px;
      margin-bottom: 30px;
      > div {
        margin-bottom: 20px;
        > span {
          width: 200px;
        }
      }
    }
  }
}
.operation-delete {
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  background-color: #f5f6f8;
  border: 1px solid $borderColor;
  font-size: 18px;
  height: 30px;
  line-height: 30px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.tips {
  text-align: center;
  border: 1px dashed $borderColor;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #f7f9fc;
  }
}
</style>
