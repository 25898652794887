<template>
  <div class="control-twins-item is-group" ref="controlTwins">
    <div class="control-twins-item-container" v-if="resourceInfo">
      <div class="creative-item flex-row-start-start">
        <div
          class="list-item flex-center-center"
          @mouseenter="handleMouseEnter('tipsIndexVideo')"
          @mouseleave="handleMouseLeave('tipsIndexVideo')"
        >
          <input
            type="file"
            ref="inputVideo"
            style="display:none"
            @change="(e) => handleVideoChange(e)"
          />
          <div class="meta-item" v-if="resourceInfo.video.src && !resourceInfo.video.loading">
            <video controls muted :src="resourceInfo.video.src"></video>
            <i
              class="el-icon-error"
              v-if="resourceInfo.video.src"
              @click.stop="resourceInfo.video = {}"
            ></i>
          </div>
          <div
            class="tips"
            v-else-if="!resourceInfo.video.src && !resourceInfo.video.loading"
            @click="handleUpload('video')"
          >
            <p>(推荐尺寸:{{ widthAndHigh.width }} * {{ widthAndHigh.height }})</p>
            <p>点击上传</p>
            <p>{{ getRate() }}</p>
            <template v-if="tipsIndexVideo === resourceInfo.id">
              <p class="divide-line"></p>
              <p style="font-size:12px">MP4/MOV/AVI</p>
              <p style="font-size:12px"></p>
            </template>
          </div>
          <div class="loading-tips flex-center-center" v-if="resourceInfo.video.loading">
            <p>
              <i class="el-icon-loading"></i>
              <span style="margin-left:5px">上传中</span>
            </p>
          </div>
        </div>
        <div
          class="list-item flex-center-center"
          @mouseenter="handleMouseEnter('tipsIndex')"
          @mouseleave="handleMouseLeave('tipsIndex')"
        >
          <input
            type="file"
            @change="(e) => handleImageChange(e)"
            ref="inputImage"
            style="display:none"
          />
          <div class="meta-item" v-if="resourceInfo.image.src && !resourceInfo.image.loading">
            <img :src="resourceInfo.image.src" />
            <div class="reset-upload-hover flex-center-center" @click="chooseSingleCover">
              <p style="color:#fff;font-weight:bold">重新选择</p>
            </div>
            <i
              class="el-icon-error"
              v-if="resourceInfo.image.src"
              @click="resourceInfo.image.src = null"
            ></i>
          </div>
          <div class="loading-tips flex-center-center" v-if="resourceInfo.image.loading">
            <p>
              <i class="el-icon-loading"></i>
              <span style="margin-left:5px">上传中</span>
            </p>
          </div>
          <div
            class="tips"
            v-else-if="!resourceInfo.image.src && !resourceInfo.image.loading"
            @click="chooseSingleCover"
          >
            <div style="cursor: pointer;">
              <p>封面图</p>
              <p>{{ widthAndHigh.width }}px * {{ widthAndHigh.height }}px</p>
            </div>
          </div>
        </div>
      </div>
      <div class="library-choose">
        <p @click="chooseLibrary('video')">
          <span>
            <i class="el-icon-video-camera-solid" style="margin-right:4px"></i>
            从素材库当中选择（视频）
          </span>
        </p>
        <p @click="chooseCover('image')">
          <span>
            <i class="el-icon-picture" style="margin-right:4px"></i>
            选择封面图（图片）
          </span>
        </p>
      </div>
    </div>
    <div v-else></div>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCancel"
    >
      <media-library :show="showMediaDialog" :mediaType="mediaType" ref="mediaLibrary">
        <div slot="footer" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="
              () => {
                handleOk();
              }
            "
          >确定</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
    <choose-cover-img
      @close="showCoverImg = false"
      :show="showCoverImg"
      :videoList="[resourceInfo.video]"
      @chooseCoverSucc="chooseCoverSucc"
    ></choose-cover-img>
  </div>
</template>

<script>
import {
  imageStatusCheck,
  videoStatusCheck,
  _changeTimeBySecond,
  isValidObject,
  gcd,
  isValidArray
} from '@/assets/js/utils'
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import chooseCoverImg from '../choose-cover-img.vue'
import { nanoid } from 'nanoid'
import _ from 'lodash'
const LIMIT_NUMBER = 20
const VIDEO_TYPE = ['video/mp4', 'video/avi', 'video/quicktime']
export default {
  name: 'ResourceVideo',
  props: {
    size: {
      type: String,
      default: 'vertical'
    },
    curInfo: {
      type: Object,
      default: () => ({})
    },
    widthAndHigh: {
      type: Object,
      default: () => ({
        width: 720,
        height: 1280
      })
    },
    limitSize: {
      type: Object,
      default: () => ({
        imageSize: '140kb',
        videoSize: '100mb'
      })
    },
    duration: {
      type: Object,
      default: () => ({
        minTime: 5,
        maxTime: 60
      })
    },
    Index: {
      type: Number,
      default: 0
    }

  },
  data () {
    return {
      LIMIT_NUMBER,
      mediaType: '',
      tipsIndexVideo: null,
      showMediaDialog: false,
      showCoverImg: false,
      resourceInfo: {
        id: nanoid(),
        image: {},
        video: {}
      },
      gcd,
      tipsIndex: null
    }
  },
  watch: {
    curInfo: {
      handler (newV) {
        if (isValidObject(newV)) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {

    echoData () {
      this.resourceInfo = _.cloneDeep(this.curInfo)
    },
    handleMouseEnter (type, val) {
      if (type === 'tipsIndex') {
        if (!this.resourceInfo.image.src) return
      }
      if (type === 'tipsIndexVideo') {
        if (!this.resourceInfo.video.src) return
      }
      this[type] = this.resourceInfo.id
    },
    handleMouseLeave (type) {
      this[type] = null
    },
    async handleVideoChange (e, index) {
      const targetFile = e.target.files[0]
      const { type } = targetFile
      if (!VIDEO_TYPE.includes(type)) {
        return this.$message.error('上传的视频格式不符合要求!')
      }
      const { minTime, maxTime } = this.duration
      const { file, message, duration } = await videoStatusCheck(
        targetFile,
        +this.limitSize.videoSize.replace(/[a-zA-Z]/g, ''),
        {
          minTime: +minTime,
          maxTime: +maxTime
        },
        this.widthAndHigh
      )
      if (!file) {
        this.$refs.inputVideo.value = ''
        return this.$message.warning(`${message}`)
      }
      if (file) {
        this.resourceInfo = {
          ...this.resourceInfo,
          video: {
            src: null,
            loading: true
          }
        }
        uploadFile({ file })
          .then((res) => {
            let videoSize
            if (file.size / 1024 / 1024 < 1) {
              videoSize = `${Math.floor(file.size / 1024)}KB`
            } else {
              videoSize = `${Math.floor(file.size / 1024 / 1024)}MB`
            }
            const type = file.type.split(/\//g)
            this.resourceInfo = {
              ...this.resourceInfo,
              video: {
                src: res,
                loading: false,
                name: targetFile.name.replace(/\.\w+/g, ''),
                url: res,
                widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
                videoTime: _changeTimeBySecond(Number(duration)),
                size: videoSize,
                format: type[type.length - 1],
                materialId: null,
                sort: 1,
                title: targetFile.name.replace(/\.\w+/g, '')
              }
            }
            this.uploadNovelPut()
          })
          .finally(() => {
            this.$refs.inputVideo.value = ''
            this.resourceInfo.video.loading = false
          })
      }
    },
    async uploadNovelPut () {
      try {
        const { video } = this.resourceInfo
        const obj = {
          materialType: 1,
          title: video.title,
          materialResources: {
            title: video.title,
            widthAndHigh: video.widthAndHigh,
            format: video.format,
            size: video.size,
            url: video.url,
            videoTime: video.videoTime,
            sort: 1
          }
        }
        const [firstElement] = await addMaterialWare([obj])
        const { resources: [Value] } = firstElement
        this.resourceInfo = {
          ...this.resourceInfo,
          video: {
            materialId: Value.materialId,
            src: Value.url,
            url: Value.url,
            loading: false,
            name: Value.title,
            title: Value.title,
            widthAndHigh: Value.widthAndHigh,
            size: Value.size,
            videoTime: Value.videoTime
          }
        }
      } catch (e) {
        this.$message.error('上传到小说素材库失败，请稍后再试！')
      }
    },
    async handleImageChange (e, index) {
      try {
        const targetFile = e.target.files[0]
        const {
          flag,
          message,
          file: compressFile
        } = await imageStatusCheck(
          targetFile,
          +this.limitSize.imageSize.replace(/[a-zA-Z]/g, ''),
          this.widthAndHigh
        )
        if (!flag) {
          this.$message.error(`${message}`)
          this.$refs.inputImage.value = ''
          return
        }
        this.resourceInfo = {
          ...this.resourceInfo,
          image: {
            loading: true,
            name: null,
            src: null
          }
        }
        const res = await uploadFile({ file: compressFile })
        const type = compressFile.type.split(/\//g)
        this.resourceInfo = {
          id: this.resourceInfo.id,
          video: this.resourceInfo.video,
          image: {
            src: res,
            name: compressFile.name.replace(/\.\w+/g, ''),
            loading: false,
            title: compressFile.name.replace(/\.\w+/g, ''),
            url: res,
            widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
            size: `${Math.floor(compressFile.size / 1024)}KB`,
            materialId: null,
            format: type[type.length - 1]
          }

        }

        this.$refs.inputImage.value = ''
      } catch (e) {
        this.$message.error('上传失败,请稍后再试!')
        this.$refs.inputImage.value = ''
        this.resourceInfo = { ...this.resourceInfo, image: { loading: false } }
      }
    },
    chooseLibrary (type) {
      this.mediaType = type
      this.showMediaDialog = true
    },
    getRate () {
      const { width, height } = this.widthAndHigh
      const cd = this.gcd(+width, +height)
      return `${width / cd} : ${height / cd}`
    },
    handleUpload (type) {
      if (type === 'image') {
        this.$refs.inputImage.click()
      }
      if (type === 'video') {
        this.$refs.inputVideo.click()
      }
    },
    chooseSingleCover () {
      if (!this.resourceInfo.video || !isValidObject(this.resourceInfo.video)) return
      this.showCoverImg = true
    },
    chooseCover () { this.showCoverImg = true },
    handleCancel () {
      this.showMediaDialog = false
    },
    handleOk () {
      const { imageList } = this.$refs.mediaLibrary
      const imageInfo = imageList[0]
      const flag = imageList.every(
        this.mediaType === 'video' ? this.checkVideo : this.checkImage
      )
      if (!flag) { return this.$message.error('所选素材至少有一项不符合格式要求！') }
      let identifier = false
      identifier = this.mediaType === 'image' ? this.checkImage(imageInfo) : this.checkVideo(imageInfo)
      if (identifier) {
        this.resourceInfo = {
          ...this.resourceInfo,
          [`${this.mediaType === 'image' ? 'image' : 'video'}`]: {
            src: imageInfo.src,
            loading: false,
            name: imageInfo.resources[0].title.replace(/\.\w+/g, ''),
            title: imageInfo.resources[0].title.replace(/\.\w+/g, ''),
            materialId: imageInfo.id,
            url: imageInfo.src,
            widthAndHigh: imageInfo.resources[0].widthAndHigh,
            ...(this.mediaType === 'video' && {
              videoTime: imageInfo.resources[0].videoTime
            })
          }
        }
      }
      this.showMediaDialog = false
      // !根据选择的素材关联文案
      this.boundDocument(imageInfo)
    },
    boundDocument (imageInfo) {
      const { doc } = imageInfo
      if (!isValidArray(doc)) return
      this.$emit('chooseDoc', { doc, Index: this.Index })
    },
    checkImage (file) {
      const { resources } = file
      const imageSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+imageSize > +this.limitSize.imageSize.replace(/[a-zA-Z]/g, '')) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },
    checkVideo (file) {
      const { resources } = file
      const videoSize = resources[0].size.replace(/[^\d+]/g, '')
      if (
        +videoSize / 1024 >
        +this.limitSize.videoSize.replace(/[a-zA-Z]/g, '')
      ) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },
    chooseCoverSucc (select) {
      this.resourceInfo = {
        ...this.resourceInfo,
        image: {
          loading: false,
          src: select[0].src,
          title: '封面图',
          name: '封面图'
        },

      }
    },
    resetData () {
      this.resourceInfo = null
    }
  },
  components: { chooseCoverImg, MediaLibrary }
}
</script>
<style lang='scss' scoped>
.divide-line {
  width: 100%;
  border-top: 1px solid $borderColor;
  margin: 15px 0;
}

.control-twins-item {
  border: 1px solid $borderColor;
  border-radius: 8px;
  padding: 40px;
  .per-part {
    margin-bottom: 40px;
    &-list {
      width: 90%;
    }
  }
  .list-item {
    width: 298px;
    height: 158px;
    background-color: #f5f6f8;
    border: 1px solid $borderColor;
    // cursor: pointer;
    border-radius: 4px;
    margin-right: -1px;
    font-size: 14px;
    text-align: center;
    color: #b0b4bd;
    position: relative;
    .tips {
      width: 80%;
    }
    .loading-tips {
      width: 182px;
      height: 102px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
    .el-icon-close {
      position: absolute;
      top: 5%;
      left: 90%;
    }
    .el-icon-plus {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 22px;
    }
  }
  .operation-delete {
    align-self: center;
    margin-left: 20px;
    border-radius: 8px;
    background-color: #f5f6f8;
    border: 1px solid $borderColor;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    width: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.is-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .creative-item {
    margin-bottom: 40px;
  }
}

.add-resource-item {
  width: 594px;
  // margin-top: 40px;
  height: 36px;
  line-height: 36px;
  border: 1px dashed rgb(223, 225, 230);
  border-radius: 8px;
  background-color: #f5f6f8;
  text-align: center;
  cursor: pointer;
}
.library-choose {
  margin-top: 20px;
  > p {
    cursor: pointer;
    padding: 8px 0;
    transition: all 0.3s;
    &:hover {
      color: $main;
    }
  }
}
.meta-item {
  position: relative;
  width: 182px;
  height: 102px;
  img,
  video {
    height: 100%;
    width: 100%;
  }
  img {
    object-fit: contain;
  }
  i {
    position: absolute;
    left: 95%;
    top: -5%;
    color: $colorRed;
    z-index: 999;
    font-size: 16px;
    opacity: 0;
    background: $main-background;
    border-radius: 50%;
  }
  .reset-upload-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.25s;
    background-color: rgba(0, 0, 0, 0.4confirm);
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    .reset-upload-hover,
    i {
      opacity: 1;
    }
  }
}
</style>
