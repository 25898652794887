<template>
  <div class="vertical-video">
    <div class="vertical-video-container">
      <div class="control-twins-item">
        <div class="image-cover per-part">
          <div class="flex-row-start-start">
            <div style="width: 130px">{{mediaType === 'image' ? '图片' :'视频'}}</div>
            <div
              class="image-cover-list per-part-list flex-row-start-start-wrap"
            >
              <div
                class="image-cover-list-item list-item flex-center-center"
                @mouseenter="handleMouseEnter('tipsIndex', item)"
                @mouseleave="handleMouseLeave('tipsIndex')"
                :draggable="!!item.mediaInfo.src"
                v-for="(item, index) in resourceList"
                :key="item.id"
              >
                <input
                  type="file"
                  :ref="`uploadInput`"
                  @change="
                    (file) => {
                      handleFileChange(index, file);
                    }
                  "
                  :multiple="
                    resourceList.length -
                      resourceList.filter((item) => item.mediaInfo.src).length >
                      1 && isCanMultiple
                  "
                  style="display: none"
                />
                <div class="item-media" v-if="item.mediaInfo.src && !item.loading">
                  <img v-if="mediaType === 'image'" :src="item.mediaInfo.src" alt="" />
                  <video v-else-if="mediaType === 'video'" :src="item.mediaInfo.src" controls alt="" />
                  <div
                    class="reset-upload-hover flex-center-center"
                    v-if="mediaType === 'image'"
                    @click="
                      () => {
                        (isCanMultiple = false), handleUpload(index);
                      }
                    "
                  >
                    <span
                      style="font-size: 14px; color: #fff; font-weight: bold"
                      >点击重新上传</span
                    >
                  </div>
                  <i
                    class="el-icon-error"
                    v-if="item.mediaInfo.src"
                    @click.stop="item.mediaInfo.src = null"
                  ></i>
                </div>
                <div
                  class="tips"
                  @click="handleUpload(index)"
                  v-else-if="!item.mediaInfo.src && !item.loading"
                >
                  <p>点击上传</p>
                  <p>{{widthAndHigh.width}}px * {{widthAndHigh.height}}px</p>
                  <template v-if="tipsIndex === item.id">
                    <p class="divide-line"></p>
                    <p style="font-size: 12px" v-if="mediaType==='image'">JPG/JPEG/PNG</p>
                    <p style="font-size: 12px" v-else-if="mediaType==='video'">MP4,<={{Math.floor(limitSize / 1024 / 1024)}}MB,时长>={{durationInfo.minTime}}s,<br/><={{durationInfo.maxTime}}s,必须带有声音</p>
                    <p style="font-size: 12px" v-if="mediaType==='image'">小于{{limitSize}}KB</p>
                  </template>
                </div>
                <i
                  class="el-icon-close"
                  v-if="resourceList.length > 1"
                  @click.stop="resourceList.splice(index, 1)"
                ></i>
                <div
                  class="loading-tips flex-center-center"
                  v-if="item.loading"
                >
                  <p>
                    <i class="el-icon-loading"></i>
                    <span style="margin-left: 5px">上传中</span>
                  </p>
                </div>
              </div>
              <div
                class="list-item flex-center-center"
                v-if="LIMIT_NUMBER - resourceList.length > 0"
                @click="addContent"
                style="cursor: pointer"
              >
                <div style="text-align: center">
                  <i class="el-icon-plus"></i>
                  <p style="font-weight: bold">
                    还可以添加{{ LIMIT_NUMBER - resourceList.length }}个
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="library-choose" v-if='Array.isArray(resourceList) && resourceList.length > 0'>
            <p @click="chooseLibrary">
              <span><i class="el-icon-picture"></i>从素材库中心选择</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCancel"
    >
      <media-library
        :show="showMediaDialog"
        :mediaType="`${mediaType}`"
        ref="mediaLibrary"
      >
        <div slot="footer" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="
              () => {
                handleOk();
              }
            "
            >确定</el-button
          >
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </media-library>
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from '@/api/common'
import { addMaterialWare } from '@/api/material'
import { imageStatusCheck, videoStatusCheck, _changeTimeBySecond } from '@/assets/js/utils'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import { nanoid } from 'nanoid'
const LIMIT_NUMBER = 20
export default {
  props: {
    previewData: {
      type: Array,
      default: null
    },
    widthAndHigh: {
      type: Object,
      default: () => ({
        width: 1280,
        height: 720
      })
    },
    limitSize: {
      type: [String, Number],
      default: 140
    },
    // ! 媒体类型
    mediaType: {
      type: String,
      default: 'image'
    },
    durationInfo: {
      type: Object,
      default: () => ({
        minTime: 5,
        maxTime: 30
      })
    }
  },
  data () {
    return {
      LIMIT_NUMBER,
      isCanMultiple: true, // 是否可以多传
      tipsIndex: null,
      copyData: null,
      resourceList: [
        {
          id: nanoid(),
          loading: false,
          mediaInfo: {}
        }
      ],
      showMediaDialog: false
    }
  },
  watch: {
    resourceList: {
      handler (newV) {
        this.$emit('uploadData', newV)
      },
      immediate: true
    },
    previewData: {
      handler (newV) {
        if (newV) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {
    echoData () {
      this.resourceList = JSON.parse(JSON.stringify(this.previewData))
    },
    handleMouseEnter (type, val) {
      if (type === 'tipsIndex') {
        if (val.mediaInfo.src) return
      }
      this[type] = val.id
    },
    handleMouseLeave (type) {
      this[type] = null
    },
    addContent () {
      this.resourceList.push({
        mediaInfo: {},
        loading: false,
        id: nanoid()
      })
    },
    handleUpload (index) {
      this.$refs.uploadInput[index].click()
    },
    async handleFileChange (index, e) {
      const files = [...e.target.files]
      const isMultiple = this.mediaType === 'image' ? files.length > 1 : false

      if (!isMultiple) {
        const targetFile = files[0]
        if (this.mediaType === 'video') return this.handleVideoFile(targetFile, index)
        const {
          flag,
          message,
          file: compressFile
        } = await imageStatusCheck(targetFile, +this.limitSize, {
          width: +this.widthAndHigh.width,
          height: +this.widthAndHigh.height
        })
        if (!flag) {
          this.$message.error(`${message}`)
          this.$refs.uploadInput[index].value = ''
          this.isCanMultiple = true
          return
        }
        try {
          this.$set(this.resourceList, index, {
            ...this.resourceList[index],
            loading: true
          })
          const res = await uploadFile({ file: compressFile })
          const type = compressFile.type.split(/\//g)
          this.$set(this.resourceList, index, {
            id: this.resourceList[index].id,
            loading: false,
            mediaInfo: {
              src: res,
              name: compressFile.name.replace(/\.\w+/g, ''),
              url: res,
              widthAndHigh: `${+this.widthAndHigh.width} * ${+this.widthAndHigh.height}`,
              size: `${Math.floor(compressFile.size / 1024)}KB`,
              title: compressFile.name.replace(/\.\w+/g, ''),
              materialId: null,
              format: type[type.length - 1]
            }
          })
        } catch (e) {
          this.$message.error('上传失败，请稍后再试!')
          this.$set(this.resourceList, index, {
            ...this.resourceList[index],
            loading: false
          })
        } finally {
          this.isCanMultiple = true
          this.$refs.uploadInput[index].value = null
        }
      } else {
        this.batchUpload(files)
        this.isCanMultiple = true
      }
    },
    batchUpload (files) {
      let emptyIndexList = []
      this.resourceList.forEach((item, index) => {
        if (!item.mediaInfo.src) {
          emptyIndexList = [...emptyIndexList, index]
        }
      })
      let count = 0
      for (const val of emptyIndexList) {
        if (count >= files.length) break
        this.resourceList[val].loading = true
        count++
      }
      const copyFiles =
        files.length > emptyIndexList.length
          ? files.slice(0, emptyIndexList.length)
          : files
      let indexCount = 0
      for (const file of copyFiles) {
        imageStatusCheck(file, 140, { width: +this.widthAndHigh.width, height: +this.widthAndHigh.height }).then(
          ({ flag, message, file: compressFile }) => {
            if (!flag) {
              this.$message.error(`${message}`)
              this.$refs.uploadInput[emptyIndexList[indexCount]].value = ''
              this.resourceList[emptyIndexList[indexCount]].loading = false
              indexCount++
            } else {
              uploadFile({ file: compressFile })
                .then((res) => {
                  const type = compressFile.type.split(/\//g)
                  this.$set(this.resourceList, emptyIndexList[indexCount], {
                    id: this.resourceList[emptyIndexList[indexCount]].id,
                    mediaInfo: {
                      src: res,
                      name: compressFile.name.replace(/\.\w+/g, ''),
                      url: res,
                      widthAndHigh: `${+this.widthAndHigh.width} * ${+this.widthAndHigh.height}`,
                      size: `${Math.floor(compressFile.size / 1024)}KB`,
                      title: compressFile.name.replace(/\.\w+/g, ''),
                      materialId: null,
                      format: type[type.length - 1]
                    },
                    loading: false
                  })
                })
                .finally(() => {
                  this.$refs.uploadInput[emptyIndexList[indexCount]].value = ''
                  this.resourceList[emptyIndexList[indexCount]].loading = false
                  indexCount++
                })
            }
          }
        )
      }
    },
    async handleConfirm () {
      if (this.resourceList.some((item) => !item.mediaInfo.src)) {
        this.$message.warning('请检查素材配置!')
        return false
      }
      let needUplaodResourceList = JSON.parse(JSON.stringify(this.resourceList.filter(item => !item.mediaInfo.materialId)))
      if (needUplaodResourceList.length === 0) {
        this.$emit('confirm', this.resourceList)
        return true
      } else {
        needUplaodResourceList = needUplaodResourceList.map(item => {
          const { mediaInfo } = item
          return {
            materialType: this.mediaType === 'image' ? 2 : 1,
            title: mediaInfo.title,
            materialResources: {
              title: mediaInfo.title,
              widthAndHigh: mediaInfo.widthAndHigh,
              format: mediaInfo.format,
              size: mediaInfo.size,
              url: mediaInfo.url,
              sort: 1
            }
          }
        })
        const res = await addMaterialWare(needUplaodResourceList)
        res.forEach(item => {
          const targetIndex = this.resourceList.findIndex(resource => resource.mediaInfo.src === item.resources[0].url)
          if (targetIndex > -1) {
            this.$set(this.resourceList, targetIndex, {
              ...this.resourceList[targetIndex],
              mediaInfo: {
                ...item.resources[0],
                src: item.resources[0].url
              }
            })
          }
        })
        this.$emit('confirm', this.resourceList)
        return true
      }
    },
    handleOk () {
      const emptyIndexList = this.getEmptyList()
      if (emptyIndexList.length === 0) {
        this.$message.warning('没有剩余空间放置所选素材!')
        this.showMediaDialog = false
        return
      }
      let { imageList } = this.$refs.mediaLibrary
      imageList = imageList.slice(0, emptyIndexList.length)
      if (!imageList.every(this.checkMedia)) return this.$message.error('所选素材有不符合大小或者尺寸的内容！')
      imageList.forEach((item, index) => {
        if (this.checkMedia(item)) {
          this.$set(this.resourceList, emptyIndexList[index], {
            id: this.resourceList[emptyIndexList[index]].id,
            mediaInfo: {
              src: item.src,
              name: item.resources[0].title.replace(/\.\w+/g, ''),
              url: item.src,
              materialId: item.resources[0].materialId,
              widthAndHigh: item.resources[0].widthAndHigh,
              title: item.resources[0].title.replace(/\.\w+/g, ''),
              size: item.resources[0].size,
              format: item.resources[0].format
            },
            loading: false
          })
        }
      })
      this.showMediaDialog = false
    },
    getEmptyList () {
      return this.resourceList.reduce((acc, b, c) => {
        if (!b.mediaInfo.src) acc = [...acc, c]
        return acc
      }, [])
    },
    checkMedia (file) {
      const { resources } = file
      const mediaSize = resources[0].size.replace(/[^\d+]/g, '')
      if (+mediaSize > this.limitSize) {
        return false
      }
      const { widthAndHigh } = resources[0]
      const imageWH = widthAndHigh.split('*').map((item) => +item)
      const [width, height] = imageWH
      if (width === +this.widthAndHigh.width && height === +this.widthAndHigh.height) return true
      return false
    },

    async handleVideoFile (targetFile, index) {
      const { minTime, maxTime } = this.durationInfo
      const { file, message, duration } = await videoStatusCheck(
        targetFile,
        {
          minTime,
          maxTime
        },
        this.widthAndHigh
      )
      if (!file) {
        this.$refs.uploadInput[index].value = null
        return this.$message.warning(`${message}`)
      }
      this.$set(this.resourceList, index, {
        ...this.resourceList[index],
        loading: true
      })
      try {
        const res = await uploadFile({ file })
        const type = file.type.split(/\//g)
        let videoSize
        if (file.size / 1024 / 1024 < 1) {
          videoSize = `${Math.floor(file.size / 1024)}KB`
        } else {
          videoSize = `${Math.floor(file.size / 1024 / 1024)}MB`
        }
        this.$set(this.resourceList, index, {
          ...this.resourceList[index],
          loading: false,
          mediaInfo: {
            src: res,
            url: res,
            name: targetFile.name.replace(/\.\w+/g, ''),
            title: targetFile.name.replace(/\.\w+/g, ''),
            widthAndHigh: `${this.widthAndHigh.width} * ${this.widthAndHigh.height}`,
            videoTime: _changeTimeBySecond(Number(duration)),
            size: videoSize,
            format: type[type.length - 1],
            materialId: null,
            sort: 1
          }
        })
      } catch (e) {
        this.$message.error('上传失败!')
      } finally {
        this.$refs.uploadInput[index].value = null
      }
    },
    handleCancel () {
      this.showMediaDialog = false
    },
    chooseLibrary () {
      this.showMediaDialog = true
    },
    clearResource () {
      this.$confirm('确定清空吗？清空的数据无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resourceList = [
            {
              id: nanoid(),
              loading: false,
              mediaInfo: {}
            }
          ]
        })
        .catch(() => {})
    }
  },
  components: {
    MediaLibrary
  }
}
</script>
<style lang='scss' scoped>
.divide-line {
  width: 100%;
  border-top: 1px solid $borderColor;
  margin: 15px 0;
}
.vertical-video {
  &-container {
    .metas {
      .meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .control-twins-item {
      margin-top: 50px;
      border: 1px solid $borderColor;
      border-radius: 8px;
      padding: 40px;
      .per-part {
        margin-bottom: 40px;
        &-list {
          width: 90%;
        }
      }
      .list-item {
        width: 298px;
        height: 158px;
        background-color: #f5f6f8;
        border: 1px solid $borderColor;
        cursor: pointer;
        border-radius: 4px;
        margin-right: -1px;
        font-size: 14px;
        text-align: center;
        color: #b0b4bd;
        position: relative;
        .item-media {
          position: relative;
          width: 182px;
          height: 102px;
          img,video {
            width: 100%;
            height: 100%;
          }
          i {
            position: absolute;
            left: 95%;
            top: -5%;
            color: $colorRed;
            z-index: 999;
            font-size: 16px;
            opacity: 0;
            background: $main-background;
            border-radius: 50%;
          }
          .reset-upload-hover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.25s;
            background-color: rgba(0, 0, 0, 0.4confirm);
            opacity: 0;
          }
          &:hover {
            .reset-upload-hover,
            i {
              opacity: 1;
            }
          }
        }
        .tips {
          width: 80%;
        }
        .el-icon-close {
          position: absolute;
          top: 5%;
          left: 90%;
        }
        .el-icon-plus {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 22px;
        }
        .loading-tips {
          width: 182px;
          height: 102px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
        }
      }
      .library-choose {
        margin-top: 10px;
        margin-left: 127px;
        > p {
          cursor: pointer;
          padding: 8px 0;
          transition: all 0.3s;
          &:hover {
            color: $main;
          }
        }
      }
      .operation-delete {
        align-self: center;
        margin-left: 20px;
        border-radius: 8px;
        background-color: #f5f6f8;
        border: 1px solid $borderColor;
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        width: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
