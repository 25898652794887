<template>
  <div class="template-part">
     <div class='template-part-title flex-row-between-start'>
       <span>{{title}}</span>
       <span class='config' @click='setConfig'>配置</span>
     </div>
     <div class='template-part-container'>
        <div class='detail'>
              <slot :name='slotName'></slot>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    title: {
      type: String
    },
    slotName:{
      type:String,
      default:'default'
    }

  },
  data () {
    return {
      copyData: null
    }
  },
  watch: {
    data: {
      handler (newV) {
        if (newV) {
          this.copyData = JSON.parse(JSON.stringify(newV))
        }
      },
    }
  },
  mounted () {},
  methods: {
    setConfig () {
      this.$emit('setConfig')
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>

.template-part {
      width: 400px;
      overflow: hidden;
      color: $fontDarkColor;
      border: 1px solid $borderColor;
      border-radius: 8px;
      &-title{
       height:48px;
       line-height:48px;
       padding: 0 24px;
       font-weight:bold;
       font-size:14px;
       background-color:rgb(251, 252, 253);
       border-bottom:1px solid $borderColor;
       .config{
         color:$configColor;
         cursor: pointer;
       }
      }
      &-container{
      .detail{
            padding:  24px;
            height:400px;
            overflow:auto;
            .property-item{
                display:flex;
                justify-content: flex-start;
                align-items: flex-start;
                line-height:22px;
                font-size:14px;
                padding:8px 0;
                &-key{
                  flex-grow: 1;
                  margin-right:24px;
                  text-align:left;
                  color:$fontLightColor
                }
                &-value{
                width:65%;
                }
            }
        }
    }
}
</style>
