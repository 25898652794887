<template>
  <div class="limit-word" :class="{'error-tips':curLen > maxLength || curLen < minLength}">
    <span>{{ curLen }}</span>
    <template v-if="showMaxLength">
      <span>/</span>
      <span>{{ maxLength }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    // ! 初始长度 用于回显
    initLength: {
      type: [String, Number],
      default: 0
    },
    curValue: {
      type: [String, Number],
      default: ''
    },
    maxLength: {
      type: [String, Number],
      default: 0
    },
    minLength:{
       type:[String,Number],
       default:0,
    },
    calculationRules: {
      type: Function,
      required: true
    },
    showMaxLength: {
      type: Boolean,
      default: true
    },
    // ! 是否除以二
    isDivideByTwo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      curLen: 0
    }
  },
  watch: {
    curValue: {
      handler (newV) {
        this.calcValue(newV)
      }
    },
    initLength: {
      handler (newV) {
        if (newV) {
          this.curLen = newV
        }
      },
      immediate: true
    }
  },
  methods: {
    calcValue (newV) {
      const len = this.calculationRules(`${newV}`)
      this.curLen = this.isDivideByTwo ? Math.ceil(len / 2) : len
      if (this.curLen > +this.maxLength || this.curLen < +this.minLength) {
        this.$emit('error')
      } else {
        this.$emit('change')
      }
      this.$emit('getInputLen',this.curLen)
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.error-tips{
  color:$colorRed;
  font-weight:bold;
}
</style>
