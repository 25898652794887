<template>
  <div class="config-doc">
    <div class="config-doc-container">
      <div class="doc-container">
        <div v-for="(item, i) in curParams.text" :key="item.id">
          <div class="doc-item">
            <div
              class="flex-row-start-center"
              v-if="params && params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo.value !== 714"
            >
              <span>左下辅助文字（选填）</span>
              <el-autocomplete
                style="width: 600px"
                v-model="item.bottom_text"
                placeholder="请输入辅助文字"
                clearable
                :fetch-suggestions="
                  (str, cb) => {
                    querySearchAsync(str, cb, i, 'bottom_text');
                  }
                "
              ></el-autocomplete>
              <limit-word
                style="margin-left: 10px"
                :calculationRules="calculateCharacter"
                :curValue="item.bottom_text"
                :initLength="item.lenArr[0]"
                @error="showError = true"
                @change="showError = false"
                @getInputLen="(value) => getInputLen('bottom_text', value)"
                :isDivideByTwo="true"
                :maxLength="8"
              ></limit-word>
            </div>
            <div class="flex-row-start-center">
              <span>文案（{{ getMinLength() }}-{{ getMaxLength() }}字）</span>
              <el-autocomplete
                style="width: 600px"
                v-model="item.description"
                placeholder="使用行动按钮或卖点图时最多支持10个字"
                clearable
                :fetch-suggestions="
                  (str, cb) => {
                    querySearchAsync(str, cb, i, 'description');
                  }
                "
              ></el-autocomplete>
              <limit-word
                style="margin-left: 10px"
                :calculationRules="calculateCharacter"
                :curValue="item.description"
                :initLength="item.lenArr[1]"
                @error="showError = true"
                @change="showError = false"
                @getInputLen="(value) => getInputLen('description', value)"
                :isDivideByTwo="true"
                :maxLength="getMaxLength()"
                :minLength="getMinLength()"
              ></limit-word>
            </div>
            <div
              class="flex-row-start-center"
              v-if="params && params.creativityConfig && params.creativityConfig.adcreativeTemplateInfo.value !== 714"
            >
              <span>文案（1-14字）</span>
              <el-autocomplete
                style="width: 600px"
                v-model="item.title"
                clearable
                placeholder="使用行动按钮或卖点图时最多支持10个字"
                :fetch-suggestions="
                  (str, cb) => {
                    querySearchAsync(str, cb, i, 'title');
                  }
                "
              ></el-autocomplete>
              <limit-word
                style="margin-left: 10px"
                :calculationRules="calculateCharacter"
                :curValue="item.title"
                :initLength="item.lenArr[2]"
                @error="showError = true"
                @change="showError = false"
                @getInputLen="(value) => getInputLen('title', value)"
                :isDivideByTwo="true"
                :maxLength="14"
              ></limit-word>
            </div>
          </div>
          <div
            class="operation-delete"
            @click="curParams.text.splice(i, 1)"
            v-if="curParams.text.length > 1"
          >
            <i class="el-icon-minus"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calculateCharacter, isValidObject } from '@/assets/js/utils'
import LimitWord from '@/components/limit-word'
import { getCreativeToolsText } from '@/api/tencent'
import { nanoid } from 'nanoid'

export default {
  name: 'Doc',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    },
    curInfo: {
      type: Object,
      default: () => ({})
    },
    Index: {
      type: Number,
      default: 0,
    },
    curDocIndex: {
      type: Number,
      default: 0
    },
    portalDescription: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      calculateCharacter,
      curParams: {
        text: [
          {
            title: '',
            description: '',
            bottom_text: '',
            id: nanoid(),
            loading: false,
            lenArr: [0, 0, 0]
          }
        ]
      },
      showError: false,
      initLength: 0,
      loading: false,
      buttonTextList: [],
      descList: [],
      titleList: []

    }
  },
  watch: {
    curInfo: {
      handler(newV) {
        if (isValidObject(newV)) {
          this.echoData()
        }
      },
      immediate: true
    },
    portalDescription: {
      handler(newV) {
        if (newV) {
          if (this.Index === this.curDocIndex) {
            this.$set(this.curParams, 'text', [{
              ...this.curParams.text[0],
              description: this.portalDescription
            }])
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    echoData() {
      this.curParams.text = [JSON.parse(JSON.stringify(this.curInfo))]
    },
    getDocList(type = 'bottom_text', i = null, keyword = null) {
      return new Promise((resolve) => {
        if (i) this.curParams.text[i].loading = true
        getCreativeToolsText({
          accountId: this.account[0].accountId,
          maxTextLength:
            type === 'bottom_text' ? 8 : type === 'title' ? 14 : this.getMaxLength(),
          keyword,
          number: 50
        })
          .then((res) => {
            if (type === 'bottom_text') this.buttonTextList = res
            if (type === 'description') this.descList = res
            if (type === 'title') this.titleList = res
          })
          .finally(() => {
            if (i) this.curParams.text[i].loading = false
            this.$nextTick(() => {
              resolve()
            })
          })
      })
    },
    querySearchAsync(queryString, cb, index, type) {
      let res = []
      this.getDocList(type, index, queryString)
        .then(() => {
          if (type === 'bottom_text') {
            res = !queryString
              ? this.buttonTextList
              : this.buttonTextList.filter((item) =>
                item.text.includes(queryString)
              )
          }
          if (type === 'description') {
            res = !queryString
              ? this.descList
              : this.descList.filter((item) => item.text.includes(queryString))
          }
          if (type === 'title') {
            res = !queryString
              ? this.titleList
              : this.titleList.filter((item) =>
                item.text.includes(queryString)
              )
          }
        })
        .finally(() => {
          cb(res.map((item) => ({ value: item.text })))
        })
    },
    getMaxLength() {
      if (!this.params) return 0
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      // ! 横版小图3:2
      return adcreativeTemplateInfo.value === 714 ? 24 : 30
    },
    getMinLength() {
      if (!this.params) return 0
      const { creativityConfig: { adcreativeTemplateInfo } } = this.params
      return adcreativeTemplateInfo.value === 714 ? 16 : 6
    },
    getInputLen(type, value) {
      const targetValue = this.curParams.text[0]
      if (type === 'bottom_text') {
        targetValue.lenArr[0] = value
      }
      if (type === 'description') {
        targetValue.lenArr[1] = value
      }
      if (type === 'title') {
        targetValue.lenArr[2] = value
      }
      this.$set(this.curParams, 'text', [targetValue])
    }
  },
  components: { LimitWord }
}
</script>
<style lang='scss' scoped>
.doc-container {
  max-height: 650px;
  overflow: auto;
  .doc-item {
    width: 100%;
    border: 1px solid $borderColor;
    padding: 20px;
    border-radius: 8px;
    > div {
      margin-bottom: 20px;
      > span {
        width: 200px;
      }
    }
  }
}
.operation-delete {
  align-self: center;
  margin-left: 20px;
  border-radius: 8px;
  background-color: #f5f6f8;
  border: 1px solid $borderColor;
  font-size: 18px;
  height: 30px;
  line-height: 30px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.tips {
  text-align: center;
  border: 1px dashed $borderColor;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #f7f9fc;
  }
}
</style>
