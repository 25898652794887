<template>
  <el-dialog
    width="1200px"
    top="20px"
    append-to-body
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div slot="title">添加素材{{ `${resourceList.length}组素材` }}</div>
    <div class="config-resource">
      <div class="config-resource-container">
        <!-- 竖版视频 -->
        <div v-if="creativeType === 721">
          <vertical-video
            :widthAndHigh="{ width: 720, height: 1280 }"
            ref="verticalVideo"
            @confirm="
              (data) => {
                confirm(data, 721);
              }
            "
            :previewData="previewData"
            @uploadData="handleUploadData"
          ></vertical-video>
        </div>
        <!-- 横版大图 -->
        <div v-if="creativeType === 711">
          <horizontal-image
            @uploadData="handleUploadData"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 711);
              }
            "
            ref="horizontalImage"
          ></horizontal-image>
        </div>
        <!-- 横版视频 -->
        <div v-if="creativeType === 720">
          <vertical-video
            @uploadData="handleUploadData"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 720);
              }
            "
            ref="verticalVideo"
            :widthAndHigh="{ width: 1280, height: 720 }"
          ></vertical-video>
        </div>
        <!-- 横版三小图 -->
        <div v-if="creativeType === 718">
          <config-three-pic
            @uploadData="handleUploadData"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 718);
              }
            "
            ref="configThreePic"
          ></config-three-pic>
        </div>
        <!-- 竖版大图 -->
        <div v-if="creativeType === 712">
          <vertical-pic
            @uploadData="handleUploadData"
            :params="params"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 712);
              }
            "
            ref="verticalPic"
          ></vertical-pic>
        </div>
        <!-- 横版小图3:2 -->
        <div v-if="creativeType === 714">
          <horizontal-image
            @uploadData="handleUploadData"
            :limitSize="100"
            :widthAndHigh="{ width: 480, height: 320 }"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 714);
              }
            "
            ref="verticalImage"
          ></horizontal-image>
        </div>
        <!-- 贴片视频 -->
        <div v-if="creativeType === 722">
          <horizontal-image
            @uploadData="handleUploadData"
            mediaType="video"
            :widthAndHigh="{ width: 1280, height: 720 }"
            :limitSize="1024 * 1024 * 100"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 722);
              }
            "
            ref="wallpaperVideo"
          ></horizontal-image>
        </div>
        <!-- 激励浏览广告不需要素材 -->
        <!-- 闪屏视频9:16 -->
        <div v-if="creativeType === 1529">
          <vertical-video
            @uploadData="handleUploadData"
            ref="flashVideo"
            :durationInfo="{ minTime: 5, maxTime: 5 }"
            :limitSize="{ imageSize: '140kb', videoSize: '200mb' }"
            :previewData="previewData"
            @confirm="
              (data) => {
                confirm(data, 1529);
              }
            "
            :widthAndHigh="{ width: 1080, height: 1920 }"
          ></vertical-video>
        </div>
      </div>
    </div>
    <div slot="footer" class="button-list" style="text-align: left">
      <el-button type="danger" @click="clearResource">清空素材组</el-button>
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VerticalVideo from './vertical-video'
import HorizontalImage from './horizontal-image'
import ConfigThreePic from './config-three-pic'
import VerticalPic from './vertical-pic'
export default {
  name: 'ConfigResource',
  props: {
    showInfo: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => ({})
    },
    isChangeAdcreative: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      creativeType: null,
      resourceList: [],
      curParams: null,
      previewData: null
    }
  },
  computed: {
    show: {
      get () {
        return this.showInfo.name === this.$options.name && this.showInfo.value
      },
      set () {}
    }
  },
  watch: {
    showInfo: {
      handler (newV) {
        if (this.show) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    initData () {
      if (!this.params.creativityConfig) return
      const {
        creativityConfig: { adcreativeTemplateInfo }
      } = this.params
      this.creativeType = adcreativeTemplateInfo.value
      if (this.params.ConfigResource) {
        this.previewData = this.params.ConfigResource.select
      }
    },
    clearResource () {
      if ([721, 720].includes(this.creativeType)) { this.$refs.verticalVideo.clearResource() }
      if (this.creativeType === 711) this.$refs.horizontalImage.clearResource()
      if (this.creativeType === 718) this.$refs.configThreePic.clearResource()
      if (this.creativeType === 712) this.$refs.verticalPic.clearResource()
      if (this.creativeType === 714) this.$refs.verticalImage.clearResource()
      if (this.creativeType === 722) this.$refs.wallpaperVideo.clearResource()
      if (this.creativeType === 1529) this.$refs.flashVideo.clearResource()
    },
    async handleConfirm () {
      const obj = {
        721: 'verticalVideo',
        720: 'verticalVideo',
        718: 'configThreePic',
        711: 'horizontalImage',
        712: 'verticalPic',
        714: 'verticalImage',
        722: 'wallpaperVideo',
        1529: 'flashVideo'
      }
      const res = await this.$refs[obj[this.creativeType]].handleConfirm()
      if (!res) return
      this.$emit('close')
    },
    handleUploadData (data) {
      this.resourceList = data
    },
    confirm (data, type) {
      this.$emit('confirm', data)
    },
    handleCancel () {
      this.$emit('close')
    },
    handleClose () {
      this.$emit('close')
    }
  },
  components: {
    VerticalVideo,
    HorizontalImage,
    ConfigThreePic,
    VerticalPic
  }
}
</script>
<style lang='scss' scoped>
.config-resource {
  &-container {
    height: 700px;
    overflow: auto;
  }
}
</style>
